import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { CrashReportState } from '../state/crash-report.state';

export const selectCrashReportState = (state: AppState) => state.crashReport;

// crash report selectors
export const selectCrashReportSummary = createSelector(selectCrashReportState, (state: CrashReportState) => state?.crashReportSummary); // TODO: why is the ? needed here?
export const selectItemNumber = createSelector(selectCrashReportSummary, crashReportSummary =>
  CommonUtils.isDefined(crashReportSummary) ? crashReportSummary.nopd_item_num : null
);
export const selectCrashReportDetail = createSelector(selectCrashReportState, (state: CrashReportState) => state.crashReportDetail);

// new crash report form value selectors
export const selectNewCrashReportFormValue = createSelector(selectCrashReportState, (state: CrashReportState) => state.newCrashReportFormValue);

// is new crash report selector
export const selectIsNewCrashReport = createSelector(selectCrashReportState, (state: CrashReportState) => state.isNewCrashReport);

// in progress crash report selectors
export const selectInProgressCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => {
  return [...state.inProgressCrashReportSummaries].sort((crashReportSummary1, crashReportSummary2) => {
    switch (true) {
      case CommonUtils.isNullOrUndefinedOrEmptyString(crashReportSummary1.date) && CommonUtils.isDefined(crashReportSummary2.date): {
        return 1;
      }
      case CommonUtils.isDefined(crashReportSummary1.date) && CommonUtils.isNullOrUndefinedOrEmptyString(crashReportSummary2.date): {
        return -1;
      }
      case crashReportSummary1.date < crashReportSummary2.date: {
        return 1;
      }
      case crashReportSummary1.date > crashReportSummary2.date: {
        return -1;
      }
      case crashReportSummary1.nopd_item_num < crashReportSummary2.nopd_item_num: {
        return -1;
      }
      case crashReportSummary1.nopd_item_num > crashReportSummary2.nopd_item_num: {
        return 1;
      }
      default: {
        return 0;
      }
    }
  });
});

// submitted crash report selectors
export const selectSubmittedCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.submittedCrashReportSummaries);
export const selectCurrentPage = createSelector(selectCrashReportState, (state: CrashReportState) => state.currentPage);
export const selectCurrentOffset = createSelector(selectCrashReportState, (state: CrashReportState) => state.currentOffset);
export const selectNumberOfSubmittedCrashReportSummaries = createSelector(
  selectCrashReportState,
  (state: CrashReportState) => state.numberOfSubmittedCrashReportSummaries
);

// needs review crash report selectors
export const selectNeedsReviewCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.needsReviewCrashReportSummaries);
export const selectNumberOfNeedsReviewCrashReportSummaries = createSelector(
  selectNeedsReviewCrashReportSummaries,
  needsReviewCrashReportSummaries => needsReviewCrashReportSummaries.length
);
export const selectNeedsReviewCrashReportSummary = createSelector(selectCrashReportState, (state: CrashReportState) => state.needsReviewCrashReportSummary);
export const selectNeedsReviewCrashReportSummaryItemNumber = createSelector(selectNeedsReviewCrashReportSummary, needsReviewCrashReportSummary =>
  OssOptional.ofNullable(needsReviewCrashReportSummary)
    .map(summary => summary.nopd_item_num)
    .orElseNull()
);

// my approved crash report selectors
export const selectMyApprovedCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.myApprovedCrashReportSummaries);

// approved crash report selectors
export const selectApprovedCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.approvedCrashReportSummaries);

// my rejected crash report selectors
export const selectMyRejectedCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.myRejectedCrashReportSummaries);

// rejected crash report selectors
export const selectRejectedCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.rejectedCrashReportSummaries);

// is redirect from review / non-review and is redirect from activity / non-activity selectors
export const selectIsRedirectFromReview = createSelector(selectCrashReportState, (state: CrashReportState) => state.isRedirectFromReview);
export const selectIsRedirectFromNonReview = createSelector(selectCrashReportState, (state: CrashReportState) => state.isRedirectFromNonReview);
export const selectIsRedirectFromActivity = createSelector(selectCrashReportState, (state: CrashReportState) => state.isRedirectFromActvity);
export const selectIsRedirectFromNonActivity = createSelector(selectCrashReportState, (state: CrashReportState) => state.isRedirectFromNonActivity);
export const selectIsRedirect = createSelector(
  selectCrashReportState,
  (state: CrashReportState) => state.isRedirectFromReview || state.isRedirectFromNonReview || state.isRedirectFromActvity || state.isRedirectFromNonActivity
);
