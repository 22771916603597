import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type Color = 'blue' | 'red' | 'green';

@Component({
  selector: 'app-primary-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './primary-button.component.html',
  styleUrl: './primary-button.component.scss',
})
export class PrimaryButtonComponent {
  // required inputs
  @Input({ required: true }) color: Color;
  @Input({ required: true }) label: string;

  // optional inputs
  @Input({ required: false }) isDisabled: boolean = false;
}
