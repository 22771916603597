import { Injectable } from '@angular/core';
import { Selectable } from '../../../../shared/data-model/models/selectable.model';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { getFormItemType } from '../../../data-model/enums/form-item-type.enum';
import { FormItemMetaData } from '../../../data-model/models/form-item-meta-data.model';
import { FormItem } from '../../../data-model/models/form-item.model';
import { PageMetaData } from '../../../data-model/models/page-meta-data.model';
import { Page } from '../../../data-model/models/page.model';
import { SchemaGroupMetaData } from '../../../data-model/models/schema-group-meta-data.model';
import { SchemaGroup } from '../../../data-model/models/schema-group.model';
import { SectionMetaData } from '../../../data-model/models/section-meta-data.model';
import { Section } from '../../../data-model/models/section.model';
import { SubGroupMetaData } from '../../../data-model/models/sub-group-meta-data.model';
import { SubGroup } from '../../../data-model/models/sub-group.model';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailMetaDataServiceService {
  buildSchemaGroupMetaData(
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    schemaGroupDomIdSchemaGroupIndexMap: Map<string, number>,
    isPlaceholder: boolean,
    apiIndex: number
  ): SchemaGroupMetaData {
    return {
      doesRepeat: schemaGroup.repeats,
      domId: schemaGroup.key,
      index: schemaGroupIndex,
      isPlaceholder,
      key: schemaGroup.key,
      shouldHide: OssOptional.ofNullable(schemaGroup.hide).orElse(false),
      title: schemaGroup.name,
      schemaGroupIndex: schemaGroupDomIdSchemaGroupIndexMap.get(schemaGroup.key),
      isDeleted: false,
      apiIndex,
    };
  }

  buildSubGroupMetaData(schemaGroup: SchemaGroup, subGroup: SubGroup, subGroupIndex: number, isPlaceholder: boolean, apiIndex: number): SubGroupMetaData {
    return {
      doesRepeat: subGroup.repeats,
      domId: subGroup.key,
      groupKey: OssOptional.ofNullable(subGroup.db_repeat_field).orElseNull(),
      index: subGroupIndex,
      isPlaceholder,
      key: subGroup.key,
      parentGroupKey: OssOptional.ofNullable(schemaGroup.db_repeat_field).orElseNull(),
      isActionable: true,
      name: subGroup.name,
      shouldHide: false,
      isDeleted: false,
      apiIndex,
    };
  }

  buildSectionMetaData(section: Section, sectionIndex: number): SectionMetaData {
    return { domId: section.key, key: section.key, name: section.name, index: sectionIndex, isActionable: true };
  }

  buildPageMetaData(page: Page, pageIndex: number): PageMetaData {
    return {
      domId: page.key,
      key: page.key,
      name: page.name,
      index: pageIndex,
      label: OssOptional.ofNullable(page.label).orElseNull(),
      errorMessage: OssOptional.ofNullable(page.errorMessage).orElseNull(),
      numberOfRows: OssOptional.ofNullable(page.numberOfRows).orElse(1),
      numberOfColumns: OssOptional.ofNullable(page.numberOfColumns).orElse(1),
      template: OssOptional.ofNullable(page.template).orElseNull(),
      templateSubType: OssOptional.ofNullable(page.templateSubType).orElseNull(),
      templateData: OssOptional.ofNullable(page.templateData).orElseNull(),
      shouldHide: OssOptional.ofNullable(page.hide).orElse(false),
      helpText: OssOptional.ofNullable(page.helpText).orElseNull(),
    };
  }

  buildFormItemMetaData(
    formItem: FormItem,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    isDefaultValue: boolean,
    schemaGroupApiIndex: number,
    subGroupApiIndex: number
  ): FormItemMetaData {
    const shouldHide = OssOptional.ofNullable(formItem.hide).orElse(false);
    const labelPosition = CommonUtils.isDefined(formItem.labelPosition)
      ? formItem.labelPosition
      : OssOptional.ofNullable(formItem.showLabel).orElse(true)
        ? 'top'
        : null;
    const updatedItems = this.updateItems(formItem);

    return {
      allowedSelections: +formItem.allowedSelections,
      column: !shouldHide ? OssOptional.ofNullable(formItem.column).orElse(0) : null,
      domId: formItem.key,
      eCrashHandler: OssOptional.ofNullable(formItem.eCrashHandler).orElseNull(),
      eCrashReference: OssOptional.ofNullable(formItem.eCrashReference).orElseNull(),
      eCrashReportPath: OssOptional.ofNullable(formItem.eCrashReportPath).orElseNull(),
      fieldName: formItem.fieldName,
      formControlType: getFormItemType(formItem.formControlType),
      helpText: OssOptional.ofNullable(formItem.helpText).orElse(StringUtils.EMPTY),
      hiddenItems: [],
      isDefaultValue,
      isRequired: false,
      items: updatedItems,
      key: formItem.key,
      label: formItem.label,
      labelOverrides: OssOptional.ofNullable(formItem.labelOverrides).orElseNull(),
      labelPosition,
      maxLength: OssOptional.ofNullable(formItem.max_length).orElse(255),
      orderOverride: OssOptional.ofNullable(formItem.orderOverride).orElseNull(),
      placeholder: OssOptional.ofNullable(formItem.placeholder).orElse(StringUtils.EMPTY),
      row: !shouldHide ? OssOptional.ofNullable(formItem.row).orElse(0) : null,
      schemaGroupApiIndex,
      schemaGroupDomId: schemaGroup.key,
      schemaGroupIndex,
      shouldHide,
      showLabel: OssOptional.ofNullable(formItem.showLabel).orElse(true),
      showSubscript: OssOptional.ofNullable(formItem.showSubscript).orElse(true),
      subGroupApiIndex,
      subGroupDomId: subGroup.key,
      subGroupIndex,
      table: formItem.table,
      templateSlot: OssOptional.ofNullable(formItem.templateSlot).orElseNull(),
      type: getFormItemType(formItem.type),
      visibilityOverrides: OssOptional.ofNullable(formItem.visibilityOverrides).orElseNull(),
      width: OssOptional.ofNullable(formItem.width).orElse('m'),
    };
  }

  private updateItems(formItem: FormItem): Selectable[] {
    const valueNewLabelMap = OssOptional.ofNullable(formItem.labelOverrides).orElse({});
    const valueIsVisibleMap = OssOptional.ofNullable(formItem.visibilityOverrides).orElse({});
    const orderOverride = OssOptional.ofNullable(formItem.orderOverride).orElse([]);

    const { updatedItems, valueIndexMap } = [
      ...OssOptional.ofNullable(formItem.items).orElse([]),
      ...OssOptional.ofNullable(formItem.hiddenItems).orElse([]),
    ].reduce(
      (accumulator, item) => {
        if (CommonUtils.isDefinedAndFalse(valueIsVisibleMap[item.value.toString()])) {
          return accumulator;
        }
        accumulator.valueIndexMap.set(item.value.toString(), accumulator.updatedItems.length);
        accumulator.updatedItems.push({ ...item, label: OssOptional.ofNullable(valueNewLabelMap[item.value.toString()]).orElse(item.label) });
        return accumulator;
      },
      { updatedItems: [], valueIndexMap: new Map<string, number>() }
    );

    const { sortedItems, sortedValues } = orderOverride.reduce(
      (accumulator, value) => {
        OssOptional.ofNullable(valueIndexMap.get(value.toString())).ifPresent(index => {
          OssOptional.ofNullable(updatedItems[index]).ifPresentOrElse(
            item => {
              accumulator.sortedItems.push(item);
              accumulator.sortedValues.add(value);
            },
            () => console.warn(`Item with value ${value} not found in form item ${formItem.key}`)
          );
        });
        return accumulator;
      },
      { sortedItems: [], sortedValues: new Set<string>() }
    );

    return [...sortedItems, ...updatedItems.filter(item => !sortedValues.has(item.value.toString()))];
  }
}
