import { createAction, props } from '@ngrx/store';
import { PlateToVinRequest } from '../../data-model/models/plate-to-vin-request.model';
import { SchemaGroupMetaData } from '../../data-model/models/schema-group-meta-data.model';
import { SubGroupMetaData } from '../../data-model/models/sub-group-meta-data.model';

const feature = '[License Plate]';

export const loadVinData = createAction(
  `${feature} Load Vin Data`,
  props<{ request: PlateToVinRequest; schemaGroupMetaData: SchemaGroupMetaData; subGroupMetaData: SubGroupMetaData }>()
);
export const loadVinDataSuccess = createAction(`${feature} Load Vin Data Success`);
export const loadVinDataFailure = createAction(`${feature} Load Vin Data Failure`, props<{ error: string }>());
