<button
  class="primary-button-container"
  [ngClass]="{
    blue: color === 'blue',
    green: color === 'green',
    red: color === 'red'
  }"
  [disabled]="isDisabled"
>
  <div class="primary-button-label">{{ label }}</div>
</button>
