import { Selectable } from '../data-model/models/selectable.model';
import { TechnicalException } from '../data-model/models/technical-exception.model';
import { CommonUtils } from './common-utils';
import { StringUtils } from './string-utils';

export class CollectionUtils {
  static EMPTY = [];

  static toCommaSeparatedList(values: string[], finalConjunction: 'and' | 'or'): string {
    if (CommonUtils.isEmpty(values)) {
      return StringUtils.EMPTY;
    }
    if (values.length === 1) {
      return values[0];
    }
    const lastValue = values.pop();
    const joinedValues = values.join(', ');
    return `${joinedValues} ${finalConjunction} ${lastValue}`;
  }

  static range(start: number, stop?: number, step: number = 1): number[] {
    if (stop === undefined) {
      stop = start;
      start = 0;
    }
    const result: number[] = [];
    if (step > 0) {
      for (let i = start; i < stop; i += step) {
        result.push(i);
      }
    } else if (step < 0) {
      for (let i = start; i > stop; i += step) {
        result.push(i);
      }
    }
    return result;
  }

  static getFirstElement<T>(array: T[]): T | null {
    if (CommonUtils.isEmpty(array)) {
      return null;
    }
    return array[0];
  }

  static getLastElement<T>(array: T[]): T | null {
    if (CommonUtils.isEmpty(array)) {
      return null;
    }
    return array[array.length - 1];
  }

  static getOnlyElement<T>(array: T[], message?: string): T {
    if (array.length !== 1) {
      throw new TechnicalException(message || `Expected exactly one element but found ${array.length}`);
    }
    return array[0];
  }

  static everyElementIsOfType<T>(array: unknown, type: string | { new (...args: unknown[]): T }): boolean {
    if (!Array.isArray(array)) {
      return false;
    }
    if (typeof type === 'string') {
      return array.every(item => typeof item === type);
    }
    return array.every(item => item instanceof type);
  }

  static findWithIndex<T>(array: T[], callback: (element: T, index: number, array: T[]) => boolean): { element: T; index: number } | null {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (callback(element, index, array)) {
        return { element, index };
      }
    }
    return { element: null, index: null };
  }

  static findLastWithIndex<T>(array: T[], callback: (element: T, index: number, array: T[]) => boolean): { element: T; index: number } | null {
    for (let index = array.length - 1; index >= 0; index--) {
      const element = array[index];
      if (callback(element, index, array)) {
        return { element, index };
      }
    }
    return { element: null, index: null };
  }

  static count<T>(array: T[], callback: (element: T, index: number, array: T[]) => boolean = () => true): number {
    return array.filter(callback).length;
  }

  static symmetricDifference<T>(list1: T[], list2: T[]): T[] {
    const uniqueToFirstList = list1.filter(item => !list2.includes(item));
    const uniqueToSecondList = list2.filter(item => !list1.includes(item));
    return [...uniqueToFirstList, ...uniqueToSecondList];
  }

  static intersection<T>(list1: T[], list2: T[]): T[] {
    return list1.filter(item => list2.includes(item));
  }

  static findClosestMatch(target: string, items: Selectable[]): Selectable {
    if (CommonUtils.isNullOrUndefined(target) || CommonUtils.isNullOrUndefined(items) || items.length === 0) {
      return null;
    }

    target = target.toLowerCase();

    // if the target is a substring of the label, return the item
    const itemWithLabelContainingTarget = items.find(item => item.label.toLowerCase().includes(target));
    if (CommonUtils.isDefined(itemWithLabelContainingTarget)) {
      return itemWithLabelContainingTarget;
    }

    // if the target is not a substring of the label, return the item with the smallest Levenshtein distance
    let closestMatch: Selectable | null = null;
    let minDistance = Infinity;

    for (const item of items) {
      const distance = this.levenshteinDistance(target, item.label.toLowerCase());
      if (distance < minDistance) {
        minDistance = distance;
        closestMatch = item;
      }
    }

    return closestMatch;
  }

  private static levenshteinDistance(s1: string, s2: string): number {
    const m = s1.length;
    const n = s2.length;
    const dp: number[][] = Array.from({ length: m + 1 }, () => Array.from({ length: n + 1 }, () => 0));

    for (let i = 0; i <= m; i++) {
      dp[i][0] = i;
    }
    for (let j = 0; j <= n; j++) {
      dp[0][j] = j;
    }

    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (s1[i - 1] === s2[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1];
        } else {
          dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + 1);
        }
      }
    }

    return dp[m][n];
  }
}
