import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private readonly isProduction = environment.production;
  private readonly apiUrl = environment.appConfig.apiUrl;
  private readonly apiVersion = environment.appConfig.apiVersion;
  private readonly plateToVinActive = environment.appConfig.plateToVinActive;
  private readonly plateToVinApiKey = environment.appConfig.plateToVinApiKey;
  private readonly smartyStreetsActive = environment.appConfig.smartyStreetsActive;
  private readonly smartyStreetsApiKey = environment.appConfig.smartyStreetsApiKey;
  private readonly version = environment.appConfig.version;
  private readonly radarApiKey = environment.appConfig.radarApiKey;
  private readonly radarActive = environment.appConfig.radarActive;
  private readonly googleMapsApiMapId = environment.appConfig.googleMapsApiMapId;
  private readonly easyStreetDrawServiceUrl = environment.appConfig.easyStreetDrawServiceUrl;
  private readonly easyStreetDrawLicenseId = environment.appConfig.easyStreetDrawLicenseId;
  private readonly posthogApiKey = environment.appConfig.posthogApiKey;

  getIsProduction(): boolean {
    return this.isProduction;
  }

  getApiUrl(): string {
    return this.apiUrl;
  }

  getApiVersion(): string {
    return this.apiVersion;
  }

  getPlateToVinActive(): boolean {
    return this.plateToVinActive;
  }

  getPlateToVinApiKey(): string {
    return this.plateToVinApiKey;
  }

  getSmartyStreetsActive(): boolean {
    return this.smartyStreetsActive;
  }

  getSmartyStreetsApiKey(): string {
    return this.smartyStreetsApiKey;
  }

  getVersion(): string {
    return this.version;
  }

  getRadarApiKey(): string {
    return this.radarApiKey;
  }

  getRadarActive(): boolean {
    return this.radarActive;
  }

  getGoogleMapsApiMapId(): string {
    return this.googleMapsApiMapId;
  }

  getEasyStreetDrawServiceUrl(): string {
    return this.easyStreetDrawServiceUrl;
  }

  getEasyStreetDrawLicenseId(): string {
    return this.easyStreetDrawLicenseId;
  }

  getPosthogApiKey(): string {
    return this.posthogApiKey;
  }
}
