import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { OssOptional } from '../../../shared/utils/oss-optional';

export const selectServiceLocationState = (state: AppState) => state.serviceLocation;

export const selectServiceLocations = createSelector(selectServiceLocationState, state => state.serviceLocations);

export const selectServiceLocation = createSelector(selectServiceLocationState, state => state.serviceLocation);
export const selectServiceLocationId = createSelector(selectServiceLocation, serviceLocation =>
  OssOptional.ofNullable(serviceLocation)
    .map(location => location.id)
    .orElseNull()
);
