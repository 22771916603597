export class MapUtils {
  static clone<T, U>(map: Map<T, U>): Map<T, U> {
    const clonedMap = new Map<T, U>();
    map.forEach((value, key) => {
      clonedMap.set(key, value);
    });
    return clonedMap;
  }

  static putIfAbsent<T, U>(map: Map<T, U>, key: T, value: U): void {
    if (!map.has(key)) {
      map.set(key, value);
    }
  }
}
