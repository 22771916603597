import { Injectable } from '@angular/core';
import * as SmartySDK from 'smartystreets-javascript-sdk';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class SmartyStreetsService {
  private smartyStreetsClient: SmartySDK.usAutocompleteProClient;

  get client(): SmartySDK.usAutocompleteProClient {
    return this.smartyStreetsClient;
  }

  private set client(client: SmartySDK.usAutocompleteProClient) {
    this.smartyStreetsClient = client;
  }

  constructor(private readonly configService: ConfigService) {}

  configure(): void {
    const SmartyCore = SmartySDK.core;

    const key = this.configService.getSmartyStreetsApiKey();
    const credentials = new SmartyCore.SharedCredentials(key);

    const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
    this.smartyStreetsClient = clientBuilder.buildUsAutocompleteProClient();
  }
}
