import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TechnicalException } from '../data-model/models/technical-exception.model';
import { CommonUtils } from '../utils/common-utils';
import { StringUtils } from '../utils/string-utils';

export class TimeValidator {
  static getHhMmValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const value = control.value;

      if (CommonUtils.isNullOrUndefinedOrEmptyString(value)) {
        return null;
      }

      const formatRegex = /^\d{4}$/;
      if (!formatRegex.test(value)) {
        return { invalidTime: { invalidFormat: 'HHMM' } };
      }

      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/;
      if (!timeRegex.test(value)) {
        return { invalidTime: { invalidMilitaryTime: true } };
      }

      return null;
    };
  }

  static lessThan(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const time1 = TimeValidator.timeToNumber(formControl1.value);
      const time2 = TimeValidator.timeToNumber(formControl2.value);
      if (time1 < time2) {
        return null;
      }
      return { timeLessThan: { value: TimeValidator.convertHHColonMMOrHHMMToHHMM(formControl2.value) } };
    };
  }

  static lessThanOrEqualTo(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const time1 = TimeValidator.timeToNumber(formControl1.value);
      const time2 = TimeValidator.timeToNumber(formControl2.value);
      if (time1 <= time2) {
        return null;
      }
      return { timeLessThanOrEqualTo: { value: TimeValidator.convertHHColonMMOrHHMMToHHMM(formControl2.value) } };
    };
  }

  static greaterThan(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const time1 = TimeValidator.timeToNumber(formControl1.value);
      const time2 = TimeValidator.timeToNumber(formControl2.value);
      if (CommonUtils.isNullOrUndefined(time1) || CommonUtils.isNullOrUndefined(time2)) {
        return null;
      }
      if (time1 > time2) {
        return null;
      }
      return { timeGreaterThan: { value: TimeValidator.convertHHColonMMOrHHMMToHHMM(formControl2.value) } };
    };
  }

  static greaterThanOrEqualTo(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const time1 = TimeValidator.timeToNumber(formControl1.value);
      const time2 = TimeValidator.timeToNumber(formControl2.value);
      if (time1 >= time2) {
        return null;
      }
      return { timeGreaterThanOrEqualTo: { value: TimeValidator.convertHHColonMMOrHHMMToHHMM(formControl2.value) } };
    };
  }

  static validatorNameValidatorFunctionMap: Map<string, (...args: unknown[]) => ValidatorFn>;

  static {
    TimeValidator.validatorNameValidatorFunctionMap = new Map<string, (...args: unknown[]) => ValidatorFn>();
    this.populateValidatorNameValidatorFunctionMap();
  }

  private static populateValidatorNameValidatorFunctionMap(): void {
    TimeValidator.validatorNameValidatorFunctionMap.set('lessThan', TimeValidator.lessThan);
    TimeValidator.validatorNameValidatorFunctionMap.set('lessThanOrEqualTo', TimeValidator.lessThanOrEqualTo);
    TimeValidator.validatorNameValidatorFunctionMap.set('greaterThan', TimeValidator.greaterThan);
    TimeValidator.validatorNameValidatorFunctionMap.set('greaterThanOrEqualTo', TimeValidator.greaterThanOrEqualTo);
  }

  private static timeToNumber(time: string): number {
    const normalizedTime = TimeValidator.normalizeTime(time);
    if (CommonUtils.isNullOrUndefined(normalizedTime)) {
      return null;
    }
    const [hours, minutes, seconds] = normalizedTime.split(':').map(segment => parseInt(segment, 10));
    return hours * 3600 + minutes * 60 + (seconds || 0);
  }

  private static normalizeTime(time: string): string {
    if (CommonUtils.isNullOrUndefined(time) || time === StringUtils.EMPTY) {
      return null;
    }
    if (time.length === 4) {
      return `${time.slice(0, 2)}:${time.slice(2, 4)}:00`;
    }
    return time;
  }

  private static convertHHColonMMOrHHMMToHHMM(value: string): string {
    if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY) {
      return null;
    }
    if (/^\d{4}$/.test(value)) {
      return value;
    }
    if (/^\d{2}:\d{2}$/.test(value)) {
      return value.replace(':', '');
    }
    throw new TechnicalException('Invalid time format:' + value);
  }
}
