import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, startWith } from 'rxjs';
import { AppState } from '../../../../../store/state/app.state';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { OssStream } from '../../../../shared/utils/oss-stream';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { FormConstants } from '../../../constants/form.constants';
import { RepeatableSubGroupValueChange } from '../../../data-model/models/repeatable-sub-group-value-change.model';
import { Rule } from '../../../data-model/models/rule.model';
import { SubGroupMetaData } from '../../../data-model/models/sub-group-meta-data.model';
import { ValueChange } from '../../../data-model/models/value-change.model';
import { RuleSet } from '../../../data-model/types/rule-set.type';
import { CrashReportActions, CrashReportStructuralActions } from '../../../store/actions';
import { CrashReportDetailFormKeyService } from '../crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailFormValidationService } from '../crash-report-detail-form-validation/crash-report-detail-form-validation.service';
import { CrashReportDetailMapService } from '../crash-report-detail-map.service';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailFormValueChangesService {
  compositeKeyIsActionableMap: Record<string, boolean>;
  compositeKeyIsActionableMap$: Observable<Record<string, boolean>>;

  private compositeKeyIsActionableMapSubject: BehaviorSubject<Record<string, boolean>>;
  private fullCompositeKeyValueChangeSubscriptionMap: Map<string, Subscription>;

  constructor(
    private readonly validationService: CrashReportDetailFormValidationService,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly store: Store<AppState>
  ) {
    this.fullCompositeKeyValueChangeSubscriptionMap = new Map<string, Subscription>();

    this.compositeKeyIsActionableMap = {};
    this.compositeKeyIsActionableMapSubject = new BehaviorSubject(this.compositeKeyIsActionableMap);
    this.compositeKeyIsActionableMap$ = this.compositeKeyIsActionableMapSubject.asObservable();
  }

  subscribeToValueChangesWithRules(ruleSet: RuleSet, informAppCallback: () => void, form: FormGroup): void {
    this.resetCompositeKeyIsActionableMap(form);
    this.compositeKeyIsActionableMapSubject.next(this.compositeKeyIsActionableMap);

    ruleSet.forEach(rule => {
      const ruleCompositeKey = this.keyService.getPartialCompositeKey(rule.tableName, rule.columnName);
      const sourceFormGroups = this.mapService.partialCompositeKeyFormGroupsMap.get(ruleCompositeKey);
      if (CommonUtils.hasContent(sourceFormGroups)) {
        sourceFormGroups.forEach(sourceFormGroup => this.subscribeToSourceFormGroupValueChanges(sourceFormGroup, rule, informAppCallback, ruleSet, form));
      }
    });
  }

  patchImpactedPageFormGroup(impactedFormGroup: FormGroup): void {
    const { META_DATA, SCHEMA_GROUP_DOM_ID, SCHEMA_GROUP_INDEX, SUB_GROUP_DOM_ID, SUB_GROUP_INDEX, FIELD_NAME, FORM_ITEMS, SHOULD_HIDE } = FormConstants;
    const pageFormGroup = this.mapService.formItemCompositeKeyParentPageFormGroupMap.get(
      this.keyService.getFormItemCompositeKey(
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][SUB_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SUB_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][FIELD_NAME]
      )
    );
    const formItemFormArray = pageFormGroup.controls[FORM_ITEMS] as FormArray;
    const allFormItemsAreHidden = formItemFormArray.controls.every((formItemFormGroup: FormGroup) => formItemFormGroup.value[META_DATA][SHOULD_HIDE]);
    pageFormGroup.controls[META_DATA].setValue({ ...pageFormGroup.value[META_DATA], shouldHide: allFormItemsAreHidden });
  }

  patchImpactedSectionFormGroup(impactedFormGroup: FormGroup): void {
    const { META_DATA, SCHEMA_GROUP_DOM_ID, SCHEMA_GROUP_INDEX, SUB_GROUP_DOM_ID, SUB_GROUP_INDEX, FIELD_NAME, SHOULD_HIDE, PAGES } = FormConstants;
    const sectionFormGroup = this.mapService.formItemCompositeKeyParentSectionFormGroupMap.get(
      this.keyService.getFormItemCompositeKey(
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][SUB_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SUB_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][FIELD_NAME]
      )
    );
    const pagesFormArray = sectionFormGroup.controls[PAGES] as FormArray;
    const allPagesAreHidden = pagesFormArray.controls.every((pageFormGroup: FormGroup) => pageFormGroup.value[META_DATA][SHOULD_HIDE]);
    sectionFormGroup.controls[META_DATA].setValue({ ...sectionFormGroup.value[META_DATA], isActionable: !allPagesAreHidden });
  }

  patchImpactedSubGroupFormGroup(impactedFormGroup: FormGroup): void {
    const { META_DATA, SCHEMA_GROUP_DOM_ID, SCHEMA_GROUP_INDEX, SUB_GROUP_DOM_ID, SUB_GROUP_INDEX, FIELD_NAME, SECTIONS, IS_ACTIONABLE } = FormConstants;
    const subGroupFormGroup = this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap.get(
      this.keyService.getFormItemCompositeKey(
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][SUB_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SUB_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][FIELD_NAME]
      )
    );
    const sectionsFormArray = subGroupFormGroup.controls[SECTIONS] as FormArray;
    const noSectionsAreActionable = sectionsFormArray.controls.every((sectionFormGroup: FormGroup) => !sectionFormGroup.value[META_DATA][IS_ACTIONABLE]);
    subGroupFormGroup.controls[META_DATA].setValue({ ...subGroupFormGroup.value[META_DATA], isActionable: !noSectionsAreActionable });
  }

  private subscribeToSourceFormGroupValueChanges(
    sourceFormGroup: FormGroup,
    rule: Rule,
    informAppCallback: () => void,
    ruleSet: RuleSet,
    form: FormGroup
  ): void {
    const {
      META_DATA,
      TABLE,
      FIELD_NAME,
      SCHEMA_GROUP_INDEX,
      SUB_GROUP_INDEX,
      VALUE,
      SCHEMA_GROUP_DOM_ID,
      SUB_GROUP_DOM_ID,
      INDEX,
      IS_DELETED,
      IS_PLACEHOLDER,
      SECTIONS,
      SHOULD_HIDE,
      IS_DEFAULT_VALUE,
    } = FormConstants;

    const partialCompositeKeyRuleMap = ruleSet.reduce((partialCompositeKeyRuleMap, rule) => {
      partialCompositeKeyRuleMap[this.keyService.getPartialCompositeKey(rule.tableName, rule.columnName)] = rule;
      return partialCompositeKeyRuleMap;
    }, {});

    // unsubscribe from the value changes subscription if it exists
    const compositeKey = this.keyService.getFullCompositeKey(
      sourceFormGroup.value[META_DATA][TABLE],
      sourceFormGroup.value[META_DATA][FIELD_NAME],
      sourceFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
      sourceFormGroup.value[META_DATA][SUB_GROUP_INDEX]
    );
    if (CommonUtils.isDefined(this.fullCompositeKeyValueChangeSubscriptionMap.get(compositeKey))) {
      this.fullCompositeKeyValueChangeSubscriptionMap.get(compositeKey).unsubscribe();
      this.fullCompositeKeyValueChangeSubscriptionMap.delete(compositeKey);
    }

    let isFirstEmmision = true;

    // subscribe to the value changes on the source form group
    // inititate the subscription with the initial value of the form control
    const subscription = sourceFormGroup.controls[VALUE].valueChanges.pipe(startWith(sourceFormGroup.value[VALUE])).subscribe(value => {
      let sourceFormGroupUpdated = false;

      // if the parent schema group is a placeholder, do not update the back end
      const parentSchemaGroup = this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap.get(
        this.keyService.getFormItemCompositeKey(
          sourceFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
          sourceFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
          sourceFormGroup.value[META_DATA][SUB_GROUP_DOM_ID],
          sourceFormGroup.value[META_DATA][SUB_GROUP_INDEX],
          sourceFormGroup.value[META_DATA][FIELD_NAME]
        )
      );

      // if the parent sub group is a placeholder, do not update the back end
      const parentSubGroup = this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap.get(
        this.keyService.getFormItemCompositeKey(
          sourceFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
          sourceFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
          sourceFormGroup.value[META_DATA][SUB_GROUP_DOM_ID],
          sourceFormGroup.value[META_DATA][SUB_GROUP_INDEX],
          sourceFormGroup.value[META_DATA][FIELD_NAME]
        )
      );

      // update the back end if the source form control rule has a default value and the value of the form control is a default value
      // this compound condition will only test true if the value service was unable to find a value in the crash report detail
      // and found a default value in the rule set
      if (
        CommonUtils.isDefined(rule.defaultValue) &&
        sourceFormGroup.value[META_DATA][IS_DEFAULT_VALUE] &&
        !parentSchemaGroup.value[META_DATA][IS_PLACEHOLDER] &&
        !parentSubGroup.value[META_DATA][IS_PLACEHOLDER]
      ) {
        this.store.dispatch(
          CrashReportActions.updateCrashReportDetail({
            metaData: sourceFormGroup.value[META_DATA],
            value: sourceFormGroup.value[VALUE],
            isValid: sourceFormGroup.controls[VALUE].valid,
            isSetInBackground: true,
          })
        );
        sourceFormGroupUpdated = true;
      }

      // value changes property of rule is a hash map of potential values that point to a list of downstream effects
      // sometimes, a * is included in the rule set to indicate any other value
      // this is useful for button groups or other controls with discrete values
      const potentialValues = CommonUtils.hasContent(rule.valueChanges)
        ? CommonUtils.isDefined(rule.valueChanges[value])
          ? rule.valueChanges[value]
          : CommonUtils.isDefined(rule.valueChanges['*'])
            ? rule.valueChanges['*']
            : []
        : [];

      potentialValues.forEach(potentialValue => {
        const potentialValueCompositeKey = this.keyService.getFullCompositeKey(
          potentialValue.tableName,
          potentialValue.columnName,
          sourceFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
          sourceFormGroup.value[META_DATA][SUB_GROUP_INDEX]
        );
        const impactedFormGroup = this.mapService.fullCompositeKeyFormGroupMap.get(potentialValueCompositeKey);
        if (CommonUtils.isDefined(impactedFormGroup)) {
          this.patchImpactedFormItemFormGroup(
            impactedFormGroup,
            potentialValue,
            rule,
            value,
            sourceFormGroup.controls[VALUE].valid,
            isFirstEmmision,
            sourceFormGroupUpdated,
            partialCompositeKeyRuleMap[this.keyService.getPartialCompositeKey(potentialValue.tableName, potentialValue.columnName)]
          );
          // update the visibility on the parent page, section, and sub group form groups and inform the app
          // so the view can be updated
          this.patchImpactedPageFormGroup(impactedFormGroup);
          this.patchImpactedSectionFormGroup(impactedFormGroup);
          this.patchImpactedSubGroupFormGroup(impactedFormGroup);
          informAppCallback();
        }
      });

      // if the rule does not have a repeatable sub group value changes or the repeatable sub group value changes hash does not have a key for the value
      // we do not need to update the visibility of the repeatable sub group
      if (CommonUtils.isEmpty(rule.repeatableSubGroupValueChanges) || CommonUtils.isNullOrUndefined(rule.repeatableSubGroupValueChanges[value])) {
        return;
      }

      rule.repeatableSubGroupValueChanges[value].forEach((repeatableSubGroupValueChange: RepeatableSubGroupValueChange) => {
        // validate the isHidden property exists and that it is a boolean
        if (CommonUtils.isNullOrUndefined(repeatableSubGroupValueChange.isHidden)) {
          throw new TechnicalException('The repeatable sub group value change must have an isHidden property.');
        }
        CommonUtils.assert(
          typeof repeatableSubGroupValueChange.isHidden === 'boolean',
          'The isHidden property of the repeatable sub group value change must be a boolean.'
        );

        // build the schema group dom id index sub group dom id composite key and get a list of repeatable sub groups
        // we can get the schema grou index from the source form group meta data cause one schema group cannot cause value changes in another schema group
        const schemaGroupDomIdIndexSubGroupDomIdCompositeKey = this.keyService.getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(
          repeatableSubGroupValueChange.schemaGroupKey,
          sourceFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
          repeatableSubGroupValueChange.subGroupKey
        );
        const repeatableSubGroups = this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap.get(
          schemaGroupDomIdIndexSubGroupDomIdCompositeKey
        );

        if (CommonUtils.isEmpty(repeatableSubGroups)) {
          return;
        }
        // save the is hidden property to a variable
        const shouldHide = repeatableSubGroupValueChange.isHidden;

        repeatableSubGroups
          // sort the repeatable sub groups by index in descending order so the delete request is sent to the back end in the correct order
          .sort((repeatableSubGroup1, repeatableSubGroup2) => repeatableSubGroup2.value[META_DATA][INDEX] - repeatableSubGroup1.value[META_DATA][INDEX])
          .forEach(repeatableSubGroup => {
            const repeatableSubGroupMetaData = repeatableSubGroup.value[META_DATA];
            let isDeleted = false;

            // if should hide equals true and this not the initial emmision and the repeatable sub group is not deleted or a placeholder
            // remove the repeatable sub group and set the is deleted flag to true
            if (
              CommonUtils.isDefinedAndTrue(shouldHide) &&
              !isFirstEmmision &&
              !repeatableSubGroupMetaData[IS_DELETED] &&
              !repeatableSubGroupMetaData[IS_PLACEHOLDER]
            ) {
              this.removeSubGroup(sourceFormGroup, form, repeatableSubGroupMetaData);
              isDeleted = true;
            }

            // the is actionable flag on the sub group meta data is set to false if all sections are hidden
            const allSectionsAreHiddenSupplier = () => {
              const sectionFormArray = repeatableSubGroup.controls[SECTIONS] as FormArray;
              return sectionFormArray.controls.every((sectionFormGroup: FormGroup) => sectionFormGroup.value[META_DATA][SHOULD_HIDE]);
            };

            // if the repeatable sub group is deleted, all sections are hidden, or the should hide property is true, the is actionable flag is false
            // else the is actionable flag is true
            const isActionable = isDeleted ? false : allSectionsAreHiddenSupplier() ? false : shouldHide ? false : true;

            // update the should hide and is actionable properties on the repeatable sub group meta data
            repeatableSubGroup.controls[META_DATA].setValue({ ...repeatableSubGroup.value[META_DATA], shouldHide, isActionable });
          });

        // update the is actionable map
        this.compositeKeyIsActionableMap[schemaGroupDomIdIndexSubGroupDomIdCompositeKey] = !shouldHide;

        // broadcast the updated is actionable map
        this.compositeKeyIsActionableMapSubject.next(this.compositeKeyIsActionableMap);

        // inform the app to update the view
        informAppCallback();
      });
    });

    this.fullCompositeKeyValueChangeSubscriptionMap.set(compositeKey, subscription);
    isFirstEmmision = false;
  }

  private patchImpactedFormItemFormGroup(
    impactedFormGroup: FormGroup,
    valueChange: ValueChange,
    rule: Rule,
    sourceValue: unknown,
    sourceValueIsValid: boolean,
    isFirstEmmision: boolean,
    sourceFormGroupUpdated: boolean,
    impactedFormGroupRule: Rule
  ): void {
    const { META_DATA, VALUE, SHOULD_HIDE, SCHEMA_GROUP_DOM_ID, SCHEMA_GROUP_INDEX, SUB_GROUP_DOM_ID, SUB_GROUP_INDEX, FIELD_NAME, IS_PLACEHOLDER } =
      FormConstants;

    // save the previous value of the form control, we will need it to determine if the value has changed
    const previousValue = impactedFormGroup.value[VALUE];

    // always set the visibility of the form item based on the value change
    // if isVisible is defined on the value change, negate it to get shouldHide
    // else fall back to the shouldHide property on the form item
    // the meta data service sets a default value of false for shouldHide
    const shouldHide = CommonUtils.isDefinedAndTrue(valueChange.isVisible)
      ? false
      : CommonUtils.isDefinedAndFalse(valueChange.isVisible)
        ? true
        : impactedFormGroup.value[META_DATA][SHOULD_HIDE];
    const patch = { metaData: { ...impactedFormGroup.value[META_DATA], shouldHide } };

    let shouldUpdateBackend = false;

    // if the value change has a shouldOverride property set to true
    if (CommonUtils.isDefinedAndTrue(valueChange.shouldOverride)) {
      switch (true) {
        // and the value change has an override value (use !== undefined to allow for null values)
        case valueChange.overrideValue !== undefined: {
          // and its the first emmision and the source form group was updated
          // meaning a source form control was provided with a default value from the rule set and that value had downstream effects
          // or its not the first emmision (meaning the user interacted with a form control)
          if ((isFirstEmmision && sourceFormGroupUpdated) || !isFirstEmmision) {
            patch[VALUE] = valueChange.overrideValue;
            shouldUpdateBackend = true;
          }
          break;
        }
        // and the value change has an override function
        case CommonUtils.isDefined(valueChange.overrideFunction): {
          // and the source form control has a valid value
          if (CommonUtils.isDefined(sourceValue) && sourceValue !== StringUtils.EMPTY && sourceValueIsValid) {
            // and the source form control was provided with a value from the rule set and that value had downstream effects
            // or its not the first emmision (meaning the user interacted with a form control)
            if ((isFirstEmmision && sourceFormGroupUpdated) || !isFirstEmmision) {
              patch[VALUE] = this.calculateDerivedValue(valueChange.overrideFunction, sourceValue);
              shouldUpdateBackend = true;
            }
          }
          break;
        }
        default: {
          throw new TechnicalException('The value change must have an override value or override function.');
        }
      }
    }

    impactedFormGroup.patchValue(patch);

    // if the parent schema group is a placeholder, do not update the back end
    const parentSchemaGroup = this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap.get(
      this.keyService.getFormItemCompositeKey(
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][SUB_GROUP_DOM_ID],
        impactedFormGroup.value[META_DATA][SUB_GROUP_INDEX],
        impactedFormGroup.value[META_DATA][FIELD_NAME]
      )
    );

    // map the previous value and current value to null if they are empty (empty string or null)
    // and compare the mapped values to determine if the value has changed
    const mappedPreviousValue = CommonUtils.isNullOrUndefinedOrEmptyString(previousValue) ? null : previousValue;
    const mappedCurrentValue = CommonUtils.isNullOrUndefinedOrEmptyString(impactedFormGroup.value[VALUE]) ? null : impactedFormGroup.value[VALUE];
    const valueHasChanged = mappedPreviousValue !== mappedCurrentValue;

    if (
      OssOptional.ofNullable(valueChange.shouldOverride).orElse(false) &&
      shouldUpdateBackend &&
      !parentSchemaGroup.value[META_DATA][IS_PLACEHOLDER] &&
      valueHasChanged
    ) {
      // in the form service, if isDownstreamValueChange tests true, the form control will not be marked as touched
      // we do not want to mark the form control as touched in this case cause this is the result of a value change
      this.store.dispatch(
        CrashReportActions.updateCrashReportDetail({
          metaData: impactedFormGroup.value[META_DATA],
          value: impactedFormGroup.value[VALUE],
          isValid: impactedFormGroup.controls[VALUE].valid,
          isSetInBackground: true,
        })
      );
    }

    // update the validation on the impacted form group
    this.validationService.updateValidationOnImpactedFormControlOnValueChange(impactedFormGroup, valueChange, rule, impactedFormGroupRule);
  }

  private calculateDerivedValue(overrideFunction: string, sourceValue: unknown): unknown {
    switch (overrideFunction) {
      case 'calculateAge': {
        CommonUtils.assert(StringUtils.isString(sourceValue), 'The source value must be a string to calculate age.');
        const birthDate = new Date(sourceValue as string);

        // this shouldn't be necessary anymore as i figured out how to add less than or equal to current date validation  to the ruleset
        // but i will leave it here for now
        if (birthDate > new Date()) {
          console.warn('The birth date is greater than the current date.');
          return null;
        }

        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970).toString();
      }
      default: {
        throw new TechnicalException(`The override function ${overrideFunction} is not supported. supported functions include: calculateAge.`);
      }
    }
  }

  private resetCompositeKeyIsActionableMap(form: FormGroup): void {
    const compositeKeyIsActionableMap = {};

    // iterate over every schema group form array
    Object.entries(form.controls).forEach(([, schemaGroupFormArray]: [string, FormArray]) => {
      // iterate over every schema group form group in the schema group form array
      schemaGroupFormArray.controls.forEach((schemaGroupFormGroup: FormGroup) => {
        const schemaGroupMetaData = schemaGroupFormGroup.value[FormConstants.META_DATA];
        const schemaGroupDomId = schemaGroupMetaData[FormConstants.DOM_ID];
        const schemaGroupIndex = schemaGroupMetaData[FormConstants.INDEX];
        const subGroupFormArray = schemaGroupFormGroup.controls[FormConstants.SUB_GROUPS] as FormArray;
        // iterate over every sub group form group in the schema group form array
        subGroupFormArray.controls
          // filter out the sub groups that do not repeat
          .filter((subGroupFormGroup: FormGroup) => {
            const subGroupMetaData = subGroupFormGroup.value[FormConstants.META_DATA];
            return subGroupMetaData[FormConstants.DOES_REPEAT];
          })
          .forEach((subGroupFormGroup: FormGroup) => {
            const subGroupMetaData = subGroupFormGroup.value[FormConstants.META_DATA];
            const subGroupDomId = subGroupMetaData[FormConstants.DOM_ID];
            const compositeKey = this.keyService.getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(schemaGroupDomId, schemaGroupIndex, subGroupDomId);
            // default the is actionable flag to true
            compositeKeyIsActionableMap[compositeKey] = true;
          });
      });
    });

    this.compositeKeyIsActionableMap = compositeKeyIsActionableMap;
  }

  private removeSubGroup(sourceFormGroup: FormGroup, form: FormGroup, repeatableSubGroupMetaData: SubGroupMetaData): void {
    const { schemaGroupDomId, schemaGroupIndex } = sourceFormGroup.value[FormConstants.META_DATA];

    const schemaGroupFormArray = OssOptional.ofNullable(
      Object.entries(form.controls).find(([currentSchemaGroupDomId]) => currentSchemaGroupDomId === schemaGroupDomId)
    )
      .map(([, schemaGroupFormArray]) => schemaGroupFormArray as FormArray)
      .orElseThrow(() => new TechnicalException(`Schema group form array not found for schema group dom id ${schemaGroupDomId}`));

    const schemaGroupFormGroup = OssStream.from(schemaGroupFormArray.controls)
      .filter(schemaGroup => schemaGroup.value[FormConstants.META_DATA][FormConstants.INDEX] === schemaGroupIndex)
      .findFirst()
      .orElseThrow(() => new TechnicalException(`Schema group form group not found for schema group dom id ${schemaGroupDomId}`)) as FormGroup;

    this.store.dispatch(
      CrashReportStructuralActions.removeSubGroup({
        schemaGroupMetaData: schemaGroupFormGroup.value[FormConstants.META_DATA],
        subGroupMetaData: repeatableSubGroupMetaData,
        subGroupWasHiddenOnValueChange: true,
      })
    );
  }
}
