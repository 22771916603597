import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormConstants } from '../../crash-report/constants/form.constants';
import { FormItemType } from '../../crash-report/data-model/enums/form-item-type.enum';
import { isSelectable, Selectable } from '../data-model/models/selectable.model';
import { TechnicalException } from '../data-model/models/technical-exception.model';
import { CollectionUtils } from '../utils/collection-utils';
import { CommonUtils } from '../utils/common-utils';
import { StringUtils } from '../utils/string-utils';

export class StringValidator {
  static alphanumeric(): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      const value = formControl.value;
      if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY || /^[A-Z0-9]*$/i.test(value)) {
        return null;
      }
      return { alphanumeric: { message: 'Only alphanumeric characters are allowed.' } };
    };
  }

  static email(): ValidatorFn {
    return Validators.email;
  }

  static phoneNumber(): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      const value = formControl.value;
      if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY || /^\d{3}-\d{3}-\d{4}$/.test(value)) {
        return null;
      }
      return { phoneNumber: { message: 'Must be in format 111-222-3333' } };
    };
  }

  static vehicleIdentificationNumber(): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      const value = formControl.value;
      if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY || /^[A-HJ-NPR-Z0-9]{17}$/.test(value)) {
        return null;
      }
      return { vin: { message: 'Must be 17 alphanumeric characters' } };
    };
  }

  static minLength(minLength: number): ValidatorFn {
    return Validators.minLength(minLength);
  }

  static maxLength(maxLength: number): ValidatorFn {
    return Validators.maxLength(maxLength);
  }

  // TODO: this needs to be cleaned up. it's probably better to split the string on commas
  // in the value service
  static equals(formGroup: FormGroup, ...values: Selectable[]): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (StringValidator.formGroupTypeIsButtonGroupButtonGroupMultiECrashRefOrECrashRefMulti(formGroup)) {
        return StringValidator.validateButtonGroup(
          formControl,
          values,
          () => null,
          () => {
            const returnValues = CollectionUtils.toCommaSeparatedList(
              values.map(value => value.label),
              'or'
            );
            return { equals: { values: returnValues } };
          }
        );
      }

      const foundValue = values.find(value => value.value === formControl.value);
      if (CommonUtils.isDefined(foundValue)) {
        return null;
      }
      const returnValues = CollectionUtils.toCommaSeparatedList(
        values.map(value => value.label),
        'or'
      );
      return { equals: { values: returnValues } };
    };
  }

  static doesNotEqual(formGroup: FormGroup, ...values: Selectable[]): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (StringValidator.formGroupTypeIsButtonGroupButtonGroupMultiECrashRefOrECrashRefMulti(formGroup)) {
        return StringValidator.validateButtonGroup(
          formControl,
          values,
          () => {
            const returnValues = CollectionUtils.toCommaSeparatedList(
              values.map(value => value.label),
              'or'
            );
            return { doesNotEqual: { values: returnValues } };
          },
          () => null
        );
      }

      const foundValue = values.find(value => value.value === formControl.value);
      if (CommonUtils.isNullOrUndefined(foundValue)) {
        return null;
      }
      const returnValues = CollectionUtils.toCommaSeparatedList(
        values.map(value => value.label),
        'or'
      );
      return { doesNotEqual: { values: returnValues } };
    };
  }

  private static formGroupTypeIsButtonGroupButtonGroupMultiECrashRefOrECrashRefMulti(formGroup: FormGroup): boolean {
    const type = formGroup.value[FormConstants.META_DATA][FormConstants.TYPE];
    return [FormItemType.BUTTON_GROUP, FormItemType.BUTTON_GROUP_MULTI, FormItemType.ECRASH_REF, FormItemType.ECRASH_REF_MULTI].includes(type);
  }

  private static validateButtonGroup(
    formControl: FormControl,
    targetSelectables: Selectable[],
    foundValueCallback: () => ValidationErrors | null,
    didNotFindValueCallback: () => ValidationErrors | null
  ): ValidationErrors | null {
    let formControlValues;
    switch (true) {
      case CommonUtils.isNullOrUndefined(formControl.value):
        formControlValues = [];
        break;
      case StringUtils.isString(formControl.value):
        formControlValues = (formControl.value as string).split(',');
        break;
      case CommonUtils.isArray(formControl.value):
        formControlValues = formControl.value;
        break;
      case isSelectable(formControl.value): {
        console.warn('in StringValidator::validateButtonGroup. formControl.value is a selectable. this seems wrong.');
        formControlValues = formControl.value.value;
        break;
      }
      default:
        throw new TechnicalException('form control value should be a string or an array');
    }
    const targetValues = targetSelectables.map(selectable => selectable.value);
    for (let index = 0; index < formControlValues.length; index++) {
      const formControlValue = formControlValues[index];
      if (targetValues.includes(formControlValue)) {
        return foundValueCallback();
      }
    }
    return didNotFindValueCallback();
  }

  static validatorNameValidatorFunctionMap: Map<string, (...args: unknown[]) => ValidatorFn>;

  static {
    StringValidator.validatorNameValidatorFunctionMap = new Map<string, (...args: unknown[]) => ValidatorFn>();
    this.populateValidatorNameValidatorFunctionMap();
  }

  private static populateValidatorNameValidatorFunctionMap(): void {
    StringValidator.validatorNameValidatorFunctionMap.set('minLength', StringValidator.minLength);
    StringValidator.validatorNameValidatorFunctionMap.set('maxLength', StringValidator.maxLength);
    StringValidator.validatorNameValidatorFunctionMap.set('equals', StringValidator.equals);
    StringValidator.validatorNameValidatorFunctionMap.set('doesNotEqual', StringValidator.doesNotEqual);
    StringValidator.validatorNameValidatorFunctionMap.set('vehicleIdentificationNumber', StringValidator.vehicleIdentificationNumber);
    StringValidator.validatorNameValidatorFunctionMap.set('email', StringValidator.email);
    StringValidator.validatorNameValidatorFunctionMap.set('alphanumeric', StringValidator.alphanumeric);
  }
}
