export enum CrashReportStatus {
  agent_qc_check = 'agent_qc_check',
  in_progress = 'in_progress',
  incomplete = 'incomplete',
  secondary_incomplete = 'secondary_incomplete',
  open = 'open',
  ready = 'ready',
  secondary_qc_check = 'secondary_qc_check',
  send_to_carrier = 'send_to_carrier',
  submitted = 'submitted',
  rejected = 'rejected',
}
