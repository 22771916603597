import { createAction, props } from '@ngrx/store';
import { CrashReportDetailStatus } from '../../data-model/types/crash-report-detail-status.type';

const feature = '[Crash Report Status]';

export const updateCrashReportStatus = createAction(
  `${feature} Update Crash Report Status`,
  props<{ crashReportNumber: string; status: CrashReportDetailStatus }>()
);
export const crashReportStatusUpdated = createAction(`${feature} Crash Report Status Updated`, props<{ newStatus: CrashReportDetailStatus }>());
