import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { UnitState } from '../state/unit.state';

export const selectUnitState = (state: AppState) => state.unit;

export const selectUnits = createSelector(selectUnitState, (state: UnitState) => state.units);
export const selectUnit = createSelector(selectUnitState, (state: UnitState) => state.unit);
export const selectUnitName = createSelector(selectUnit, unit =>
  OssOptional.ofNullable(unit)
    .map(unit => unit.name)
    .orElseNull()
);
export const selectAssignmentId = createSelector(selectUnitState, (state: UnitState) => state.assignmentId);
