import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { DateUtils } from '../../../shared/utils/date-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { OssActivityRecord } from '../../data-model/models/oss-activity-record.model';
import { AggregatedActivityRecords } from '../../data-model/types/aggregated-activity-records.type';
import { ActivityState } from '../state/activity.state';

export const selectActivityState = (state: AppState) => state.activity;

export const selectActivityRecords = createSelector(selectActivityState, (state: ActivityState) => state.activityRecords);
export const selectNumberOfActivityRecords = createSelector(selectActivityState, (state: ActivityState) => state.numberOfActivityRecords);

export const selectAggregatedActivityRecords = createSelector(selectActivityRecords, (activityRecords: OssActivityRecord[]): AggregatedActivityRecords => {
  // aggregate activity records on created at date
  const createdAtDateActivityRecordsMap = activityRecords.reduce((createdAtDateActivityRecordsMap, activityRecord) => {
    const createdAtDateString = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(activityRecord.created_at.toString());
    OssOptional.ofNullable(createdAtDateActivityRecordsMap[createdAtDateString]).ifPresentOrElse(
      activityRecords => activityRecords.push(activityRecord),
      () => (createdAtDateActivityRecordsMap[createdAtDateString] = [activityRecord])
    );
    return createdAtDateActivityRecordsMap;
  }, {});

  // sort entries on created at date descending
  const sortedEntries = Object.entries(createdAtDateActivityRecordsMap).sort(
    (element1, element2) => new Date(element2[0]).getTime() - new Date(element1[0]).getTime()
  );

  // convert list of entry tuples to list of objects containing date and elements
  const sortedList = sortedEntries.reduce((dateActivityRecords, [date, activityRecords]) => {
    dateActivityRecords.push({ date, activityRecords });
    return dateActivityRecords;
  }, []);

  // update notes property on each activity record -- set to null if there are no notes and set to comments if notes and notes.comments exist
  const sortedListWithUpdatedComments = sortedList.map(element => ({
    date: element.date,
    activityRecords: element.activityRecords.map(activityRecord => {
      const comments = OssOptional.ofNullable(
        OssOptional.ofNullable(activityRecord.notes)
          .map(notes => {
            try {
              return JSON.parse(notes);
            } catch (error) {
              return null;
            }
          })
          .orElseNull()
      )
        .map(notes => notes.comments)
        .orElseNull();
      return { ...activityRecord, notes: comments };
    }),
  }));

  return sortedListWithUpdatedComments;
});

export const selectVerificationStatus = createSelector(selectActivityState, (state: ActivityState) => state.verificationStatus);
export const selectLimit = createSelector(selectActivityState, (state: ActivityState) => state.limit);
export const selectOffset = createSelector(selectActivityState, (state: ActivityState) => state.offset);

export const selectNumberOfUnverifiedActivityRecords = createSelector(selectActivityState, (state: ActivityState) => state.numberOfUnverifiedActivityRecords);
export const selectNumberOfMissingActivityRecords = createSelector(selectActivityState, (state: ActivityState) => state.numberOfMissingActivityRecords);
export const selectNumberOfUnverifiedAndMissingActivityRecords = createSelector(
  selectNumberOfUnverifiedActivityRecords,
  selectNumberOfMissingActivityRecords,
  (numberOfUnverifiedActivityRecords, numberOfMissingActivityRecords) => numberOfUnverifiedActivityRecords + numberOfMissingActivityRecords
);

export const selectActivityRecord = createSelector(selectActivityState, (state: ActivityState) => state.activityRecord);
