import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkStatusService {
  get networkStatus$(): Observable<boolean> {
    return this.networkStatusSubject.asObservable();
  }

  get isOnline(): boolean {
    return this.networkStatusSubject.value;
  }

  get isOffline(): boolean {
    return !this.isOnline;
  }

  private networkStatusSubject = new BehaviorSubject<boolean>(navigator.onLine);

  constructor() {
    window.addEventListener('online', () => this.updateNetworkStatus(true));
    window.addEventListener('offline', () => this.updateNetworkStatus(false));
  }

  private updateNetworkStatus(isOnline: boolean): void {
    this.networkStatusSubject.next(isOnline);
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'NETWORK_STATUS_UPDATE', isOnline });
    }
  }
}
