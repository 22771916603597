import { Injectable } from '@angular/core';
import { ClientCurrentUser } from '../../../auth/data-model/models/client-current-user.model';
import { OssUnit } from '../../../crash-report/data-model/models/oss-unit.model';
import { ServiceLocation } from '../../../crash-report/data-model/models/service-location.model';
import { LocalStorageConstants } from '../../constants/local-storage.constants';
import { OssOptional } from '../../utils/oss-optional';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  // setters
  setApiToken(apiToken: string): void {
    localStorage.setItem(LocalStorageConstants.API_TOKEN_KEY, apiToken);
  }
  setUser(user: ClientCurrentUser): void {
    localStorage.setItem(LocalStorageConstants.USER_KEY, JSON.stringify(user));
  }
  setServiceLocation(serviceLocation: ServiceLocation): void {
    localStorage.setItem(LocalStorageConstants.SERVICE_LOCATION_KEY, JSON.stringify(serviceLocation));
  }
  setUnit(unit: OssUnit): void {
    localStorage.setItem(LocalStorageConstants.UNIT_KEY, JSON.stringify(unit));
  }
  setAssignmentId(assignmentId: number): void {
    localStorage.setItem(LocalStorageConstants.ASSIGNMENT_ID_KEY, assignmentId.toString());
  }

  // clear-ers
  clearApiToken(): void {
    localStorage.removeItem(LocalStorageConstants.API_TOKEN_KEY);
  }
  clearUser(): void {
    localStorage.removeItem(LocalStorageConstants.USER_KEY);
  }
  clearServiceLocation(): void {
    localStorage.removeItem(LocalStorageConstants.SERVICE_LOCATION_KEY);
  }
  clearUnit(): void {
    localStorage.removeItem(LocalStorageConstants.UNIT_KEY);
  }
  clearAssignmentId(): void {
    localStorage.removeItem(LocalStorageConstants.ASSIGNMENT_ID_KEY);
  }

  // getters
  getApiToken(): string | null {
    return localStorage.getItem(LocalStorageConstants.API_TOKEN_KEY);
  }
  getUser(): string | null {
    return localStorage.getItem(LocalStorageConstants.USER_KEY);
  }
  getServiceLocation(): string | null {
    return localStorage.getItem(LocalStorageConstants.SERVICE_LOCATION_KEY);
  }
  getUnit(): string | null {
    return OssOptional.ofNullable(localStorage.getItem(LocalStorageConstants.UNIT_KEY)).map(JSON.parse).orElseNull();
  }
  getAssignmentId(): string | null {
    return localStorage.getItem(LocalStorageConstants.ASSIGNMENT_ID_KEY);
  }
}
