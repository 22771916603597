import { Injectable } from '@angular/core';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { UnsupportedOperationException } from '../../../../shared/data-model/models/unsupported-operation-exception.model';
import { BooleanUtils } from '../../../../shared/utils/boolean-utils';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { DateUtils } from '../../../../shared/utils/date-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { SchemaConstants } from '../../../constants/schema.constants';
import { DefaultValueType } from '../../../data-model/enums/default-value-type.enum';
import { FormItemType, getFormItemType } from '../../../data-model/enums/form-item-type.enum';
import { CrashReportDetail } from '../../../data-model/models/crash-report-detail.model';
import { FormItem } from '../../../data-model/models/form-item.model';
import { Rule } from '../../../data-model/models/rule.model';
import { SchemaGroup } from '../../../data-model/models/schema-group.model';
import { SubGroup } from '../../../data-model/models/sub-group.model';
import { CrashReportDetailFormKeyService } from '../crash-report-detail-form-key/crash-report-detail-form-key.service';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailFormValueService {
  constructor(private readonly keyService: CrashReportDetailFormKeyService) {}

  getValue(
    formItem: FormItem,
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    isNewCrashReport: boolean
  ): { value: unknown; isDefaultValue: boolean } {
    let value = this.getValueFromCrashReportDetail(formItem, crashReportDetail, schemaGroup, schemaGroupIndex, subGroup, subGroupIndex);
    switch (true) {
      case formItem.type === 'button-group' && formItem.max_length === 1: {
        CommonUtils.assert(
          BooleanUtils.isBoolean(value) || CommonUtils.isNullOrUndefined(value),
          `crash report detail value is not a boolean, null, or undefined: ${value}`
        );
        value = this.mapBooleanToYesOrNo(value as boolean | null);
        break;
      }
      // value is a date string, convert it to MM/DD/YYYY. when an update request is made, MM/DD/YYYY string is converted back to a date string
      case formItem.type === 'date': {
        value = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(value as string);
        break;
      }
      // value is HH:MM, convert it to HHMM. when an update request is made, HHMM string is converted back to HH:MM
      case formItem.type === 'time': {
        value = DateUtils.convertHhColonMmToHhMmOrNull(value as string);
        break;
      }
    }
    const rule = partialCompositeKeyRuleMap.get(this.keyService.getPartialCompositeKey(formItem.table, formItem.fieldName));
    if (CommonUtils.isDefined(rule) && CommonUtils.hasContent(rule.defaultValue) && CommonUtils.isDefinedAndTrue(isNewCrashReport)) {
      value = this.getDefaultValueFromRule(rule);
      return { value, isDefaultValue: true };
    }
    return { value, isDefaultValue: false };
  }

  private getValueFromCrashReportDetail(
    formItem: FormItem,
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number
  ): unknown {
    // crash report detail is null or undefined or empty if the app is offline
    if (CommonUtils.isNullOrUndefined(crashReportDetail) || CommonUtils.isEmpty(crashReportDetail)) {
      return null;
    }

    const { JUCTION_LOCATION_DESC, JUNCTION_LOCATION_DESC, NARRATIVE, NARR, MAN_COLL_CD, MAN_COLL_DESC, DIAGRAM } = SchemaConstants;
    const fieldName = formItem.fieldName.toLowerCase();

    if (CommonUtils.isDefinedAndTrue(schemaGroup.repeats) || CommonUtils.isDefinedAndTrue(subGroup.repeats)) {
      return this.getValueWhenGroupIsRepeatable(crashReportDetail, schemaGroup, schemaGroupIndex, subGroup, subGroupIndex, fieldName);
    }

    // each crash report has two rows in the narr_tb relation
    // the row with a diagram_num of 1 contains the diagram
    // the row with a diagram_num of 0 or null contains all other fields
    CommonUtils.assert(
      CommonUtils.isDefined(crashReportDetail[NARR]) && CommonUtils.isArray(crashReportDetail[NARR]),
      "crash report detail at key 'narr' is not defined or is not an array"
    );
    const diagramNum = 'diagram_num';
    const narrativeContainingDiagram = (crashReportDetail[NARR] as unknown[]).find(narrative => narrative[diagramNum] === 1);
    const narrativeContainingNarrativeManColCdAndManCollDesc = (crashReportDetail[NARR] as unknown[]).find(
      narrative => CommonUtils.isNullOrUndefined(narrative[diagramNum]) || narrative[diagramNum] === 0
    );

    switch (fieldName) {
      case JUCTION_LOCATION_DESC:
        return crashReportDetail[JUNCTION_LOCATION_DESC];

      case NARRATIVE:
        return OssOptional.ofNullable(narrativeContainingNarrativeManColCdAndManCollDesc)
          .map(narrative => narrative[NARRATIVE])
          .orElseNull();

      case MAN_COLL_CD:
        return OssOptional.ofNullable(narrativeContainingNarrativeManColCdAndManCollDesc)
          .map(narrative => narrative[MAN_COLL_CD])
          .orElseNull();

      case MAN_COLL_DESC:
        return OssOptional.ofNullable(narrativeContainingNarrativeManColCdAndManCollDesc)
          .map(narrative => narrative[MAN_COLL_DESC])
          .orElseNull();

      case DIAGRAM:
        return OssOptional.ofNullable(narrativeContainingDiagram)
          .map(narrative => narrative[DIAGRAM])
          .orElseNull();

      case StringUtils.EMPTY:
        switch (getFormItemType(formItem.type)) {
          case FormItemType.HELP:
          case FormItemType.SCANNER:
            return null;
          default:
            throw new TechnicalException('invalid form item type. valid types include HELP and SCANNER');
        }
    }

    if (!(fieldName in crashReportDetail)) {
      throw new TechnicalException(`key value pair with key ${fieldName} does not exist in crash report detail`);
    }

    return crashReportDetail[fieldName];
  }

  private mapBooleanToYesOrNo(value: boolean | null | undefined): 'Y' | 'N' | null {
    return value === true ? 'Y' : value === false ? 'N' : null;
  }

  private getValueWhenGroupIsRepeatable(
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    fieldName: string
  ): unknown {
    switch (true) {
      case CommonUtils.isDefinedAndTrue(schemaGroup.repeats) && CommonUtils.isDefinedAndTrue(subGroup.repeats): {
        const subGroupsFromCrashReportDetail = crashReportDetail[subGroup.key];
        if (CommonUtils.isEmpty(subGroupsFromCrashReportDetail)) {
          return null;
        }
        const subGroupFromCrashReportDetail = (subGroupsFromCrashReportDetail as unknown[]).find(currentSubGroup => {
          if (CommonUtils.isNullOrUndefined(schemaGroup.db_repeat_field)) {
            throw new TechnicalException('schema group db repeat field is null or undefined');
          }
          if (CommonUtils.isNullOrUndefined(subGroup.db_repeat_field)) {
            throw new TechnicalException('sub group db repeat field is null or undefined');
          }
          return currentSubGroup[schemaGroup.db_repeat_field] === schemaGroupIndex + 1 && currentSubGroup[subGroup.db_repeat_field] === subGroupIndex + 1;
        });
        if (CommonUtils.isNullOrUndefined(subGroupFromCrashReportDetail)) {
          return null;
        }
        return OssOptional.ofNullable(subGroupFromCrashReportDetail[fieldName]).orElseNull();
      }
      case CommonUtils.isDefinedAndTrue(schemaGroup.repeats) && CommonUtils.isDefinedAndFalse(subGroup.repeats): {
        const schemaGroupsFromCrashReportDetail = crashReportDetail[schemaGroup.key];
        // no schema groups have been added yet
        if (CommonUtils.isEmpty(schemaGroupsFromCrashReportDetail)) {
          return null;
        }
        // schema group is new
        if (CommonUtils.isNullOrUndefined(schemaGroupsFromCrashReportDetail[schemaGroupIndex])) {
          return null;
        }
        return OssOptional.ofNullable(schemaGroupsFromCrashReportDetail[schemaGroupIndex][fieldName]).orElseNull();
      }
      case CommonUtils.isDefinedAndFalse(schemaGroup.repeats) && CommonUtils.isDefinedAndTrue(subGroup.repeats): {
        const subGroupsFromCrashReportDetail = crashReportDetail[subGroup.key];
        // no sub groups have been added yet
        if (CommonUtils.isEmpty(subGroupsFromCrashReportDetail)) {
          return null;
        }
        // sub group is new
        if (CommonUtils.isNullOrUndefined(subGroupsFromCrashReportDetail[subGroupIndex])) {
          return null;
        }
        return OssOptional.ofNullable(subGroupsFromCrashReportDetail[subGroupIndex][fieldName]).orElseNull();
      }
      case CommonUtils.isDefinedAndFalse(schemaGroup.repeats) && CommonUtils.isDefinedAndFalse(subGroup.repeats):
        return crashReportDetail[fieldName];
    }
    throw new UnsupportedOperationException('unable to determine value when group is repeatable');
  }

  private getDefaultValueFromRule(rule: Rule): unknown {
    let value;
    switch (rule.defaultValue.type) {
      case DefaultValueType.STRING:
      case DefaultValueType.SELECTABLE: {
        value = rule.defaultValue.value;
        break;
      }
      case DefaultValueType.NUMBER: {
        value = +rule.defaultValue.value;
        break;
      }
      case DefaultValueType.DATE: {
        if (CommonUtils.isDefinedAndTrue(rule.defaultValue.useCurrentDate)) {
          value = new Date().toISOString();
        }
        break;
      }
      default: {
        throw new UnsupportedOperationException(
          'unimplemented default value type. implemented types include: STRING, SELECTABLE, NUMBER, DATE, SEQUENCE, and FOREIGN_KEY'
        );
      }
    }
    return value;
  }
}
