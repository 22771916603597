import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { SchemaState } from '../state/schema.state';

export const selectSchemaState = (state: AppState) => state.schema;

export const selectSchema = createSelector(selectSchemaState, (state: SchemaState) => state.schema);
export const selectSchemaVersion = createSelector(selectSchemaState, (state: SchemaState) => state.schemaVersion);

export const selectDefaultSchema = createSelector(selectSchemaState, (state: SchemaState) => state?.defaultSchema); // is the ? needed here
export const selectDefaultSchemaVersion = createSelector(selectSchemaState, (state: SchemaState) => state.defaultSchemaVersion);
