const shouldLog = false;

export function Timer() {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const start = performance.now();
      const result = originalMethod.apply(this, args);
      const end = performance.now();
      const executionTime = end - start;
      const roundedExecutionTime = Math.round(executionTime * 100) / 100;

      if (shouldLog) {
        console.info(`Method ${propertyKey} executed in ${roundedExecutionTime} milliseconds.`);
      }

      return result;
    };

    return descriptor;
  };
}
