import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state/app.state';
import { SnackbarLevel } from '../../data-model/types/snackbar-level.type';
import { SnackbarActions } from '../../store/actions';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private readonly store: Store<AppState>) {}

  showSnackbar(message: string, level: SnackbarLevel, time: number = 3000): void {
    this.store.dispatch(SnackbarActions.showSnackbar({ message, level, time }));
  }

  hideSnackbar(): void {
    this.store.dispatch(SnackbarActions.hideSnackbar());
  }
}
