export class FormConstants {
  static readonly ALLOWED_SELECTIONS = 'allowedSelections';
  static readonly API_INDEX = 'apiIndex';
  static readonly DOES_REPEAT = 'doesRepeat';
  static readonly DOM_ID = 'domId';
  static readonly FIELD_NAME = 'fieldName';
  static readonly FORM_CONTROL_TYPE = 'formControlType';
  static readonly FORM_ITEMS = 'formItems';
  static readonly HIDDEN_ITEMS = 'hiddenItems';
  static readonly INDEX = 'index';
  static readonly IS_ACTIONABLE = 'isActionable';
  static readonly IS_DEFAULT_VALUE = 'isDefaultValue';
  static readonly IS_DELETED = 'isDeleted';
  static readonly IS_PLACEHOLDER = 'isPlaceholder';
  static readonly IS_REQUIRED = 'isRequired';
  static readonly ITEMS = 'items';
  static readonly KEY = 'key';
  static readonly LABEL = 'label';
  static readonly LABEL_OVERRIDES = 'labelOverrides';
  static readonly MAX_LENGTH = 'max_length';
  static readonly META_DATA = 'metaData';
  static readonly NAME = 'name';
  static readonly ORDER_OVERRIDE = 'orderOverride';
  static readonly PAGES = 'pages';
  static readonly PLACEHOLDER = 'placeholder';
  static readonly SCHEMA_GROUP_API_INDEX = 'schemaGroupApiIndex';
  static readonly SCHEMA_GROUP_DOM_ID = 'schemaGroupDomId';
  static readonly SCHEMA_GROUP_INDEX = 'schemaGroupIndex';
  static readonly SECTIONS = 'sections';
  static readonly SHOULD_HIDE = 'shouldHide';
  static readonly SUB_GROUP_API_INDEX = 'subGroupApiIndex';
  static readonly SUB_GROUP_DOM_ID = 'subGroupDomId';
  static readonly SUB_GROUP_INDEX = 'subGroupIndex';
  static readonly SUB_GROUPS = 'subGroups';
  static readonly TABLE = 'table';
  static readonly TEMPLATE = 'template';
  static readonly TEMPLATE_DATA = 'templateData';
  static readonly TEMPLATE_SLOT = 'templateSlot';
  static readonly TITLE = 'title';
  static readonly TYPE = 'type';
  static readonly VALUE = 'value';
  static readonly VISIBILITY_OVERRIDES = 'visibilityOverrides';
}
