import { createAction, props } from '@ngrx/store';
import { Schema } from '../../data-model/types/schema.type';

const feature = '[Schema]';

export const loadSchema = createAction(`${feature} Load Schema`);
export const schemaLoaded = createAction(`${feature} Schema Loaded`, props<{ schema: Schema; version: number }>());

export const loadDefaultSchema = createAction(`${feature} Load Default Schema`);
export const defaultSchemaLoaded = createAction(`${feature} Default Schema Loaded`, props<{ schema: Schema; version: number }>());
