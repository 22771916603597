import { Injectable } from '@angular/core';
import posthog, { Properties } from 'posthog-js';

@Injectable({ providedIn: 'root' })
export class PosthogService {
  captureReportSubmittedEvent(ossId: string, reportWritten: boolean, agent: unknown, serviceLocationId: number): void {
    const properties: Properties = {
      start_time: Date.now(),
      oss_id: ossId,
      report_written: reportWritten,
      agent: agent,
      service_location_id: serviceLocationId,
    };
    this.captureEvent('Report Submitted', properties);
  }

  captureItemCreatedEvent(ossId: string, agent: unknown, serviceLocationId: number): void {
    const properties: Properties = {
      start_time: Date.now(),
      oss_id: ossId,
      agent: agent,
      service_location_id: serviceLocationId,
    };
    this.captureEvent('Item Created', properties);
  }

  captureNonReportItemCreatedEvent(ossId: string, agent: unknown, serviceLocationId: number, disposition: string): void {
    const properties: Properties = {
      start_time: Date.now(),
      oss_id: ossId,
      agent: agent,
      service_location_id: serviceLocationId,
      disposition: disposition,
    };
    this.captureEvent('Non-Report Item Submitted', properties);
  }

  private captureEvent(eventName: string, properties: Properties): void {
    posthog.capture(eventName, properties);
  }
}
