import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Rule } from '../../data-model/models/rule.model';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailMapService {
  formItemCompositeKeyFormGroupMap: Map<string, FormGroup>;
  fullCompositeKeyFormGroupMap: Map<string, FormGroup>;
  partialCompositeKeyFormGroupsMap: Map<string, FormGroup[]>;
  schemaGroupCompositeKeyFormGroupMap: Map<string, FormGroup>;
  subGroupCompositeKeyFormGroupMap: Map<string, FormGroup>;

  formItemCompositeKeyParentPageFormGroupMap: Map<string, FormGroup>;
  formItemCompositeKeyParentSectionFormGroupMap: Map<string, FormGroup>;
  formItemCompositeKeyParentSubGroupFormGroupMap: Map<string, FormGroup>;
  formItemCompositeKeyParentSchemaGroupFormGroupMap: Map<string, FormGroup>;

  partialCompositeKeyRuleMap: Map<string, Rule>;

  schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap: Map<string, FormGroup[]>;

  fieldNameValueLabelMap: Map<string, Map<string, string>>;

  initializeMaps(): void {
    this.formItemCompositeKeyFormGroupMap = new Map<string, FormGroup>();
    this.fullCompositeKeyFormGroupMap = new Map<string, FormGroup>();
    this.partialCompositeKeyFormGroupsMap = new Map<string, FormGroup[]>();
    this.schemaGroupCompositeKeyFormGroupMap = new Map<string, FormGroup>();
    this.subGroupCompositeKeyFormGroupMap = new Map<string, FormGroup>();

    this.formItemCompositeKeyParentPageFormGroupMap = new Map<string, FormGroup>();
    this.formItemCompositeKeyParentSectionFormGroupMap = new Map<string, FormGroup>();
    this.formItemCompositeKeyParentSubGroupFormGroupMap = new Map<string, FormGroup>();
    this.formItemCompositeKeyParentSchemaGroupFormGroupMap = new Map<string, FormGroup>();

    this.partialCompositeKeyRuleMap = new Map<string, Rule>();

    this.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap = new Map<string, FormGroup[]>();

    this.fieldNameValueLabelMap = new Map<string, Map<string, string>>();
  }
}
