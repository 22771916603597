import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailFormKeyService {
  getPartialCompositeKey(tableName: string, columnName: string): string {
    return [tableName.toLowerCase(), columnName.toLowerCase()].join(':');
  }

  getFullCompositeKey(tableName: string, columnName: string, schemaGroupIndex: number, subGroupIndex: number): string {
    return [tableName.toLowerCase(), columnName.toLowerCase(), schemaGroupIndex, subGroupIndex].join(':');
  }

  getSchemaGroupCompositeKey(schemaGroupDomId: string, schemaGroupIndex: number): string {
    return [schemaGroupDomId.toLowerCase(), schemaGroupIndex].join(':');
  }

  getSubGroupCompositeKey(schemaGroupDomId: string, schemaGroupIndex: number, subGroupDomId: string, subGroupIndex: number): string {
    return [schemaGroupDomId.toLowerCase(), schemaGroupIndex, subGroupDomId.toLowerCase(), subGroupIndex].join(':');
  }

  getFormItemCompositeKey(schemaGroupDomId: string, schemaGroupIndex: number, subGroupDomId: string, subGroupIndex: number, formItemFieldName: string): string {
    return [schemaGroupDomId.toLowerCase(), schemaGroupIndex, subGroupDomId.toLowerCase(), subGroupIndex, formItemFieldName.toLowerCase()].join(':');
  }

  getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(schemaGroupDomId: string, schemaGroupIndex: number, subGroupDomId: string): string {
    return [schemaGroupDomId.toLowerCase(), schemaGroupIndex, subGroupDomId.toLowerCase()].join(':');
  }
}
