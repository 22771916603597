import { createAction, props } from '@ngrx/store';
import { ECrashValidation } from '../../data-model/models/e-crash-validation.model';

const feature = '[E-Crash]';

export const loadValidationObject = createAction(`${feature} Load Validation Object`);
export const validationObjectLoaded = createAction(`${feature} Validation Object Loaded`, props<{ validationObject: ECrashValidation }>());

export const submitCrashReport = createAction(`${feature} Submit Crash Report`);
export const crashReportSubmitted = createAction(`${feature} Crash Report Submitted`);
