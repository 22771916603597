export class ObjectUtils {
  static equals(obj1: unknown, obj2: unknown, deep: boolean = false): boolean {
    if (obj1 === obj2) {
      return true;
    }
    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
      if (deep && obj1[key] !== null && obj2[key] !== null && typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (!ObjectUtils.equals(obj1[key], obj2[key], true)) {
          return false;
        }
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  static doesNotEqual(obj1: unknown, obj2: unknown, deep: boolean = false): boolean {
    return !ObjectUtils.equals(obj1, obj2, deep);
  }

  static isObject(value: unknown): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  static shallowClone<T>(value: T): T {
    return { ...value };
  }
}
