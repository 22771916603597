import { createAction, props } from '@ngrx/store';
import { CrashReportDetail } from '../../data-model/models/crash-report-detail.model';
import { CrashReportSummary } from '../../data-model/models/crash-report-summary.model';
import { FormItemMetaData } from '../../data-model/models/form-item-meta-data.model';
import { NewCrashReportFormValue } from '../../data-model/models/new-crash-report-form-value.model';
import { SupervisorCrashReportSummary } from '../../data-model/models/supevisor-crash-report-summary.model';
import { CrashReportSummaryListTab } from '../../data-model/types/crash-report-summary-list-tab.type';

const feature = '[Crash Report]';

/**
 * crash report summary
 */
export const loadInProgressCrashReportSummaries = createAction(`${feature} Load In Progress Crash Report Summaries`);
export const inProgressCrashReportSummariesLoaded = createAction(
  `${feature} In Progress Crash Report Summaries Loaded`,
  props<{ inProgressCrashReportSummaries: CrashReportSummary[] }>()
);
export const crashReportSummarySelected = createAction(`${feature} Crash Report Summary Selected`, props<{ crashReportSummary: CrashReportSummary }>());
export const loadSubmittedCrashReportSummaries = createAction(`${feature} Load Submitted Crash Report Summaries`, props<{ limit: number; offset: number }>());
export const submittedCrashReportSummariesLoaded = createAction(
  `${feature} Submitted Crash Report Summaries Loaded`,
  props<{ submittedCrashReportSummaries: CrashReportSummary[] }>()
);
export const setNumberOfSubmittedCrashReportSummaries = createAction(
  `${feature} Set Number Of Submitted Crash Report Summaries`,
  props<{ numberOfSubmittedCrashReportSummaries: number }>()
);

/**
 * crash report detail
 */
export const loadCrashReportDetail = createAction(`${feature} Load Crash Report Detail`);
export const crashReportDetailLoaded = createAction(`${feature} Crash Report Detail Loaded`, props<{ crashReportDetail: CrashReportDetail }>());
export const updateCrashReportDetail = createAction(
  `${feature} Update Crash Report Detail`,
  // a form item can be updated by the user or by the system
  // system updates include default values specified in the rule set, downstream value changes, and updates that are made in the value update service (for example, setting lat and lng on report creation)
  // user updates include the user changing the value of a form item
  props<{ metaData: FormItemMetaData; value: unknown; isValid: boolean; isSetInBackground: boolean }>()
);
export const crashReportDetailUpdated = createAction(`${feature} Crash Report Detail Updated`);
export const addCrashReportDetail = createAction(`${feature} Add Crash Report Detail`, props<{ newCrashReportFormValue: NewCrashReportFormValue }>());
export const crashReportDetailAdded = createAction(`${feature} Crash Report Detail Added`);
export const setNewCrashReportFormValue = createAction(
  `${feature} Set New Crash Report Form Value`,
  props<{ newCrashReportFormValue: NewCrashReportFormValue }>()
);
export const setIsNewCrashReport = createAction(`${feature} Set Is New Crash Report`, props<{ isNewCrashReport: boolean }>());

/**
 * current page and offset
 */
export const setCurrentPage = createAction(`${feature} Set Current Page`, props<{ currentPage: CrashReportSummaryListTab }>());
export const setCurrentOffset = createAction(`${feature} Set Current Offset`, props<{ currentOffset: number }>());

/**
 * needs review crash report summaries
 */
export const loadNeedsReviewCrashReportSummaries = createAction(`${feature} Load Needs Review Crash Report Summaries`);
export const needsReviewCrashReportSummariesLoaded = createAction(
  `${feature} Needs Review Crash Report Summaries Loaded`,
  props<{ needsReviewCrashReportSummaries: SupervisorCrashReportSummary[] }>()
);
export const needsReviewCrashReportSummarySelected = createAction(
  `${feature} Needs Review Crash Report Summary Selected`,
  props<{ crashReportSummary: SupervisorCrashReportSummary }>()
);

/**
 * my approved crash report summaries
 */
export const loadMyApprovedCrashReportSummaries = createAction(`${feature} Load My Approved Crash Report Summaries`);
export const myApprovedCrashReportSummariesLoaded = createAction(
  `${feature} My Approved Crash Report Summaries Loaded`,
  props<{ myApprovedCrashReportSummaries: CrashReportSummary[] }>()
);

/**
 * my rejected crash report summaries
 */
export const loadMyRejectedCrashReportSummaries = createAction(`${feature} Load My Rejected Crash Report Summaries`);
export const myRejectedCrashReportSummariesLoaded = createAction(
  `${feature} My Rejected Crash Report Summaries Loaded`,
  props<{ myRejectedCrashReportSummaries: CrashReportSummary[] }>()
);

/**
 * review view -> non-review view -> review view
 */
export const navigateToCrashReportDetail = createAction(`${feature} Navigate To Crash Report Detail`, props<{ crashReportNumber: string }>());
export const navigateToNeedsReviewCrashReportDetail = createAction(
  `${feature} Navigate To Needs Review Crash Report Detail`,
  props<{ crashReportNumber: string }>()
);
export const setIsRedirectFromReview = createAction(`${feature} Set Is Redirect From Review`, props<{ isRedirectFromReview: boolean }>());
export const setIsRedirectFromNonReview = createAction(`${feature} Set Is Redirect From Non Review`, props<{ isRedirectFromNonReview: boolean }>());

/**
 * activity list -> non-review-form -> activity list
 */
export const setIsRedirectFromActvity = createAction(`${feature} Set Is Redirect From Activity`, props<{ isRedirectFromActivity: boolean }>());
export const setIsRedirectFromNonActivity = createAction(`${feature} Set Is Redirect From Non Non Activity`, props<{ isRedirectFromNonActivity: boolean }>());

/**
 * approved crash report summaries
 */
export const loadApprovedCrashReportSummaries = createAction(`${feature} Load Approved Crash Report Summaries`);
export const approvedCrashReportSummariesLoaded = createAction(
  `${feature} Approved Crash Report Summaries Loaded`,
  props<{ approvedCrashReportSummaries: SupervisorCrashReportSummary[] }>()
);

/**
 * rejected crash report summaries
 */
export const loadRejectedCrashReportSummaries = createAction(`${feature} Load Rejected Crash Report Summaries`);
export const rejectedCrashReportSummariesLoaded = createAction(
  `${feature} Rejected Crash Report Summaries Loaded`,
  props<{ rejectedCrashReportSummaries: SupervisorCrashReportSummary[] }>()
);

/**
 * update item number
 */
export const updateItemNumber = createAction(`${feature} Update Item Number`, props<{ value: string; metaData: FormItemMetaData }>());
export const itemNumberUpdated = createAction(`${feature} Item Number Updated`, props<{ updateWasSuccessful: boolean }>());
