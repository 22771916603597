import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TechnicalException } from '../data-model/models/technical-exception.model';
import { CommonUtils } from '../utils/common-utils';
import { DateUtils } from '../utils/date-utils';
import { StringUtils } from '../utils/string-utils';

export class NumberValidator {
  // compare source form control to target form control
  static greaterThanTarget(target: FormControl): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const value = parseFloat(formControl.value);
      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      const targetValue = parseFloat(target.value);
      if (isNaN(targetValue)) {
        return { invalidNumber: target.value };
      }

      if (value > targetValue) {
        return null;
      }

      return { greaterThan: { value: target.value, actual: formControl.value } };
    };
  }

  static greaterThanOrEqualToTarget(target: FormControl): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const value = parseFloat(formControl.value);
      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      const targetValue = parseFloat(target.value);
      if (isNaN(targetValue)) {
        return { invalidNumber: target.value };
      }

      if (value >= targetValue) {
        return null;
      }

      return { greaterThanOrEqualTo: { value: target.value, actual: formControl.value } };
    };
  }

  static lessThanTarget(target: FormControl): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const value = parseFloat(formControl.value);
      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      const targetValue = parseFloat(target.value);
      if (isNaN(targetValue)) {
        return { invalidNumber: target.value };
      }

      if (value < targetValue) {
        return null;
      }

      return { lessThan: { value: target.value, actual: formControl.value } };
    };
  }

  static lessThanOrEqualToTarget(target: FormControl): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const value = parseFloat(formControl.value);
      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      const targetValue = parseFloat(target.value);
      if (isNaN(targetValue)) {
        return { invalidNumber: target.value };
      }

      if (value <= targetValue) {
        return null;
      }

      return { lessThanOrEqualTo: { value: target.value, actual: formControl.value } };
    };
  }

  // compare source form control to a value
  static greaterThanValue(value: number): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const formControlValue = parseFloat(formControl.value);
      if (isNaN(formControlValue)) {
        return { invalidNumber: formControlValue };
      }

      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      if (formControlValue > value) {
        return null;
      }

      return { greaterThan: { value, actual: formControl.value } };
    };
  }

  static greaterThanOrEqualToValue(value: number): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const formControlValue = parseFloat(formControl.value);
      if (isNaN(formControlValue)) {
        return { invalidNumber: formControlValue };
      }

      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      if (formControlValue >= value) {
        return null;
      }

      return { greaterThanOrEqualTo: { value, actual: formControl.value } };
    };
  }

  static lessThanValue(value: number): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const formControlValue = parseFloat(formControl.value);
      if (isNaN(formControlValue)) {
        return { invalidNumber: formControlValue };
      }

      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      if (formControlValue < value) {
        return null;
      }

      return { lessThan: { value, actual: formControl.value } };
    };
  }

  static lessThanOrEqualToValue(value: number): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(formControl.value) || formControl.value === StringUtils.EMPTY) {
        return null;
      }

      const formControlValue = parseFloat(formControl.value);
      if (isNaN(formControlValue)) {
        return { invalidNumber: formControlValue };
      }

      if (isNaN(value)) {
        return { invalidNumber: value };
      }

      if (formControlValue <= value) {
        return null;
      }

      return { lessThanOrEqualTo: { value, actual: formControl.value } };
    };
  }

  // compare source form group to expression
  static greaterThanExpression(expressionKey: string): ValidatorFn {
    return NumberValidator.getExpressionValidatorFnOnExpressionKeyAndComparison(expressionKey, 'gt');
  }

  static greaterThanOrEqualToExpression(expressionKey: string): ValidatorFn {
    return NumberValidator.getExpressionValidatorFnOnExpressionKeyAndComparison(expressionKey, 'gte');
  }

  static lessThanExpression(expressionKey: string): ValidatorFn {
    return NumberValidator.getExpressionValidatorFnOnExpressionKeyAndComparison(expressionKey, 'lt');
  }

  static lessThanOrEqualToExpression(expressionKey: string): ValidatorFn {
    return NumberValidator.getExpressionValidatorFnOnExpressionKeyAndComparison(expressionKey, 'lte');
  }

  static validatorNameValidatorFunctionMap: Map<string, (...args: unknown[]) => ValidatorFn> = new Map();

  static {
    this.populateValidatorNameValidatorFunctionMap();
  }

  private static populateValidatorNameValidatorFunctionMap(): void {
    // target form control comparison
    this.validatorNameValidatorFunctionMap.set('greaterThanTarget', this.greaterThanTarget);
    this.validatorNameValidatorFunctionMap.set('greaterThanOrEqualToTarget', this.greaterThanOrEqualToTarget);
    this.validatorNameValidatorFunctionMap.set('lessThanTarget', this.lessThanTarget);
    this.validatorNameValidatorFunctionMap.set('lessThanOrEqualToTarget', this.lessThanOrEqualToTarget);

    // value comparison
    this.validatorNameValidatorFunctionMap.set('greaterThanValue', this.greaterThanValue);
    this.validatorNameValidatorFunctionMap.set('greaterThanOrEqualToValue', this.greaterThanOrEqualToValue);
    this.validatorNameValidatorFunctionMap.set('lessThanValue', this.lessThanValue);
    this.validatorNameValidatorFunctionMap.set('lessThanOrEqualToValue', this.lessThanOrEqualToValue);

    // expression comparison
    this.validatorNameValidatorFunctionMap.set('greaterThanExpression', this.greaterThanExpression);
    this.validatorNameValidatorFunctionMap.set('greaterThanOrEqualToExpression', this.greaterThanOrEqualToExpression);
    this.validatorNameValidatorFunctionMap.set('lessThanExpression', this.lessThanExpression);
    this.validatorNameValidatorFunctionMap.set('lessThanOrEqualToExpression', this.lessThanOrEqualToExpression);
  }

  private static getExpressionValidatorFnOnExpressionKeyAndComparison(expressionKey: string, comparison: 'gt' | 'gte' | 'lt' | 'lte'): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefinedOrEmptyString(expressionKey)) {
        throw new TechnicalException('expressionKey must be a non-empty string');
      }

      if (CommonUtils.isNullOrUndefinedOrEmptyString(formControl.value)) {
        return null;
      }

      let value = null;
      switch (expressionKey) {
        case 'nextYear': {
          value = parseFloat(formControl.value);
          break;
        }

        case 'currentDate': {
          value = DateUtils.isMmSlashDdSlashYyString(formControl.value) ? DateUtils.convertMmSlashDdSlashYyStringToDateObject(formControl.value) : null;
          break;
        }

        case 'currentTime': {
          value = DateUtils.isHhMmString(formControl.value) ? DateUtils.convertHhMmStringToCurrentDateObjectAtHhMm(formControl.value) : null;
          break;
        }

        default: {
          throw new TechnicalException(`expressionKey ${expressionKey} is not supported`);
        }
      }

      if (expressionKey === 'nextYear' && isNaN(value)) {
        return { invalidNumber: formControl.value };
      }
      if (expressionKey === 'currentDate' && !(value instanceof Date)) {
        return { invalidDate: { format: 'MM/DD/YY' } };
      }
      if (expressionKey === 'currentTime' && !(value instanceof Date)) {
        return { invalidTime: { format: 'HHMM' } };
      }

      let comparisonValue;
      switch (expressionKey) {
        case 'nextYear':
          comparisonValue = new Date().getFullYear() + 1;
          break;
        case 'currentDate':
          comparisonValue = new Date();
          comparisonValue.setHours(0, 0, 0, 0);
          break;
        case 'currentTime':
          comparisonValue = new Date();
          break;
        default:
          throw new TechnicalException(`expressionKey ${expressionKey} is not supported`);
      }

      if (expressionKey === 'nextYear' && isNaN(comparisonValue)) {
        throw new TechnicalException('The comparison value is not a number');
      }
      if (expressionKey === 'currentDate' && !(comparisonValue instanceof Date)) {
        throw new TechnicalException('The comparison value is not a date');
      }
      if (expressionKey === 'currentTime' && !(comparisonValue instanceof Date)) {
        throw new TechnicalException('The comparison value is not a date');
      }

      switch (expressionKey) {
        case 'nextYear': {
          if (comparison === 'gt') {
            if (value > comparisonValue) {
              return null;
            }
            return { greaterThan: { value: comparisonValue, actual: formControl.value } };
          } else if (comparison === 'gte') {
            if (value >= comparisonValue) {
              return null;
            }
            return { greaterThanOrEqualTo: { value: comparisonValue, actual: formControl.value } };
          } else if (comparison === 'lt') {
            if (value < comparisonValue) {
              return null;
            }
            return { lessThan: { value: comparisonValue, actual: formControl.value } };
          } else if (comparison === 'lte') {
            if (value <= comparisonValue) {
              return null;
            }
            return { lessThanOrEqualTo: { value: comparisonValue, actual: formControl.value } };
          } else {
            throw new TechnicalException(`comparison ${comparison} is not supported`);
          }
        }

        case 'currentDate': {
          const comparisonValueAsMMDDYYYY = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(comparisonValue);
          const actualValueAsMMDDYYYY = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(value);

          if (comparison === 'gt') {
            if (value > comparisonValue) {
              return null;
            }
            return { greaterThan: { value: comparisonValueAsMMDDYYYY, actual: actualValueAsMMDDYYYY } };
          } else if (comparison === 'gte') {
            if (value >= comparisonValue) {
              return null;
            }
            return { greaterThanOrEqualTo: { value: comparisonValueAsMMDDYYYY, actual: actualValueAsMMDDYYYY } };
          } else if (comparison === 'lt') {
            if (value < comparisonValue) {
              return null;
            }
            return { lessThan: { value: comparisonValueAsMMDDYYYY, actual: actualValueAsMMDDYYYY } };
          } else if (comparison === 'lte') {
            if (value <= comparisonValue) {
              return null;
            }
            return { lessThanOrEqualTo: { value: comparisonValueAsMMDDYYYY, actual: actualValueAsMMDDYYYY } };
          } else {
            throw new TechnicalException(`comparison ${comparison} is not supported`);
          }
        }

        case 'currentTime': {
          const comparisonValueAsHHMM = DateUtils.convertDateObjectToHhMmOrNull(comparisonValue);
          const actualValueAsHHMM = DateUtils.convertDateObjectToHhMmOrNull(value);

          if (comparison === 'gt') {
            if (value > comparisonValue) {
              return null;
            }
            return { greaterThan: { value: comparisonValueAsHHMM, actual: actualValueAsHHMM } };
          } else if (comparison === 'gte') {
            if (value >= comparisonValue) {
              return null;
            }
            return { greaterThanOrEqualTo: { value: comparisonValueAsHHMM, actual: actualValueAsHHMM } };
          } else if (comparison === 'lt') {
            if (value < comparisonValue) {
              return null;
            }
            return { lessThan: { value: comparisonValueAsHHMM, actual: actualValueAsHHMM } };
          } else if (comparison === 'lte') {
            if (value <= comparisonValue) {
              return null;
            }
            return { lessThanOrEqualTo: { value: comparisonValueAsHHMM, actual: actualValueAsHHMM } };
          } else {
            throw new TechnicalException(`comparison ${comparison} is not supported`);
          }
        }

        default:
          throw new TechnicalException(`expressionKey ${expressionKey} is not supported`);
      }
    };
  }
}
