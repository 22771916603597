export enum TemplateType {
  ADDRESS = 'ADDRESS',
  CRASH_MANNER = 'CRASH_MANNER',
  DIAGRAM = 'DIAGRAM',
  INFORMATION = 'INFORMATION',
  INSURANCE = 'INSURANCE',
  LICENSE_PLATE = 'LICENSE_PLATE',
  LICENSE_PLATE_TYPE = 'LICENSE_PLATE_TYPE',
  MAP = 'MAP',
  VEHICLE_DAMAGE = 'VEHICLE_DAMAGE',
}
