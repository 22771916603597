import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, noop, Observable } from 'rxjs';
import { AppState } from '../../../../../store/state/app.state';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { Timer } from '../../../../shared/decorators/timer/timer.decorator';
import { CollectionUtils } from '../../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { DateUtils } from '../../../../shared/utils/date-utils';
import { MapUtils } from '../../../../shared/utils/map-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { DateValidator } from '../../../../shared/validators/date-validator';
import { ListValidator } from '../../../../shared/validators/list-validator';
import { StringValidator } from '../../../../shared/validators/string-validator';
import { TimeValidator } from '../../../../shared/validators/time-validator';
import { FormConstants } from '../../../constants/form.constants';
import { FormItemType } from '../../../data-model/enums/form-item-type.enum';
import { TemplateSubType } from '../../../data-model/enums/template-sub-type.enum';
import { TemplateType } from '../../../data-model/enums/template.enum';
import { CrashReportDetail } from '../../../data-model/models/crash-report-detail.model';
import { FormItemMetaData } from '../../../data-model/models/form-item-meta-data.model';
import { FormItem } from '../../../data-model/models/form-item.model';
import { NewCrashReportFormValue } from '../../../data-model/models/new-crash-report-form-value.model';
import { Page } from '../../../data-model/models/page.model';
import { Rule } from '../../../data-model/models/rule.model';
import { SchemaGroupMetaData } from '../../../data-model/models/schema-group-meta-data.model';
import { SchemaGroup } from '../../../data-model/models/schema-group.model';
import { Section } from '../../../data-model/models/section.model';
import { SubGroupMetaData } from '../../../data-model/models/sub-group-meta-data.model';
import { SubGroup } from '../../../data-model/models/sub-group.model';
import { SupervisorCrashReportSummary } from '../../../data-model/models/supevisor-crash-report-summary.model';
import { ReviewCommentsTemplateData } from '../../../data-model/types/review-comments-template-data.type';
import { RuleSet } from '../../../data-model/types/rule-set.type';
import { Schema } from '../../../data-model/types/schema.type';
import { CrashReportActions } from '../../../store/actions';
import { CrashReportDetailFormKeyService } from '../crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailFormValidationService } from '../crash-report-detail-form-validation/crash-report-detail-form-validation.service';
import { CrashReportDetailFormValueChangesService } from '../crash-report-detail-form-value-changes/crash-report-detail-form-value-changes.service';
import { CrashReportDetailFormValueService } from '../crash-report-detail-form-value/crash-report-detail-form-value.service';
import { CrashReportDetailMapService } from '../crash-report-detail-map.service';
import { CrashReportDetailMetaDataServiceService } from '../crash-report-detail-meta-data-service/crash-report-detail-meta-data-service.service';
import { CrashReportDetailValueUpdateService } from '../crash-report-detail-value-update/crash-report-detail-value-update.service';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailFormService {
  form: FormGroup;
  formStructureUpdated$: Observable<number>;

  private formStructureUpdatedSubject = new BehaviorSubject<number>(0);
  private serviceLocationIdReviewDataMap: Map<number, { schema: Schema; commentsPath: [string, string, string, string] }>;

  private readonly NEW_ORLEANS_SERVICE_LOCATION_ID = 1;
  private readonly PLACEHOLDER_ID = -1;

  constructor(
    private readonly fb: FormBuilder,
    private readonly valueChangesService: CrashReportDetailFormValueChangesService,
    private readonly validationService: CrashReportDetailFormValidationService,
    private readonly valueService: CrashReportDetailFormValueService,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly metaDataService: CrashReportDetailMetaDataServiceService,
    private readonly valueUpdateService: CrashReportDetailValueUpdateService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly store: Store<AppState>
  ) {
    this.formStructureUpdated$ = this.formStructureUpdatedSubject.asObservable();
    this.initializeServiceLocationIdReviewDataMap();
  }

  /**
   * public api
   */
  @Timer()
  buildFullForm(
    crashReportDetail: CrashReportDetail,
    schema: Schema,
    ruleSet: RuleSet,
    newCrashReportFormValue: NewCrashReportFormValue,
    isNewCrashReport: boolean
  ): void {
    // reset the form, clear all maps in the maps service, and re-build the partial composite key rule map
    this.resetForm();
    this.mapService.initializeMaps();
    this.setPartialCompositeKeyRuleMap(ruleSet);

    // populate the form, update is actionable and should hide properties, and add validation
    this.populateSchemaGroups(
      schema,
      crashReportDetail,
      this.mapService.partialCompositeKeyRuleMap,
      this.buildSchemaGroupDomIdSchemaGroupIndexMap(schema),
      isNewCrashReport
    );
    this.updateIsActionableAndShouldHideProperties();
    this.validationService.addValidationOnFormCreation(ruleSet);

    // subscribe to value changes
    // pass a callback to subscribe to value changes so the rest of the app can be informed when a schema group or sub group is added or removed
    this.valueChangesService.subscribeToValueChangesWithRules(ruleSet, () => this.informAppThatFormStructureHasBeenUpdated(), this.form);

    // update the form with on arrival workflow values
    if (CommonUtils.isDefined(newCrashReportFormValue)) {
      this.valueUpdateService.updateFormOnArrival(newCrashReportFormValue);
      this.store.dispatch(CrashReportActions.setNewCrashReportFormValue({ newCrashReportFormValue: null }));
    }
  }

  @Timer()
  buildReviewForm(
    crashReportDetail: CrashReportDetail,
    ruleSet: RuleSet,
    serviceLocationId: number,
    supervisorCrashReportSummary: SupervisorCrashReportSummary
  ): void {
    const { META_DATA, DOM_ID, INDEX } = FormConstants;

    // get the review schema and comments path for the service location id out of the service location id review schema map
    // and throw an exception if the schema is not found (to help implement a new service location)
    const { schema, commentsPath } = OssOptional.ofNullable(this.serviceLocationIdReviewDataMap.get(serviceLocationId)).orElseThrow(
      () => new TechnicalException('Schema not found for service location id: ' + serviceLocationId)
    );
    const [schemaGroupDomId, subGroupDomId, sectionDomId, pageDomId] = commentsPath;

    // reset the form, clear all maps in the maps service, and re-build the partial composite key rule map
    // do not update is actionable and should hide properties
    // do not update the form with on arrival workflow values
    this.resetForm();
    this.mapService.initializeMaps();
    this.setPartialCompositeKeyRuleMap(ruleSet);

    // populate the form and add validation
    this.populateSchemaGroups(
      schema,
      crashReportDetail,
      this.mapService.partialCompositeKeyRuleMap,
      this.buildSchemaGroupDomIdSchemaGroupIndexMap(schema),
      false
    );
    this.validationService.addValidationOnFormCreation(ruleSet);

    // subscribe to value changes
    // do not pass a callback to subscribe to value changes cause a supervisor cannot add or remove schema groups or sub groups
    this.valueChangesService.subscribeToValueChangesWithRules(ruleSet, noop, this.form);

    // get schema group form group at schema group dom id and throw an exception one and only one is not found
    const schemaGroupFormArray = this.form.controls[schemaGroupDomId] as FormArray;
    const schemaGroupFormGroup = CollectionUtils.getOnlyElement(
      schemaGroupFormArray.controls,
      'there should only be one schema group form group in the schema group form array'
    ) as FormGroup;

    // get sub group form group at sub group dom id and throw an exception one and only one is not found
    // validate that the dom id equals the sub group dom id
    const subGroupFormArray = schemaGroupFormGroup.controls[FormConstants.SUB_GROUPS] as FormArray;
    const subGroupFormGroup = CollectionUtils.getOnlyElement(
      subGroupFormArray.controls,
      'there should only be one sub group form group in the sub group form array'
    ) as FormGroup;
    if (subGroupFormGroup.value[FormConstants.META_DATA][FormConstants.DOM_ID] !== subGroupDomId) {
      throw new TechnicalException('dom id of sub group form group should equal sub group dom id');
    }

    // get section form group at section dom id and throw an exception one and only one is not found
    const sectionFormArray = subGroupFormGroup.controls[FormConstants.SECTIONS] as FormArray;
    const sectionFormGroup = sectionFormArray.controls.find(
      (formGroup: FormGroup) => formGroup.value[FormConstants.META_DATA][FormConstants.DOM_ID] === sectionDomId
    ) as FormGroup;
    if (CommonUtils.isNullOrUndefined(sectionFormGroup)) {
      throw new TechnicalException('section form group should be found in section form array');
    }

    // get page form group at page dom id and throw an exception one and only one is not found
    const pageFormArray = sectionFormGroup.controls[FormConstants.PAGES] as FormArray;
    const pageFormGroup = CollectionUtils.getOnlyElement(
      pageFormArray.controls,
      'there should only be one page form group in the page form array'
    ) as FormGroup;
    if (pageFormGroup.value[META_DATA][DOM_ID] !== pageDomId) {
      throw new TechnicalException('dom id of page form group should equal page dom id');
    }

    const comments: ReviewCommentsTemplateData = OssOptional.ofNullable(supervisorCrashReportSummary.reviews)
      .orElse([])
      .filter(review => CommonUtils.isDefinedAndNonEmptyString(review.notes))
      .map(review => ({ date: review.created_at, comment: review.notes }));

    // if there are comments, update page -> meta data -> template data value
    if (CommonUtils.hasContent(comments)) {
      pageFormGroup.controls[META_DATA].setValue({ ...pageFormGroup.value[META_DATA], templateData: comments }, { emitEvent: false });
    }

    // if there are no comments, remove the comments section from the sections form array and re-index the sections
    if (CommonUtils.isEmpty(comments)) {
      const commentSectionIndex = sectionFormArray.controls.findIndex((section: FormGroup) => section.value[META_DATA][DOM_ID] === sectionDomId);
      if (commentSectionIndex === -1) {
        throw new TechnicalException('section form group should be found in section form array');
      }
      sectionFormArray.removeAt(commentSectionIndex);
      sectionFormArray.controls.forEach((section: FormGroup) => {
        const currentSectionIndex = section.value[META_DATA][INDEX];
        if (currentSectionIndex > commentSectionIndex) {
          section.controls[META_DATA].setValue({ ...section.value[META_DATA], [INDEX]: currentSectionIndex - 1 });
        }
      });
    }
  }

  updateIsDefaultValueOnFormItemMetaData(metaData: FormItemMetaData): void {
    const fullCompositeKey = this.keyService.getFullCompositeKey(metaData.table, metaData.fieldName, metaData.schemaGroupIndex, metaData.subGroupIndex);
    OssOptional.ofNullable(this.mapService.fullCompositeKeyFormGroupMap.get(fullCompositeKey))
      .ifPresent(formGroup =>
        formGroup.controls[FormConstants.META_DATA].setValue({ ...formGroup.value[FormConstants.META_DATA], isDefaultValue: false }, { emitEvent: false })
      )
      .orElseThrow(() => new TechnicalException(`unable to find form item with full composite key ${fullCompositeKey}`));
  }

  updateFormItemOnFormItemMetaDataAndValue(metaData: FormItemMetaData, value: unknown, isSetInBackground: boolean): void {
    const fullCompositeKey = this.keyService.getFullCompositeKey(metaData.table, metaData.fieldName, metaData.schemaGroupIndex, metaData.subGroupIndex);
    OssOptional.ofNullable(this.mapService.fullCompositeKeyFormGroupMap.get(fullCompositeKey))
      .ifPresent(formGroup => {
        const { DATE, TIME, BUTTON_GROUP, BUTTON_GROUP_MULTI, ECRASH_REF, ECRASH_REF_MULTI } = FormItemType;

        const formItemType = formGroup.value[FormConstants.META_DATA].type;

        // the blur event contains the value of the form item -- map the value to the format required by the oss input
        // if the form item is of type date, the value will be null, '', or an iso8601 date string
        if (
          formItemType === DATE &&
          CommonUtils.isDefined(value) &&
          StringUtils.isString(value) &&
          (value as string) !== StringUtils.EMPTY &&
          DateUtils.isIso8601DateString(value as string)
        ) {
          value = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(value as string);
        }

        // if the form item is of type time, the value will be null, '', or an hh:mm string
        if (
          formItemType === TIME &&
          CommonUtils.isDefined(value) &&
          StringUtils.isString(value) &&
          (value as string) !== StringUtils.EMPTY &&
          DateUtils.isHhColonMmString(value as string)
        ) {
          value = DateUtils.convertHhColonMmToHhMmOrNull(value as string);
        }

        // if the form item is of type button group or ecrash ref, the form control is expecting a string, an empty string, or null
        if ([BUTTON_GROUP, ECRASH_REF].includes(formItemType)) {
          switch (true) {
            // if the value is a string or null, set the value of the form control to the value
            case StringUtils.isString(value):
            case CommonUtils.isNull(value): {
              break;
            }
            // if the value is an array of strings, set the value of the form control to the only element in the array
            case CommonUtils.isArray(value): {
              CommonUtils.assert(
                (value as unknown[]).every((item: unknown) => StringUtils.isString(item)),
                'value should be an array of strings'
              );
              value = CollectionUtils.getOnlyElement(value as string[], 'there should only be one element in the string array');
              break;
            }
            default:
              throw new TechnicalException('value should be null, a string, or an array of strings with one element');
          }
        }

        // if the form item is of type button group multi or ecrash ref multi,
        // the form control is expecting a string with comma separated values, an empty string, or null
        if ([BUTTON_GROUP_MULTI, ECRASH_REF_MULTI].includes(formItemType)) {
          switch (true) {
            // if the value is null or an empty string, set the value of the form control to null
            // it could probably be set to an empty string, as well
            // if this is the case, regression test the pdf that represents the crash report
            case CommonUtils.isNull(value) || StringUtils.EMPTY === value: {
              value = null;
              break;
            }
            // if the value is a string, set the value of the form control to the value
            case StringUtils.isString(value): {
              break;
            }
            // if the value is an array of strings, set the value of the form to the elements of the array joined by commas
            case CommonUtils.isArray(value): {
              CommonUtils.assert(
                (value as unknown[]).every((item: unknown) => StringUtils.isString(item)),
                'value should be an array of strings'
              );
              value = (value as string[]).join(',');
              break;
            }

            default:
              throw new TechnicalException('value should be null, an empty string, a string, or an array of strings');
          }
        }

        formGroup.controls[FormConstants.VALUE].setValue(value, { emitEvent: false });

        // do not mark the form group as touched if the form item is being set in the background
        if (!isSetInBackground) {
          formGroup.markAsTouched();
        }

        formGroup.markAsPristine();
        formGroup.updateValueAndValidity();
      })
      .orElseThrow(() => new TechnicalException(`unable to find form item with full composite key ${fullCompositeKey}`));
  }

  addSchemaGroup(schemaGroupMetaData: SchemaGroupMetaData, schema: Schema, crashReportDetail: CrashReportDetail, userActionRules: Rule[]): void {
    const { META_DATA, IS_PLACEHOLDER, IS_DELETED, API_INDEX } = FormConstants;

    // save a reference to the schema group form array
    const schemaGroupFormArray = this.form.controls[schemaGroupMetaData.domId] as FormArray;

    // build a list of non deleted schema groups
    // if there is only one schema group form group in the schema group form array and it is a placeholder, set is deleted to true
    // shouldn't have to re-index the schema group form groups because the placeholder will be the last one
    const nonDeletedSchemaGroups = schemaGroupFormArray.controls.filter((formGroup: FormGroup) => !formGroup.value[META_DATA][IS_DELETED]);
    if (nonDeletedSchemaGroups.length === 1 && CollectionUtils.getOnlyElement(nonDeletedSchemaGroups).value[META_DATA][IS_PLACEHOLDER]) {
      const onlyElement = CollectionUtils.getOnlyElement(nonDeletedSchemaGroups) as FormGroup;
      onlyElement.controls[META_DATA].setValue({ ...onlyElement.value[META_DATA], [IS_DELETED]: true, [API_INDEX]: this.PLACEHOLDER_ID });
    }

    // save a reference to the schema group (the blueprint, not the form group)
    const schemaGroup = schema.find(schemaGroup => schemaGroup.key === schemaGroupMetaData.domId);

    // new schema group index is the number of schema groups in the schema group form array
    const schemaGroupIndex = schemaGroupFormArray.controls.length;

    // api index is the number of schema groups in the schema group form array that are not deleted
    const apiIndex = CollectionUtils.count(schemaGroupFormArray.controls, (formGroup: FormGroup) => !formGroup.value[META_DATA][IS_DELETED]);

    // push new schema group form group to the schema group form array
    const isPlaceholder = false;
    const newSchemaGroupMetaData = this.metaDataService.buildSchemaGroupMetaData(
      schemaGroup,
      schemaGroupIndex,
      this.buildSchemaGroupDomIdSchemaGroupIndexMap(schema),
      isPlaceholder,
      apiIndex
    );
    schemaGroupFormArray.push(this.fb.group({ metaData: [newSchemaGroupMetaData], subGroups: this.fb.array([]) }));

    // save references to the new schema group form group and the sub group form array
    const schemaGroupFormGroup = CollectionUtils.getLastElement(schemaGroupFormArray.controls) as FormGroup;
    const subGroupFormArray = schemaGroupFormGroup.controls[FormConstants.SUB_GROUPS] as FormArray;

    // add the new schema group form group to the composite key map
    this.mapService.schemaGroupCompositeKeyFormGroupMap.set(
      this.keyService.getSchemaGroupCompositeKey(schemaGroupMetaData.domId, schemaGroupIndex),
      schemaGroupFormGroup
    );

    // add one sub group form group for each sub group in the schema group
    const subGroupIndex = 0;
    const subGroupApiIndex = 0;
    schemaGroup.subGroups.forEach(subGroup =>
      this.populateSubGroups(
        subGroupFormArray,
        crashReportDetail,
        schemaGroup,
        schemaGroupIndex,
        subGroup,
        subGroupIndex,
        this.mapService.partialCompositeKeyRuleMap,
        // the sub group is a placeholder if the sub group is repeatable
        subGroup.repeats,
        schemaGroupFormGroup,
        subGroupApiIndex,
        true
      )
    );

    // reapply user action rules
    // pass the schema group index to update is actionable and should hide properties so only the new schema group is updated
    this.updateIsActionableAndShouldHideProperties();
    this.validationService.addValidationOnFormCreation(userActionRules);
    this.valueChangesService.subscribeToValueChangesWithRules(userActionRules, () => this.informAppThatFormStructureHasBeenUpdated(), this.form);

    // inform the component that the form structure has been updated
    this.informAppThatFormStructureHasBeenUpdated();
  }

  addSubGroup(
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData,
    schema: Schema,
    crashReportDetail: CrashReportDetail,
    userActionRules: Rule[]
  ): void {
    const { META_DATA, DOM_ID, SUB_GROUPS, SECTIONS, IS_PLACEHOLDER, IS_DELETED, API_INDEX, IS_ACTIONABLE } = FormConstants;

    // save a reference to the sub group form array
    const schemaGroupFormArray = this.form.controls[schemaGroupMetaData.domId] as FormArray;
    const schemaGroupFormGroup = schemaGroupFormArray.controls.find(
      (formGroup: FormGroup) => formGroup.value[META_DATA][API_INDEX] === schemaGroupMetaData.apiIndex
    ) as FormGroup;
    const subGroupFormArray = schemaGroupFormGroup.controls[SUB_GROUPS] as FormArray;

    // build a list of non deleted sub groups scoped to the sub group dom id
    const nonDeletedSubGroupsScopedToSubGroupDomId = subGroupFormArray.controls.filter(
      (formGroup: FormGroup) => !formGroup.value[META_DATA][IS_DELETED] && formGroup.value[META_DATA][DOM_ID] === subGroupMetaData.domId
    );

    // if there is only one non deleted sub group scoped to the sub group dom id, if it is a placeholder, set is deleted to true\
    // shouldn't have to re-index the sub group form groups because the placeholder will be the last one
    if (nonDeletedSubGroupsScopedToSubGroupDomId.length === 1) {
      const onlyNonDeletedSubGroupScopedToSubGroupDomId = CollectionUtils.getOnlyElement(nonDeletedSubGroupsScopedToSubGroupDomId) as FormGroup;
      if (onlyNonDeletedSubGroupScopedToSubGroupDomId.value[META_DATA][IS_PLACEHOLDER]) {
        onlyNonDeletedSubGroupScopedToSubGroupDomId.controls[META_DATA].setValue({
          ...onlyNonDeletedSubGroupScopedToSubGroupDomId.value[META_DATA],
          [IS_DELETED]: true,
          [API_INDEX]: this.PLACEHOLDER_ID,
          [IS_ACTIONABLE]: false,
        });
      }
    }

    // save a refrence to the sub group (the blueprint, not the form group)
    const schemaGroup = schema.find(schemaGroup => schemaGroup.key === schemaGroupMetaData.domId);
    const subGroup = schemaGroup.subGroups.find(subGroup => subGroup.key === subGroupMetaData.domId);

    // insert index is the index of the last sub group form group scoped to the sub group meta data dom id plus one
    const { index: indexOfLastSubGroupFormGroupScopedToSubGroupDomId } = CollectionUtils.findLastWithIndex(
      subGroupFormArray.controls,
      (formGroup: FormGroup) => formGroup.value[META_DATA][DOM_ID] === subGroupMetaData.domId
    );
    if (CommonUtils.isNullOrUndefined(indexOfLastSubGroupFormGroupScopedToSubGroupDomId)) {
      throw new TechnicalException('should not be able to add a sub group when there are no sub groups');
    }
    const insertIndex = indexOfLastSubGroupFormGroupScopedToSubGroupDomId + 1;

    // api index is the number of sub groups in the sub group form array scoped to the sub group meta data dom id that are not deleted
    const apiIndex = CollectionUtils.count(
      subGroupFormArray.controls,
      (formGroup: FormGroup) => !formGroup.value[META_DATA][IS_DELETED] && formGroup.value[META_DATA][DOM_ID] === subGroupMetaData.domId
    );

    // sub group index is the number of sub groups in the sub group form array scoped to the sub group meta data dom id
    const subGroupIndex = CollectionUtils.count(
      subGroupFormArray.controls,
      (formGroup: FormGroup) => formGroup.value[META_DATA][DOM_ID] === subGroupMetaData.domId
    );

    // insert a new non placeholder sub group form group at the insert index
    const isPlaceholder = false;
    const newSubGroupMetaData = this.metaDataService.buildSubGroupMetaData(schemaGroup, subGroup, subGroupIndex, isPlaceholder, apiIndex);
    const newSubGroupFormGroup = this.fb.group({ metaData: [newSubGroupMetaData], sections: this.fb.array([]) });
    subGroupFormArray.insert(insertIndex, newSubGroupFormGroup);

    // save a reference to the new sub group form group and the sections form array
    const sectionsFormArray = newSubGroupFormGroup.controls[SECTIONS] as FormArray;

    // update the sub group composite key form group map
    this.mapService.subGroupCompositeKeyFormGroupMap.set(
      this.keyService.getSubGroupCompositeKey(schemaGroupMetaData.domId, schemaGroupMetaData.index, subGroupMetaData.domId, subGroupIndex),
      newSubGroupFormGroup
    );

    // update the schema group dom id index sub group dom id composite key sub groups map
    const compositeKey = this.keyService.getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(
      schemaGroupMetaData.domId,
      schemaGroupMetaData.index,
      subGroupMetaData.domId
    );
    MapUtils.putIfAbsent(this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap, compositeKey, []);
    this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap.get(compositeKey).push(newSubGroupFormGroup);

    // populate underlying form groups
    this.populateSections(
      crashReportDetail,
      schemaGroup,
      schemaGroupMetaData.index,
      subGroup,
      subGroupIndex,
      sectionsFormArray,
      this.mapService.partialCompositeKeyRuleMap,
      newSubGroupFormGroup,
      schemaGroupFormGroup,
      true,
      schemaGroupMetaData.apiIndex
    );

    // reapply user action rules
    // pass the sub group index to update is actionable and should hide properties so only the new sub group is updated
    this.updateIsActionableAndShouldHideProperties();
    this.validationService.addValidationOnFormCreation(userActionRules);
    this.valueChangesService.subscribeToValueChangesWithRules(userActionRules, () => this.informAppThatFormStructureHasBeenUpdated(), this.form);

    // inform the component that the form structure has been updated
    this.informAppThatFormStructureHasBeenUpdated();
  }

  removeSchemaGroup(schemaGroupMetaData: SchemaGroupMetaData, schema: Schema, crashReportDetail: CrashReportDetail, removeIndex: number): void {
    const { META_DATA, IS_DELETED, IS_PLACEHOLDER, API_INDEX, SUB_GROUPS } = FormConstants;

    // save a reference to the schema group form array
    const schemaGroupFormArray = this.form.controls[schemaGroupMetaData.domId] as FormArray;

    // count the number of visible schema groups in the schema group form array
    const numberOfNonDeletedSchemaGroups = CollectionUtils.count(
      schemaGroupFormArray.controls,
      (formGroup: FormGroup) => !formGroup.value[META_DATA][IS_DELETED]
    );

    if (numberOfNonDeletedSchemaGroups === 1) {
      // save a reference to the schema group form group at the remove index
      const schemaGroupFormGroupToRemove = schemaGroupFormArray.controls[removeIndex] as FormGroup;

      // if it is deleted, throw a technical exception
      if (schemaGroupFormGroupToRemove.value[META_DATA][IS_DELETED]) {
        throw new TechnicalException('user should not be able to delete a schema group when length is one and first element is deleted');
      }

      // if it is a placeholder, throw a technical exception
      if (schemaGroupFormGroupToRemove.value[META_DATA][IS_PLACEHOLDER]) {
        throw new TechnicalException('user should not be able to delete a schema group when length is one and first element is placeholder');
      }

      // set is deleted property of schema group form group to true
      schemaGroupFormGroupToRemove.controls[META_DATA].setValue({
        ...schemaGroupFormGroupToRemove.value[META_DATA],
        [IS_DELETED]: true,
        [API_INDEX]: this.PLACEHOLDER_ID,
      });

      // schema group index is the number of schema groups in the schema group form array
      const schemaGroupIndex = schemaGroupFormArray.length;

      // api index is the number of schema groups in the schema group form array that are not deleted
      // can probably just set this to 0 cause this is a placeholder
      const apiIndex = -1;

      // find schema group in schema (the blueprint, not the form group)
      const schemaGroup = schema.find(schemaGroup => schemaGroup.key === schemaGroupMetaData.domId);

      // push a new placeholder schema group form group on the schema group form array
      const isPlaceholder = true;
      const newSchemaGroupMetaData = this.buildSchemaGroupDomIdSchemaGroupIndexMap(schema);
      schemaGroupFormArray.push(
        this.fb.group({
          metaData: [this.metaDataService.buildSchemaGroupMetaData(schemaGroup, schemaGroupIndex, newSchemaGroupMetaData, isPlaceholder, apiIndex)],
          subGroups: this.fb.array([]),
        })
      );

      // save a reference to the new schema group form group and the sub group form array
      const schemaGroupFormGroup = CollectionUtils.getLastElement(schemaGroupFormArray.controls) as FormGroup;
      const subGroupFormArray = schemaGroupFormGroup.controls[SUB_GROUPS] as FormArray;

      // update the composite key map
      this.mapService.schemaGroupCompositeKeyFormGroupMap.set(
        this.keyService.getSchemaGroupCompositeKey(schemaGroup.key, schemaGroupIndex),
        schemaGroupFormGroup
      );

      // populate underlying form groups
      schemaGroup.subGroups.forEach(subGroup => {
        const actualSubGroupLength = this.getRepeatableGroupLength(schemaGroup, schemaGroupIndex, subGroup, crashReportDetail);
        const adjustedSubGroupLength = actualSubGroupLength > 0 ? actualSubGroupLength : 1;

        CollectionUtils.range(adjustedSubGroupLength).forEach(subGroupIndex =>
          this.populateSubGroups(
            subGroupFormArray,
            crashReportDetail,
            schemaGroup,
            schemaGroupIndex,
            subGroup,
            subGroupIndex,
            this.mapService.partialCompositeKeyRuleMap,
            true,
            schemaGroupFormGroup,
            0, // sub group api index is always 0 cause this is a placeholder
            true
          )
        );
      });

      // inform the component that the form structure has been updated
      this.informAppThatFormStructureHasBeenUpdated();

      return;
    }

    // set is deleted property of schema group form group at remove index to true
    const schemaGroupFormGroupToRemove = schemaGroupFormArray.controls[removeIndex] as FormGroup;

    // save a reference to the api index of the schema group form group to remove
    const apiIndexOfSchemaGroupFormGroupToRemove = schemaGroupFormGroupToRemove.value[META_DATA][API_INDEX];

    schemaGroupFormGroupToRemove.controls[META_DATA].setValue({
      ...schemaGroupFormGroupToRemove.value[META_DATA],
      [IS_DELETED]: true,
      [API_INDEX]: this.PLACEHOLDER_ID,
    });

    // adjust the api index for any schema groups that are not deleted and have an api index that is greater than the deleted schema group's api index
    schemaGroupFormArray.controls.forEach((formGroup: FormGroup) => {
      const metaData = formGroup.value[META_DATA];
      if (!metaData[IS_DELETED] && metaData[API_INDEX] > apiIndexOfSchemaGroupFormGroupToRemove) {
        formGroup.controls[META_DATA].setValue({ ...metaData, [API_INDEX]: metaData[API_INDEX] - 1 });

        // update the schema group api index value for all form items in the schema group
        this.updateSchemaGroupApiIndexes(formGroup, formGroup.value[META_DATA][API_INDEX]);
      }
    });

    // inform the component that the form structure has been updated
    this.informAppThatFormStructureHasBeenUpdated();
  }

  removeSubGroup(
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData,
    schema: Schema,
    crashReportDetail: CrashReportDetail,
    removeIndex: number,
    subGroupWasHiddenOnValueChange: boolean
  ): void {
    const { META_DATA, DOM_ID, SUB_GROUPS, SECTIONS, IS_PLACEHOLDER, IS_DELETED, API_INDEX, INDEX, IS_ACTIONABLE } = FormConstants;

    // save a reference to the sub group form array
    const schemaGroupFormArray = this.form.controls[schemaGroupMetaData.domId] as FormArray;
    const schemaGroupFormGroup = schemaGroupFormArray.controls.find(
      (formGroup: FormGroup) => formGroup.value[META_DATA][API_INDEX] === schemaGroupMetaData.apiIndex
    ) as FormGroup;
    const subGroupFormArray = schemaGroupFormGroup.controls[SUB_GROUPS] as FormArray;

    // build a list of non deleted sub groups scoped to the sub group dom id
    const subGroupsScopedToSubGroupDomId = subGroupFormArray.controls.filter(
      (formGroup: FormGroup) => formGroup.value[META_DATA][DOM_ID] === subGroupMetaData.domId
    );

    // build a list of non deleted sub groups scoped to the sub group dom id that are not the removed sub group
    const nonDeletedSubGroupsScopedToSubGroupDomId = subGroupsScopedToSubGroupDomId.filter((formGroup: FormGroup) => !formGroup.value[META_DATA][IS_DELETED]);

    if (nonDeletedSubGroupsScopedToSubGroupDomId.length === 1) {
      // save a reference to the only non deleted sub group form group scoped to the sub group dom id
      const onlyNonDeletedSubGroupScopedToSubGroupDomId = CollectionUtils.getOnlyElement(nonDeletedSubGroupsScopedToSubGroupDomId) as FormGroup;

      // if it is a placeholder, throw a technical exception
      if (onlyNonDeletedSubGroupScopedToSubGroupDomId.value[META_DATA][IS_PLACEHOLDER]) {
        throw new TechnicalException('user should not be able to delete a sub group when length is one and first element is placeholder');
      }

      // set is deleted property of the only non deleted sub group form group scoped to the sub group dom id to true
      onlyNonDeletedSubGroupScopedToSubGroupDomId.controls[META_DATA].setValue({
        ...onlyNonDeletedSubGroupScopedToSubGroupDomId.value[META_DATA],
        [IS_DELETED]: true,
        [API_INDEX]: this.PLACEHOLDER_ID,
        [IS_ACTIONABLE]: false,
      });

      // save a reference to the sub group blueprint
      const schemaGroup = schema.find(schemaGroup => schemaGroup.key === schemaGroupMetaData.domId);
      const subGroup = schemaGroup.subGroups.find(subGroup => subGroup.key === subGroupMetaData.domId);

      // insert index is the index of the last sub group form group scoped to the sub group meta data dom id plus one
      const { index: indexOfLastSubGroupFormGroupScopedToSubGroupDomId } = CollectionUtils.findLastWithIndex(
        subGroupFormArray.controls,
        (formGroup: FormGroup) => formGroup.value[META_DATA][DOM_ID] === subGroupMetaData.domId
      );
      const insertIndex = indexOfLastSubGroupFormGroupScopedToSubGroupDomId + 1;

      // sub group index is the number of sub groups in the sub group form array scoped to the sub group meta data dom id
      const subGroupIndex = subGroupsScopedToSubGroupDomId.length;

      // api index is -1 cause this is a placeholder
      const apiIndex = this.PLACEHOLDER_ID;

      // insert a placeholder form group at the insert index
      // pass the sub group index and api index to the meta data builder
      const isPlaceholder = true;
      const placeholderSubGroupFormGroup = this.fb.group({
        metaData: [this.metaDataService.buildSubGroupMetaData(schemaGroup, subGroup, subGroupIndex, isPlaceholder, apiIndex)],
        sections: this.fb.array([]),
      });
      placeholderSubGroupFormGroup.controls[META_DATA].setValue({
        ...placeholderSubGroupFormGroup.value[META_DATA],
        [IS_ACTIONABLE]: !subGroupWasHiddenOnValueChange,
      });
      subGroupFormArray.insert(insertIndex, placeholderSubGroupFormGroup);

      // save a reference to the new sub group form group and the sections form array
      const subGroupFormGroup = subGroupFormArray.controls[insertIndex] as FormGroup;
      const sectionsFormArray = subGroupFormGroup.controls[SECTIONS] as FormArray;

      // update the composite key map
      this.mapService.subGroupCompositeKeyFormGroupMap.set(
        this.keyService.getSubGroupCompositeKey(schemaGroupMetaData.domId, schemaGroupMetaData.index, subGroupMetaData.domId, subGroupIndex),
        subGroupFormGroup
      );

      // populate underlying form
      this.populateSections(
        crashReportDetail,
        schemaGroup,
        schemaGroupMetaData.index,
        subGroup,
        subGroupIndex,
        sectionsFormArray,
        this.mapService.partialCompositeKeyRuleMap,
        subGroupFormGroup,
        schemaGroupFormGroup,
        true,
        schemaGroupMetaData.apiIndex
      );

      // inform the component that the form structure has been updated
      this.informAppThatFormStructureHasBeenUpdated();

      return;
    }

    // removed sub group form group is the sub group form group scoped to the sub group meta data dom id at the remove index
    const removedSubGroupFormGroup = CollectionUtils.getOnlyElement(
      subGroupsScopedToSubGroupDomId.filter((formGroup: FormGroup) => formGroup.value[META_DATA][INDEX] === removeIndex),
      'when user tries to delete a sub group, there should be exactly one non deleted sub group scoped to the sub group meta data dom id'
    ) as FormGroup;

    // save the api index of the removed sub group form group
    const apiIndexOfRemovedSubGroupFormGroup = removedSubGroupFormGroup.value[META_DATA][API_INDEX];

    // update the is deleted property of the removed sub group form group
    removedSubGroupFormGroup.controls[META_DATA].setValue({
      ...removedSubGroupFormGroup.value[META_DATA],
      [IS_DELETED]: true,
      [API_INDEX]: this.PLACEHOLDER_ID,
      [IS_ACTIONABLE]: false,
    });

    // adjust the api index for any sub groups that are not deleted and have an api index that is greater than the deleted sub group's api index
    subGroupsScopedToSubGroupDomId.forEach((formGroup: FormGroup) => {
      const metaData = formGroup.value[META_DATA];
      if (!metaData[IS_DELETED] && metaData[API_INDEX] > apiIndexOfRemovedSubGroupFormGroup) {
        formGroup.controls[META_DATA].setValue({ ...metaData, [API_INDEX]: metaData[API_INDEX] - 1 });

        // update the sub group api index value for all form items in the sub group
        this.updateSubGroupApiIndexes(formGroup, formGroup.value[META_DATA][API_INDEX]);
      }
    });

    // inform the component that the form structure has been updated
    this.informAppThatFormStructureHasBeenUpdated();
  }

  swapVehicles(fromIndex: number, tooIndex: number, serviceLocationId: number): void {
    const { META_DATA, API_INDEX } = FormConstants;

    // build a map of service location id to vehicle schema group dom id
    const newOrleansServiceLocationId = 1;
    const newOrleansVehicleSchemaGroupDomId = 'vehic';
    const serviceLocationIdVehicleSchemaGroupDomIdMap = new Map<number, string>([[newOrleansServiceLocationId, newOrleansVehicleSchemaGroupDomId]]);

    // get the schema group dom id for the service location id and throw an exception if not exists
    const vehicleSchemaGroupDomId = OssOptional.ofNullable(serviceLocationIdVehicleSchemaGroupDomIdMap.get(serviceLocationId)).orElseThrow(
      () => new TechnicalException('service location id should be found in the service location id vehicle schema group dom id map')
    );

    // save a reference to the schema group form array and throw an exception if not exists
    const schemaGroupFormArray = OssOptional.ofNullable(this.form.controls[vehicleSchemaGroupDomId])
      .map(control => control as FormArray)
      .orElseThrow(() => new TechnicalException(`schema group with dom id ${vehicleSchemaGroupDomId} should not be null`));

    // save references to the from and too schema group form groups and throw an exception if not exists
    const fromSchemaGroupFormGroup = OssOptional.ofNullable(
      schemaGroupFormArray.controls.find((control: FormGroup) => control.value[META_DATA][API_INDEX] === fromIndex)
    )
      .map(control => control as FormGroup)
      .orElseThrow(() => new TechnicalException('from schema group form group should not be null'));
    const tooSchemaGroupFormGroup = OssOptional.ofNullable(
      schemaGroupFormArray.controls.find((control: FormGroup) => control.value[META_DATA][API_INDEX] === tooIndex)
    )
      .map(control => control as FormGroup)
      .orElseThrow(() => new TechnicalException('to schema group form group should not be null'));

    // swap the api index values of the from and to schema group form groups
    fromSchemaGroupFormGroup.controls[META_DATA].setValue({
      ...fromSchemaGroupFormGroup.value[META_DATA],
      [API_INDEX]: tooIndex,
    });
    tooSchemaGroupFormGroup.controls[META_DATA].setValue({
      ...tooSchemaGroupFormGroup.value[META_DATA],
      [API_INDEX]: fromIndex,
    });

    // update the schema group api indexes for each form item in the from and too schema group form groups
    this.updateSchemaGroupApiIndexes(fromSchemaGroupFormGroup, tooIndex);
    this.updateSchemaGroupApiIndexes(tooSchemaGroupFormGroup, fromIndex);

    // swap the form groups in the schema group form array
    schemaGroupFormArray.setControl(fromIndex, tooSchemaGroupFormGroup);
    schemaGroupFormArray.setControl(tooIndex, fromSchemaGroupFormGroup);

    // inform the component that the form structure has been updated
    this.informAppThatFormStructureHasBeenUpdated();
  }

  resetFormStructureUpdatedSubject(): void {
    this.formStructureUpdatedSubject.next(0);
  }

  informAppThatFormStructureHasBeenUpdated(): void {
    this.formStructureUpdatedSubject.next(this.formStructureUpdatedSubject.value + 1);
  }

  updateSchemaGroupValueAndValidityAndReturnValidityData(): {
    invalidFormItemCompositeKeys: string[];
    schemaGroupCompositeKeyIsTouchedMap: Map<string, boolean>;
    schemaGroupCompositeKeyIsValidMap: Map<string, boolean>;
  } {
    const { META_DATA, DOES_REPEAT, IS_PLACEHOLDER, SUB_GROUPS, SECTIONS, PAGES, FORM_ITEMS, VALUE, DOM_ID, INDEX, FIELD_NAME, IS_DELETED } = FormConstants;

    const validityData = {
      invalidFormItemCompositeKeys: [],
      schemaGroupCompositeKeyIsTouchedMap: new Map<string, boolean>(),
      schemaGroupCompositeKeyIsValidMap: new Map<string, boolean>(),
    };

    // form.controls is a map of schema group dom id to schema group form array
    Object.values(this.form.controls).forEach((schemaGroupFormArray: FormArray) => {
      schemaGroupFormArray.controls
        // for each non placeholder schema group
        .forEach((schemaGroupFormGroup: FormGroup) => {
          const subGroupsFormArray = schemaGroupFormGroup.controls[SUB_GROUPS] as FormArray;
          subGroupsFormArray.controls
            // for each non placeholder sub group
            .forEach((subGroupFormGroup: FormGroup) => {
              const sectionsFormArray = subGroupFormGroup.controls[SECTIONS] as FormArray;
              // for each section
              sectionsFormArray.controls.forEach((sectionFormGroup: FormGroup) => {
                const pagesFormArray = sectionFormGroup.controls[PAGES] as FormArray;
                // for each page
                pagesFormArray.controls.forEach((pageFormGroup: FormGroup) => {
                  const formItemsFormArray = pageFormGroup.controls[FORM_ITEMS] as FormArray;
                  // for each form item
                  formItemsFormArray.controls.forEach((formItemFormGroup: FormGroup) => {
                    // if the schema group or sub group has been soft deleted, remove the validators from the form item value control
                    // and update the value and validity of the form item value control
                    if (
                      schemaGroupFormGroup.value[META_DATA][IS_DELETED] ||
                      subGroupFormGroup.value[META_DATA][IS_DELETED] ||
                      (schemaGroupFormGroup.value[META_DATA][DOES_REPEAT] && schemaGroupFormGroup.value[META_DATA][IS_PLACEHOLDER]) ||
                      (subGroupFormGroup.value[META_DATA][DOES_REPEAT] && subGroupFormGroup.value[META_DATA][IS_PLACEHOLDER])
                    ) {
                      formItemFormGroup.controls[VALUE].clearValidators();
                      formItemFormGroup.controls[VALUE].updateValueAndValidity({ emitEvent: false });
                      return;
                    }

                    // else update the value and validity of the form item value control with the validators
                    // and add the form item composite key to the invalid form item composite keys list if the form item value control is invalid
                    formItemFormGroup.controls[VALUE].updateValueAndValidity({ emitEvent: false });
                    if (formItemFormGroup.controls[VALUE].invalid) {
                      const key = this.keyService.getFormItemCompositeKey(
                        schemaGroupFormGroup.value[META_DATA][DOM_ID],
                        schemaGroupFormGroup.value[META_DATA][INDEX],
                        subGroupFormGroup.value[META_DATA][DOM_ID],
                        subGroupFormGroup.value[META_DATA][INDEX],
                        formItemFormGroup.value[META_DATA][FIELD_NAME]
                      );
                      validityData.invalidFormItemCompositeKeys.push(key);
                    }
                  });
                });
              });
            });

          if (schemaGroupFormGroup.value[META_DATA][IS_DELETED]) {
            return;
          }

          // update the key is touched and key is valid maps if the schema group has not been soft deleted
          validityData.schemaGroupCompositeKeyIsTouchedMap.set(
            this.keyService.getSchemaGroupCompositeKey(schemaGroupFormGroup.value[META_DATA][DOM_ID], schemaGroupFormGroup.value[META_DATA][INDEX]),
            schemaGroupFormGroup.touched
          );

          validityData.schemaGroupCompositeKeyIsValidMap.set(
            this.keyService.getSchemaGroupCompositeKey(schemaGroupFormGroup.value[META_DATA][DOM_ID], schemaGroupFormGroup.value[META_DATA][INDEX]),
            schemaGroupFormGroup.valid
          );
        });
    });
    return validityData;
  }

  updateSubGroupValueAndValidityAndReturnValidityData(
    subGroupFormGroups: FormGroup[],
    schemaGroupDomId: string,
    schemaGroupIndex: number
  ): {
    invalidFormItemCompositeKeys: string[];
    subGroupCompositeKeyIsTouchedMap: Map<string, boolean>;
    subGroupCompositeKeyIsValidMap: Map<string, boolean>;
  } {
    const {
      META_DATA,
      DOES_REPEAT,
      IS_PLACEHOLDER,
      SECTIONS,
      PAGES,
      FORM_ITEMS,
      VALUE,
      DOM_ID,
      INDEX,
      FIELD_NAME,
      IS_DELETED,
      SCHEMA_GROUP_DOM_ID,
      SCHEMA_GROUP_INDEX,
    } = FormConstants;

    const validityData = {
      invalidFormItemCompositeKeys: [],
      subGroupCompositeKeyIsTouchedMap: new Map<string, boolean>(),
      subGroupCompositeKeyIsValidMap: new Map<string, boolean>(),
    };

    subGroupFormGroups
      // for each non placeholder sub group
      .forEach((subGroupFormGroup: FormGroup) => {
        const sectionsFormArray = subGroupFormGroup.controls[SECTIONS] as FormArray;
        // for each section
        sectionsFormArray.controls.forEach((sectionFormGroup: FormGroup) => {
          const pagesFormArray = sectionFormGroup.controls[PAGES] as FormArray;
          // for each page
          pagesFormArray.controls.forEach((pageFormGroup: FormGroup) => {
            const formItemsFormArray = pageFormGroup.controls[FORM_ITEMS] as FormArray;
            // for each form item
            formItemsFormArray.controls.forEach((formItemFormGroup: FormGroup) => {
              // if the sub group has been soft deleted, remove the validators from the form item value control
              // and update the value and validity of the form item value control
              if (
                subGroupFormGroup.value[META_DATA][IS_DELETED] ||
                (subGroupFormGroup.value[META_DATA][DOES_REPEAT] && subGroupFormGroup.value[META_DATA][IS_PLACEHOLDER])
              ) {
                formItemFormGroup.controls[VALUE].clearValidators();
                formItemFormGroup.controls[VALUE].updateValueAndValidity({ emitEvent: false });
                return;
              }

              // else update the value and validity of the form item value control with the validators
              // and add the form item composite key to the invalid form item composite keys list if the form item value control is invalid
              formItemFormGroup.controls[VALUE].updateValueAndValidity({ emitEvent: false });
              if (formItemFormGroup.controls[VALUE].invalid) {
                const key = this.keyService.getFormItemCompositeKey(
                  formItemFormGroup.value[META_DATA][SCHEMA_GROUP_DOM_ID],
                  formItemFormGroup.value[META_DATA][SCHEMA_GROUP_INDEX],
                  subGroupFormGroup.value[META_DATA][DOM_ID],
                  subGroupFormGroup.value[META_DATA][INDEX],
                  formItemFormGroup.value[META_DATA][FIELD_NAME]
                );
                validityData.invalidFormItemCompositeKeys.push(key);
              }
            });
          });
        });

        // if the sub group has been soft deleted, return
        if (subGroupFormGroup.value[META_DATA][IS_DELETED]) {
          return;
        }

        // update the key is touched and key is valid maps if the sub group has not been soft deleted
        const subGroupCompositeKey = this.keyService.getSubGroupCompositeKey(
          schemaGroupDomId,
          schemaGroupIndex,
          subGroupFormGroup.value[META_DATA][DOM_ID],
          subGroupFormGroup.value[META_DATA][INDEX]
        );
        validityData.subGroupCompositeKeyIsTouchedMap.set(subGroupCompositeKey, subGroupFormGroup.touched);
        validityData.subGroupCompositeKeyIsValidMap.set(subGroupCompositeKey, subGroupFormGroup.valid);
      });

    return validityData;
  }

  getRepeatableGroupLength(schemaGroup: SchemaGroup, schemaGroupIndex: number, subGroup: SubGroup, crashReportDetail: CrashReportDetail): number {
    if (CommonUtils.isDefined(schemaGroup) && CommonUtils.isNullOrUndefined(subGroup)) {
      return this.getRepeatableGroupLengthOnSchemaGroup(schemaGroup, crashReportDetail);
    }
    return this.getRepeatableGroupLengthOnSchemaGroupAndSubGroup(schemaGroup, schemaGroupIndex, subGroup, crashReportDetail);
  }

  /**
   * private methods
   */
  private populateSchemaGroups(
    schema: Schema,
    crashReportDetail: CrashReportDetail,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    schemaGroupDomIdSchemaGroupIndexMap: Map<string, number>,
    isNewCrashReport: boolean
  ): void {
    schema.forEach(schemaGroup => {
      // add the schema group to the form and save a reference to the form array
      this.form.addControl(schemaGroup.key, this.fb.array([]));
      const schemaGroupFormArray = this.form.controls[schemaGroup.key] as FormArray;

      // actual schema group length is the number of schema groups in the crash report detail
      // adjusted schema group length is the actual schema group length if it is greater than 0, otherwise it is 1
      // we need to add at least one schema group to the form cause we iterate over the form to build the view
      const actualSchemaGroupLength = this.getRepeatableGroupLength(schemaGroup, null, null, crashReportDetail);
      const adjustedSchemaGroupLength = actualSchemaGroupLength > 0 ? actualSchemaGroupLength : 1;

      // each schema group form group contains a meta data key and a sub groups key
      // meta data value contains data needed to display the schema group in the view and send update requests to the api
      // sub groups value is a form array that contains sub group form groups
      CollectionUtils.range(adjustedSchemaGroupLength).forEach(schemaGroupIndex => {
        schemaGroupFormArray.push(
          this.fb.group({
            metaData: [
              this.metaDataService.buildSchemaGroupMetaData(
                schemaGroup,
                schemaGroupIndex,
                schemaGroupDomIdSchemaGroupIndexMap,
                actualSchemaGroupLength === 0,
                schemaGroupIndex // when building the form for the first time, the api index is the index of the schema group in the schema
              ),
            ],
            subGroups: this.fb.array([]),
          })
        );

        // update the schema group composite key form group map so we can access in constant time
        this.mapService.schemaGroupCompositeKeyFormGroupMap.set(
          this.keyService.getSchemaGroupCompositeKey(schemaGroup.key, schemaGroupIndex),
          CollectionUtils.getLastElement(schemaGroupFormArray.controls) as FormGroup
        );

        const schemaGroupFormGroup = CollectionUtils.getLastElement(schemaGroupFormArray.controls) as FormGroup;
        const subGroupFormArray = schemaGroupFormGroup.controls[FormConstants.SUB_GROUPS] as FormArray;

        schemaGroup.subGroups.forEach(subGroup => {
          const actualSubGroupLength = this.getRepeatableGroupLength(schemaGroup, schemaGroupIndex, subGroup, crashReportDetail);
          const adjustedSubGroupLength = actualSubGroupLength > 0 ? actualSubGroupLength : 1;

          CollectionUtils.range(adjustedSubGroupLength).forEach(subGroupIndex =>
            this.populateSubGroups(
              subGroupFormArray,
              crashReportDetail,
              schemaGroup,
              schemaGroupIndex,
              subGroup,
              subGroupIndex,
              partialCompositeKeyRuleMap,
              actualSubGroupLength === 0,
              schemaGroupFormGroup,
              subGroupIndex, // api index is set to the sub group index when building the form for the first time
              isNewCrashReport
            )
          );
        });
      });
    });
  }

  private populateSubGroups(
    subGroupFormArray: FormArray,
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    isPlaceholder: boolean,
    schemaGroupFormGroup: FormGroup,
    apiIndex: number,
    isNewCrashReport: boolean
  ): void {
    const newSubGroupFormGroup = this.fb.group({
      metaData: [this.metaDataService.buildSubGroupMetaData(schemaGroup, subGroup, subGroupIndex, isPlaceholder, apiIndex)],
      sections: this.fb.array([]),
    });
    subGroupFormArray.push(newSubGroupFormGroup);

    this.mapService.subGroupCompositeKeyFormGroupMap.set(
      this.keyService.getSubGroupCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key, subGroupIndex),
      newSubGroupFormGroup
    );

    const compositeKey = this.keyService.getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key);
    MapUtils.putIfAbsent(this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap, compositeKey, []);
    this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap.get(compositeKey).push(newSubGroupFormGroup);

    const sectionsFormArray = newSubGroupFormGroup.controls[FormConstants.SECTIONS] as FormArray;

    this.populateSections(
      crashReportDetail,
      schemaGroup,
      schemaGroupIndex,
      subGroup,
      subGroupIndex,
      sectionsFormArray,
      partialCompositeKeyRuleMap,
      newSubGroupFormGroup,
      schemaGroupFormGroup,
      isNewCrashReport,
      schemaGroupFormGroup.value[FormConstants.META_DATA][FormConstants.API_INDEX]
    );
  }

  private populateSections(
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    sectionsFormArray: FormArray,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    subGroupFormGroup: FormGroup,
    schemaGroupFormGroup: FormGroup,
    isNewCrashReport: boolean,
    schemaGroupApiIndex: number
  ): void {
    subGroup.sections.forEach((section, sectionIndex) => {
      sectionsFormArray.push(this.fb.group({ metaData: [this.metaDataService.buildSectionMetaData(section, sectionIndex)], pages: this.fb.array([]) }));

      const sectionFormGroup = CollectionUtils.getLastElement(sectionsFormArray.controls) as FormGroup;
      const pagesFormArray = sectionFormGroup.controls[FormConstants.PAGES] as FormArray;

      this.populatePages(
        crashReportDetail,
        schemaGroup,
        schemaGroupIndex,
        subGroup,
        subGroupIndex,
        pagesFormArray,
        partialCompositeKeyRuleMap,
        section,
        sectionFormGroup,
        subGroupFormGroup,
        schemaGroupFormGroup,
        isNewCrashReport,
        schemaGroupApiIndex
      );
    });
  }

  private populatePages(
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    pagesFormArray: FormArray,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    section: Section,
    sectionFormGroup: FormGroup,
    subGroupFormGroup: FormGroup,
    schemaGroupFormGroup: FormGroup,
    isNewCrashReport: boolean,
    schemaGroupApiIndex: number
  ): void {
    section.pages.forEach((page, pageIndex) => {
      pagesFormArray.push(this.fb.group({ metaData: [this.metaDataService.buildPageMetaData(page, pageIndex)], formItems: this.fb.array([]) }));

      const pageFormGroup = CollectionUtils.getLastElement(pagesFormArray.controls) as FormGroup;
      const formItemFormArray = pageFormGroup.controls[FormConstants.FORM_ITEMS] as FormArray;

      this.populateFormItems(
        crashReportDetail,
        schemaGroup,
        schemaGroupIndex,
        subGroup,
        subGroupIndex,
        formItemFormArray,
        partialCompositeKeyRuleMap,
        page,
        pageFormGroup,
        sectionFormGroup,
        subGroupFormGroup,
        schemaGroupFormGroup,
        isNewCrashReport,
        schemaGroupApiIndex
      );
    });
  }

  private populateFormItems(
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    formItemsFormArray: FormArray,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    page: Page,
    pageFormGroup: FormGroup,
    sectionFormGroup: FormGroup,
    subGroupFormGroup: FormGroup,
    schemaGroupFormGroup: FormGroup,
    isNewCrashReport: boolean,
    schemaGroupApiIndex: number
  ): void {
    page.formItems.forEach(formItem => {
      const formGroup = this.addFormItemToFormArray(
        formItem,
        crashReportDetail,
        schemaGroup,
        schemaGroupIndex,
        subGroup,
        subGroupIndex,
        formItemsFormArray,
        partialCompositeKeyRuleMap,
        pageFormGroup,
        sectionFormGroup,
        subGroupFormGroup,
        schemaGroupFormGroup,
        isNewCrashReport,
        schemaGroupApiIndex
      );
      this.updateCompositeKeyMaps(formItem, schemaGroupIndex, subGroupIndex, formGroup);
    });
  }

  private addFormItemToFormArray(
    formItem: FormItem,
    crashReportDetail: CrashReportDetail,
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    subGroupIndex: number,
    formItemsFormArray: FormArray,
    partialCompositeKeyRuleMap: Map<string, Rule>,
    pageFormGroup: FormGroup,
    sectionFormGroup: FormGroup,
    subGroupFormGroup: FormGroup,
    schemaGroupFormGroup: FormGroup,
    isNewCrashReport: boolean,
    schemaGroupApiIndex: number
  ): FormGroup {
    // extract the value from the crash report detail
    const { value, isDefaultValue } = this.valueService.getValue(
      formItem,
      crashReportDetail,
      schemaGroup,
      schemaGroupApiIndex,
      subGroup,
      subGroupIndex,
      partialCompositeKeyRuleMap,
      isNewCrashReport
    );

    // save reference to the rule for the form item
    // we will use this to determine if the form item is required
    const rule = partialCompositeKeyRuleMap.get(this.keyService.getPartialCompositeKey(formItem.table, formItem.fieldName));

    const subGroupApiIndex = subGroupFormGroup.value[FormConstants.META_DATA][FormConstants.API_INDEX];

    // each control is a form group containing a meta data key and a value key
    // use value from crash report detail if it exists, else use default value if it exists, else use null
    // update value and run validation on change so user gets immediate feedback
    const formGroup = this.fb.group(
      {
        metaData: [
          this.metaDataService.buildFormItemMetaData(
            formItem,
            schemaGroup,
            schemaGroupIndex,
            subGroup,
            subGroupIndex,
            isDefaultValue,
            schemaGroupApiIndex,
            subGroupApiIndex
          ),
        ],
        value: [CommonUtils.isDefined(value) ? value : null],
      },
      { updateOn: 'change' }
    );

    if (CommonUtils.hasContent(rule) && CommonUtils.isDefinedAndTrue(rule.isRequired)) {
      switch (formGroup.value[FormConstants.META_DATA][FormConstants.TYPE]) {
        case FormItemType.BUTTON_GROUP:
        case FormItemType.BUTTON_GROUP_MULTI:
        case FormItemType.ECRASH_REF:
        case FormItemType.ECRASH_REF_MULTI:
          formGroup.controls[FormConstants.VALUE].addValidators([ListValidator.required()]);
          break;
        default:
          formGroup.controls[FormConstants.VALUE].addValidators([Validators.required]);
      }
      formGroup.controls[FormConstants.META_DATA].patchValue({ ...formGroup.value[FormConstants.META_DATA], isRequired: true });
    }

    // form items with type date, phone, and time are rendered in an oss-input component
    // add validator here and in the validation service upon value change as validators are cleared on value change
    // long term fix: add a validator to the list of validators in rule for the form item
    if (formItem.type === 'date') {
      formGroup.controls[FormConstants.VALUE].addValidators([DateValidator.getMmSlashDdSlashYyStringValidator()]);
    }
    if (formItem.type === 'phone') {
      formGroup.controls[FormConstants.VALUE].addValidators([StringValidator.phoneNumber()]);
    }
    if (formItem.type === 'time') {
      formGroup.controls[FormConstants.VALUE].addValidators([TimeValidator.getHhMmValidator()]);
    }

    this.mapService.formItemCompositeKeyFormGroupMap.set(
      this.keyService.getFormItemCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key, subGroupIndex, formItem.fieldName),
      formGroup
    );
    this.mapService.formItemCompositeKeyParentPageFormGroupMap.set(
      this.keyService.getFormItemCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key, subGroupIndex, formItem.fieldName),
      pageFormGroup
    );
    this.mapService.formItemCompositeKeyParentSectionFormGroupMap.set(
      this.keyService.getFormItemCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key, subGroupIndex, formItem.fieldName),
      sectionFormGroup
    );
    this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap.set(
      this.keyService.getFormItemCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key, subGroupIndex, formItem.fieldName),
      subGroupFormGroup
    );
    this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap.set(
      this.keyService.getFormItemCompositeKey(schemaGroup.key, schemaGroupIndex, subGroup.key, subGroupIndex, formItem.fieldName),
      schemaGroupFormGroup
    );

    formItemsFormArray.push(formGroup);
    return formGroup;
  }

  private getRepeatableGroupLengthOnSchemaGroup(schemaGroup: SchemaGroup, crashReportDetail: CrashReportDetail): number {
    if (CommonUtils.isEmpty(crashReportDetail)) {
      // crash report detail is empty if the app is offline
      // this is a lil hacky
      // in the value service, if the schema group is repeatable, we take the key off the schema group and pass it into the
      // crash report detail, this gives an array of objects. we take the schema group index th object and pass into it the
      // field name, this gives us the value from the crash report detail
      // for vehicle schema groups, just use vehic
      if (schemaGroup.key === 'vehic') {
        // a new crash report always has two vehicle details schema groups on create
        return 2;
      }
      return 0;
    }
    if (CommonUtils.isDefinedAndFalse(schemaGroup.repeats)) {
      // a lil hacky but necessary for now
      return schemaGroup.key === 'vehic' ? 2 : 1;
    }
    const schemaGroupsFromCrashReportDetail = crashReportDetail[schemaGroup.key];
    CommonUtils.assert(
      CollectionUtils.everyElementIsOfType(schemaGroupsFromCrashReportDetail, 'object'),
      'schemaGroupsFromCrashReportDetail is not an object[]'
    );
    return (schemaGroupsFromCrashReportDetail as unknown[]).length;
  }

  private getRepeatableGroupLengthOnSchemaGroupAndSubGroup(
    schemaGroup: SchemaGroup,
    schemaGroupIndex: number,
    subGroup: SubGroup,
    crashReportDetail: CrashReportDetail
  ): number {
    switch (true) {
      case CommonUtils.isDefinedAndFalse(schemaGroup.repeats) && CommonUtils.isDefinedAndFalse(subGroup.repeats):
      case CommonUtils.isDefinedAndTrue(schemaGroup.repeats) && CommonUtils.isDefinedAndFalse(subGroup.repeats):
        return 1;
      case CommonUtils.isDefinedAndFalse(schemaGroup.repeats) && CommonUtils.isDefinedAndTrue(subGroup.repeats): {
        if (CommonUtils.isEmpty(crashReportDetail)) {
          // crash report detail is empty if the app is offline
          return 0;
        }
        const subGroupsFromCrashReportDetail = crashReportDetail[subGroup.key];
        CommonUtils.assert(CollectionUtils.everyElementIsOfType(subGroupsFromCrashReportDetail, 'object'), 'subGroupsFromCrashReportDetail is not an object[]');
        return (subGroupsFromCrashReportDetail as unknown[]).length;
      }
      case CommonUtils.isDefinedAndTrue(schemaGroup.repeats) && CommonUtils.isDefinedAndTrue(subGroup.repeats): {
        if (CommonUtils.isEmpty(crashReportDetail)) {
          // crash report detail is empty if the app is offline
          return 0;
        }
        const subGroupsFromCrashReportDetail = crashReportDetail[subGroup.key];
        CommonUtils.assert(CollectionUtils.everyElementIsOfType(subGroupsFromCrashReportDetail, 'object'), 'subGroupsFromCrashReportDetail is not an object[]');
        const subGroupsScopedToSchemaGroup = (subGroupsFromCrashReportDetail as unknown[]).filter(
          currentSubGroup => currentSubGroup[schemaGroup.db_repeat_field] === schemaGroupIndex + 1
        );
        return subGroupsScopedToSchemaGroup.length;
      }
    }
    throw new TechnicalException('unable to determine repeatable group length');
  }

  private updateCompositeKeyMaps(formItem: FormItem, schemaGroupIndex: number, subGroupIndex: number, formGroup: FormGroup): void {
    this.mapService.fullCompositeKeyFormGroupMap.set(
      this.keyService.getFullCompositeKey(formItem.table, formItem.fieldName, schemaGroupIndex, subGroupIndex),
      formGroup
    );
    const compositeKey = this.keyService.getPartialCompositeKey(formItem.table, formItem.fieldName);
    MapUtils.putIfAbsent(this.mapService.partialCompositeKeyFormGroupsMap, compositeKey, []);
    this.mapService.partialCompositeKeyFormGroupsMap.get(compositeKey).push(formGroup);
  }

  private setPartialCompositeKeyRuleMap(ruleSet: RuleSet): void {
    ruleSet.forEach(rule => {
      const partialCompositeKey = this.keyService.getPartialCompositeKey(rule.tableName, rule.columnName);
      this.mapService.partialCompositeKeyRuleMap.set(partialCompositeKey, rule);
    });
  }

  private buildSchemaGroupDomIdSchemaGroupIndexMap(schema: Schema): Map<string, number> {
    const schemaGroupDomIdSchemaGroupIndexMap = new Map<string, number>();
    schema.forEach((schemaGroup, index) => schemaGroupDomIdSchemaGroupIndexMap.set(schemaGroup.key, index));
    return schemaGroupDomIdSchemaGroupIndexMap;
  }

  private updateIsActionableAndShouldHideProperties(): void {
    const { META_DATA, DOES_REPEAT, IS_PLACEHOLDER, SUB_GROUPS, SECTIONS, PAGES, FORM_ITEMS } = FormConstants;

    Object.values(this.form.controls).forEach((schemaGroupFormArray: FormArray) => {
      schemaGroupFormArray.controls
        .filter((schemaGroupFormGroup: FormGroup) => {
          const metaData = schemaGroupFormGroup.value[META_DATA];
          if (metaData[DOES_REPEAT] && metaData[IS_PLACEHOLDER]) {
            return false;
          }
          return true;
        })
        .forEach((schemaGroupFormGroup: FormGroup) => {
          const subGroupsFormArray = schemaGroupFormGroup.controls[SUB_GROUPS] as FormArray;
          subGroupsFormArray.controls
            .filter((subGroupFormGroup: FormGroup) => {
              const metaData = subGroupFormGroup.value[META_DATA];
              if (metaData[DOES_REPEAT] && metaData[IS_PLACEHOLDER]) {
                return false;
              }
              return true;
            })
            .forEach((subGroupFormGroup: FormGroup) => {
              const sectionsFormArray = subGroupFormGroup.controls[SECTIONS] as FormArray;
              sectionsFormArray.controls.forEach((sectionFormGroup: FormGroup) => {
                const pagesFormArray = sectionFormGroup.controls[PAGES] as FormArray;
                pagesFormArray.controls.forEach((pageFormGroup: FormGroup) => {
                  const formItemsFormArray = pageFormGroup.controls[FORM_ITEMS] as FormArray;
                  const allFormItemsAreHidden = formItemsFormArray.controls.every(
                    (formItemFormGroup: FormGroup) => formItemFormGroup.value[META_DATA].shouldHide
                  );
                  if (allFormItemsAreHidden) {
                    pageFormGroup.controls[META_DATA].setValue({ ...pageFormGroup.value[META_DATA], shouldHide: true });
                  }
                });
                const allPagesAreHidden = pagesFormArray.controls.every((pageFormGroup: FormGroup) => pageFormGroup.value[META_DATA].shouldHide);
                if (allPagesAreHidden) {
                  sectionFormGroup.controls[META_DATA].setValue({ ...sectionFormGroup.value[META_DATA], isActionable: false });
                }
              });
              const noSectionsAreActionable = sectionsFormArray.controls.every(
                (sectionFormGroup: FormGroup) => sectionFormGroup.value[META_DATA].isActionable === false
              );
              if (noSectionsAreActionable) {
                subGroupFormGroup.controls[META_DATA].setValue({ ...subGroupFormGroup.value[META_DATA], isActionable: false });
              }
            });
        });
    });
  }

  // TODO: figure out why this is necessary
  // for some reason, when you interact with the button group modal, the first line in this method is not enough
  // there is a form control for every option sent into the button group modal
  private resetForm(): void {
    this.form = this.fb.group({});
    Object.keys(this.form.controls).forEach(key => this.form.removeControl(key));
  }

  private updateSchemaGroupApiIndexes(schemaGroupFormGroup: FormGroup, newApiIndex: number): void {
    const subGroupFormArray = schemaGroupFormGroup.controls[FormConstants.SUB_GROUPS] as FormArray;
    subGroupFormArray.controls.forEach((subGroupFormGroup: FormGroup) => {
      const sectionsFormArray = subGroupFormGroup.controls[FormConstants.SECTIONS] as FormArray;
      sectionsFormArray.controls.forEach((sectionFormGroup: FormGroup) => {
        const pagesFormArray = sectionFormGroup.controls[FormConstants.PAGES] as FormArray;
        pagesFormArray.controls.forEach((pageFormGroup: FormGroup) => {
          const formItemsFormArray = pageFormGroup.controls[FormConstants.FORM_ITEMS] as FormArray;
          formItemsFormArray.controls.forEach((formItemFormGroup: FormGroup) => {
            formItemFormGroup.controls[FormConstants.META_DATA].setValue({
              ...formItemFormGroup.value[FormConstants.META_DATA],
              [FormConstants.SCHEMA_GROUP_API_INDEX]: newApiIndex,
            });
          });
        });
      });
    });
  }

  private updateSubGroupApiIndexes(subGroupFormGroup: FormGroup, newApiIndex: number): void {
    const sectionsFormArray = subGroupFormGroup.controls[FormConstants.SECTIONS] as FormArray;
    sectionsFormArray.controls.forEach((sectionFormGroup: FormGroup) => {
      const pagesFormArray = sectionFormGroup.controls[FormConstants.PAGES] as FormArray;
      pagesFormArray.controls.forEach((pageFormGroup: FormGroup) => {
        const formItemsFormArray = pageFormGroup.controls[FormConstants.FORM_ITEMS] as FormArray;
        formItemsFormArray.controls.forEach((formItemFormGroup: FormGroup) => {
          formItemFormGroup.controls[FormConstants.META_DATA].setValue({
            ...formItemFormGroup.value[FormConstants.META_DATA],
            [FormConstants.SUB_GROUP_API_INDEX]: newApiIndex,
          });
        });
      });
    });
  }

  private initializeServiceLocationIdReviewDataMap(): void {
    const serviceLocationIdReviewDataMap = new Map<number, { schema: Schema; commentsPath: [string, string, string, string] }>();

    // configure new orleans schema
    const newOrleansSchema: Schema = [
      {
        key: 'schemaKey',
        name: 'schemaName',
        repeats: false,
        subGroups: [
          {
            key: 'subGroupKey',
            name: 'subGroupName',
            repeats: false,
            sections: [
              {
                key: 'comment',
                name: 'Comment',
                pages: [
                  {
                    key: 'comment',
                    name: 'comment',
                    template: TemplateType.INFORMATION,
                    templateSubType: TemplateSubType.REVIEW_COMMENTS,
                    templateData: null,
                    formItems: [],
                  },
                ],
              },
              {
                key: 'section2Key',
                name: 'Crash Date',
                pages: [
                  {
                    key: 'crashdate',
                    name: 'Crash Date',
                    formItems: [
                      {
                        fieldName: 'CRASH_DATE',
                        key: 'CRASH-0007',
                        table: 'CRASH',
                        type: 'date',
                        label: 'Date Of The Crash',
                        placeholder: 'MM/DD/YY',
                        formControlType: 'date',
                        helpText: 'Enter the date of the crash',
                      },
                    ],
                  },
                ],
              },
              {
                key: 'section3Key',
                name: 'Narrative',
                pages: [
                  {
                    key: 'narrative',
                    name: 'Narrative',
                    formItems: [
                      {
                        key: 'NARR-0001',
                        type: 'textarea',
                        label: 'Narrative Text',
                        table: 'NARR',
                        fieldName: 'NARRATIVE',
                        placeholder: 'VEHICLE #1 WAS...\n\nVEHICLE #2 WAS...\n\nAGENT OBSERVED...',
                        max_length: 200000,
                        formControlType: 'textarea',
                        helpText: 'Enter the narrative',
                      },
                    ],
                  },
                ],
              },
              {
                key: 'section4Key',
                name: 'Diagram',
                pages: [
                  {
                    key: 'diagram',
                    name: 'Diagram',
                    template: TemplateType.DIAGRAM,
                    label: 'Easy Street Draw Diagram',
                    helpText: 'Draw the diagram',
                    formItems: [
                      {
                        key: 'NARR-0002',
                        type: 'image',
                        label: 'Easy Street Draw Diagram',
                        table: 'NARR',
                        fieldName: 'DIAGRAM',
                        formControlType: 'image',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    const newOrleansCommentsPath: [string, string, string, string] = ['schemaKey', 'subGroupKey', 'comment', 'comment'];
    serviceLocationIdReviewDataMap.set(this.NEW_ORLEANS_SERVICE_LOCATION_ID, { schema: newOrleansSchema, commentsPath: newOrleansCommentsPath });

    this.serviceLocationIdReviewDataMap = serviceLocationIdReviewDataMap;
  }
}
