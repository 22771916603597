export class SchemaConstants {
  static readonly BOOL = 'BOOL';
  static readonly DIAGRAM = 'diagram';
  static readonly FIELD_NAME = 'fieldName';
  static readonly JUCTION_LOCATION_DESC = 'juction_location_desc';
  static readonly JUNCTION_LOCATION_DESC = 'junction_location_desc';
  static readonly MAN_COLL_CD = 'man_coll_cd';
  static readonly MAN_COLL_DESC = 'man_coll_desc';
  static readonly NARR = 'narr';
  static readonly NARRATIVE = 'narrative';
  static readonly TABLE = 'table';
}
