import { createAction, props } from '@ngrx/store';
import { SchemaGroupMetaData } from '../../data-model/models/schema-group-meta-data.model';
import { SubGroupMetaData } from '../../data-model/models/sub-group-meta-data.model';

const feature = '[Crash Report Structural]';

export const addSchemaGroup = createAction(`${feature} Add Schema Group`, props<{ schemaGroupMetaData: SchemaGroupMetaData }>());
export const schemaGroupAdded = createAction(`${feature} Schema Group Added`);

export const removeSchemaGroup = createAction(`${feature} Remove Schema Group`, props<{ schemaGroupMetaData: SchemaGroupMetaData }>());
export const schemaGroupRemoved = createAction(`${feature} Schema Group Removed`);

export const addSubGroup = createAction(`${feature} Add Sub Group`, props<{ schemaGroupMetaData: SchemaGroupMetaData; subGroupMetaData: SubGroupMetaData }>());
export const subGroupAdded = createAction(`${feature} Sub Group Added`);

export const removeSubGroup = createAction(
  `${feature} Remove Sub Group`,
  props<{ schemaGroupMetaData: SchemaGroupMetaData; subGroupMetaData: SubGroupMetaData; subGroupWasHiddenOnValueChange?: boolean }>()
);
export const subGroupRemoved = createAction(`${feature} Sub Group Removed`);

export const swapVehicles = createAction(`${feature} Swap Vehicles`, props<{ fromIndex: number; tooIndex: number }>());
export const vehiclesSwapped = createAction(`${feature} Vehicles Swapped`, props<{ swapWasSuccessful: boolean }>());
