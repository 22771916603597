import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { AuthState } from '../state/auth.state';

export const selectAuthState = (state: AppState) => state.auth;

export const selectApiToken = createSelector(selectAuthState, (state: AuthState) => state.apiToken);

export const selectUser = createSelector(selectAuthState, (state: AuthState) => state.currentUser);

// user roles
export const selectUserIsSupervisor = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.includes('supervisor'))
    .orElse(false)
);

export const selectUserIsReviewer = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.includes('reviewer'))
    .orElse(false)
);

export const selectUserIsAdministrator = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.includes('administrator'))
    .orElse(false)
);

export const selectUserIsAdminUser = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.includes('admin-user'))
    .orElse(false)
);

export const selectUserIsSuperUser = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.includes('super-user'))
    .orElse(false)
);

// permissions based on user roles
export const selectUserHasApproveAndRejectPermissions = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.some(role => ['supervisor', 'reviewer'].includes(role)))
    .orElse(false)
);

export const selectUserHasActivityPermissions = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.some(role => ['default-user', 'supervisor', 'administrator'].includes(role)))
    .orElse(false)
);

export const selectUserHasCreateNewCrashReportPermissions = createSelector(selectUser, currentUser =>
  OssOptional.ofNullable(currentUser)
    .map(user => user.roles.some(role => ['default-user', 'supervisor'].includes(role)))
    .orElse(false)
);
