import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { DateValidator } from '../../../../shared/validators/date-validator';
import { ListValidator } from '../../../../shared/validators/list-validator';
import { NumberValidator } from '../../../../shared/validators/number-validator';
import { StringValidator } from '../../../../shared/validators/string-validator';
import { TimeValidator } from '../../../../shared/validators/time-validator';
import { FormConstants } from '../../../constants/form.constants';
import { ControlLevelValidatorType } from '../../../data-model/enums/control-level-validator-type.enum';
import { FormItemType } from '../../../data-model/enums/form-item-type.enum';
import { ControlLevelValidator } from '../../../data-model/models/control-level-validator.model';
import { Rule } from '../../../data-model/models/rule.model';
import { ValueChange } from '../../../data-model/models/value-change.model';
import { RuleSet } from '../../../data-model/types/rule-set.type';
import { CrashReportDetailFormKeyService } from '../crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailMapService } from '../crash-report-detail-map.service';

@Injectable({ providedIn: 'root' })
export class CrashReportDetailFormValidationService {
  constructor(
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly mapService: CrashReportDetailMapService
  ) {}

  addValidationOnFormCreation(ruleSet: RuleSet): void {
    ruleSet
      // TODO: why was the second condition here?
      // .filter(rule => CommonUtils.hasContent(rule.validators) || CommonUtils.isDefinedAndTrue(rule.isRequired))
      .filter(rule => CommonUtils.hasContent(rule.validators))
      .forEach(rule => {
        const ruleCompositeKey = this.keyService.getPartialCompositeKey(rule.tableName, rule.columnName);
        const targetFormGroups = this.mapService.partialCompositeKeyFormGroupsMap.get(ruleCompositeKey);
        if (CommonUtils.hasContent(targetFormGroups)) {
          targetFormGroups.forEach(targetFormGroup => this.applyValidationsToFormControl(targetFormGroup, rule.validators));
        }
      });
  }

  updateValidationOnImpactedFormControlOnValueChange(impactedFormGroup: FormGroup, valueChange: ValueChange, rule: Rule, impactedFormGroupRule: Rule): void {
    const valueFormControl = impactedFormGroup.controls[FormConstants.VALUE];
    valueFormControl.clearValidators();

    this.addOrRemoveRequiredValidatorAndUpdateMetaData(impactedFormGroup, valueChange, impactedFormGroupRule);

    if (CommonUtils.hasContent(valueChange.mustEqual)) {
      valueFormControl.addValidators([StringValidator.equals(impactedFormGroup, ...valueChange.mustEqual)]);
    }
    if (CommonUtils.hasContent(valueChange.mustNotEqual)) {
      valueFormControl.addValidators([StringValidator.doesNotEqual(impactedFormGroup, ...valueChange.mustNotEqual)]);
    }

    // for form items with type date, phone, time, add validation on value change
    // long term fix: add validator to list of validators in rule for form item
    if (impactedFormGroup.value[FormConstants.META_DATA][FormConstants.TYPE] === FormItemType.DATE) {
      valueFormControl.addValidators([DateValidator.getMmSlashDdSlashYyStringValidator()]);
    }
    if (impactedFormGroup.value[FormConstants.META_DATA][FormConstants.TYPE] === FormItemType.PHONE) {
      valueFormControl.addValidators([StringValidator.phoneNumber()]);
    }
    if (impactedFormGroup.value[FormConstants.META_DATA][FormConstants.TYPE] === FormItemType.TIME) {
      valueFormControl.addValidators([TimeValidator.getHhMmValidator()]);
    }

    this.applyValidationsToFormControl(impactedFormGroup, rule.validators, valueChange);
  }

  private applyValidationsToFormControl(impactedFormGroup: FormGroup, valueChangeValidators: ControlLevelValidator[], valueChange?: ValueChange) {
    const metaData = impactedFormGroup.value[FormConstants.META_DATA];
    const valueFormControl = impactedFormGroup.controls[FormConstants.VALUE];

    // if we are overriding and hiding the field, we don't want to apply any validators
    // else, we need to apply rules for the control in addition to the value change rules
    let baseRuleValidators;
    if (
      CommonUtils.isDefined(valueChange) &&
      CommonUtils.isDefinedAndTrue(valueChange.shouldOverride) &&
      CommonUtils.isDefinedAndFalse(valueChange.isVisible)
    ) {
      baseRuleValidators = [];
    } else {
      const partialCompositeKey = this.keyService.getPartialCompositeKey(metaData[FormConstants.TABLE], metaData[FormConstants.FIELD_NAME]);
      baseRuleValidators = OssOptional.ofNullable(this.mapService.partialCompositeKeyRuleMap.get(partialCompositeKey))
        .map(baseRules => OssOptional.ofNullable(baseRules.validators).orElse([]))
        .orElse([]);
    }

    baseRuleValidators.concat(OssOptional.ofNullable(valueChangeValidators).orElse([])).forEach(validator => {
      let validatorFunction: (...args: unknown[]) => ValidatorFn;
      switch (validator.validatorType) {
        case ControlLevelValidatorType.STRING:
          validatorFunction = StringValidator.validatorNameValidatorFunctionMap.get(validator.validatorName);
          break;
        case ControlLevelValidatorType.TIME:
          validatorFunction = TimeValidator.validatorNameValidatorFunctionMap.get(validator.validatorName);
          break;
        case ControlLevelValidatorType.DATE:
          validatorFunction = DateValidator.validatorNameValidatorFunctionMap.get(validator.validatorName);
          break;
        case ControlLevelValidatorType.NUMBER:
          validatorFunction = NumberValidator.validatorNameValidatorFunctionMap.get(validator.validatorName);
          break;
        default:
          throw new TechnicalException(`invalid control level validator type: ${validator.validatorType}`);
      }
      if (CommonUtils.isNullOrUndefined(validatorFunction)) {
        throw new TechnicalException(`unable to locate validator with type ${validator.validatorType} and name ${validator.validatorName}`);
      }
      if (CommonUtils.isEmpty(validator.targetControls)) {
        valueFormControl.addValidators([validatorFunction(...OssOptional.ofNullable(validator.arguments).orElse([]))]);
      }
      if (CommonUtils.hasContent(validator.targetControls)) {
        const targetValueControls = validator.targetControls.map(targetControl => {
          const targetFormGroupCompositeKey = this.keyService.getFullCompositeKey(
            targetControl.tableName,
            targetControl.columnName,
            metaData[FormConstants.SCHEMA_GROUP_INDEX],
            metaData[FormConstants.SUB_GROUP_INDEX]
          );
          const targetFormGroup = this.mapService.fullCompositeKeyFormGroupMap.get(targetFormGroupCompositeKey);
          if (CommonUtils.isNullOrUndefined(targetFormGroup)) {
            throw new TechnicalException(`unable to find form group with table name ${targetControl.tableName} and column name ${targetControl.columnName}`);
          }
          return targetFormGroup.controls[FormConstants.VALUE];
        });
        if (targetValueControls.length !== validator.targetControls.length) {
          throw new TechnicalException(`validator targets ${validator.targetControls.length} controls. found ${targetValueControls.length} controls.`);
        }
        valueFormControl.addValidators([validatorFunction(valueFormControl, ...targetValueControls, ...validator.arguments)]);
      }
    });
    valueFormControl.updateValueAndValidity({ emitEvent: false });
  }

  private addOrRemoveRequiredValidatorAndUpdateMetaData(formGroup: FormGroup, valueChange: ValueChange, rule: Rule): void {
    // TODO: this logic is not fully correct.
    switch (true) {
      case CommonUtils.isDefinedAndTrue(valueChange.isRequired):
        this.addRequiredValidatorAndUpdateMetaData(formGroup);
        break;
      // case CommonUtils.isDefinedAndFalse(valueChange.isRequired):
      //   this.removeRequiredValidatorAndUpdateMetaData(formGroup);
      //   break;
      case CommonUtils.isDefined(rule) && CommonUtils.isDefinedAndTrue(rule.isRequired):
        this.addRequiredValidatorAndUpdateMetaData(formGroup);
        break;
      // case CommonUtils.isDefinedAndFalse(rule.isRequired):
      //   this.removeRequiredValidatorAndUpdateMetaData(formGroup);
    }
  }

  private addRequiredValidatorAndUpdateMetaData(formGroup: FormGroup): void {
    switch (formGroup.value[FormConstants.META_DATA][FormConstants.TYPE]) {
      case FormItemType.BUTTON_GROUP:
      case FormItemType.BUTTON_GROUP_MULTI:
      case FormItemType.ECRASH_REF:
      case FormItemType.ECRASH_REF_MULTI:
        formGroup.controls[FormConstants.VALUE].addValidators([ListValidator.required()]);
        break;
      default:
        formGroup.controls[FormConstants.VALUE].addValidators([Validators.required]);
    }
    // oss button group has isRequired input. form control wrapper component takes isRequired value from form item meta data and passes it to the button group
    formGroup.controls[FormConstants.META_DATA].patchValue({ ...formGroup.value[FormConstants.META_DATA], [FormConstants.IS_REQUIRED]: true });
  }

  private removeRequiredValidatorAndUpdateMetaData(formGroup: FormGroup): void {
    formGroup.controls[FormConstants.VALUE].removeValidators([Validators.required]);
    formGroup.controls[FormConstants.META_DATA].patchValue({ ...formGroup.value[FormConstants.META_DATA], [FormConstants.IS_REQUIRED]: false });
  }
}
