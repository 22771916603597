import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TechnicalException } from '../data-model/models/technical-exception.model';
import { CommonUtils } from '../utils/common-utils';
import { ObjectUtils } from '../utils/object-utils';
import { StringUtils } from '../utils/string-utils';

export class ListValidator {
  static required(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefined(control)) {
        return { required: true };
      }
      if (CommonUtils.isNullOrUndefinedOrEmptyString(control.value)) {
        return { required: true };
      }

      // the oss autocomplete component emits a selectable when the user selects a value
      // then it emits an array of strings, this is the real value of the control
      // we want to ignore the selectable and only check the array of strings
      if (ListValidator.isSelectable(control.value)) {
        return {};
      }

      if (StringUtils.isString(control.value)) {
        return control.value.trim().length > 0 ? null : { required: true };
      }

      if (CommonUtils.isArray(control.value)) {
        return control.value.filter(value => CommonUtils.isDefinedAndNonEmptyString(value)).length > 0 ? null : { required: true };
      }

      throw new TechnicalException('control value should be a string or an array');
    };
  }

  private static isSelectable(value: unknown): boolean {
    return ObjectUtils.isObject(value) && 'label' in (value as object) && 'value' in (value as object);
  }
}
