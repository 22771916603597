import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { RuleSetState } from '../state/rule-set.state';

export const selectRuleSetState = (state: AppState) => state.ruleSet;

export const selectRuleSet = createSelector(selectRuleSetState, (state: RuleSetState) => state.ruleSet);
export const selectRuleSetVersion = createSelector(selectRuleSetState, (state: RuleSetState) => state.ruleSetVersion);

export const selectDefaultRuleSet = createSelector(selectRuleSetState, (state: RuleSetState) => state?.defaultRuleSet); // why is the ? needed here
export const selectDefaultRuleSetVersion = createSelector(selectRuleSetState, (state: RuleSetState) => state.defaultRuleSetVersion);
