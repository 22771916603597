import { OssOptional } from './oss-optional';

export type Collector<T, R> = (items: T[]) => R;

export class OssStream<T> {
  private constructor(private array: T[]) {}

  static from<T>(iterable: Iterable<T> | T[]): OssStream<T> {
    const array = Array.isArray(iterable) ? iterable : [...iterable];
    return new OssStream(array);
  }

  map<R>(callback: (value: T) => R): OssStream<R> {
    return new OssStream(this.array.map(callback));
  }

  filter(callback: (value: T) => boolean): OssStream<T> {
    return new OssStream(this.array.filter(callback));
  }

  findFirst(): OssOptional<T> {
    return OssOptional.ofNullable(this.array.length > 0 ? this.array[0] : null);
  }

  sorted(comparator?: (a: T, b: T) => number): OssStream<T> {
    const newArray = [...this.array].sort(comparator);
    return new OssStream(newArray);
  }

  peek(action: (value: T) => void): OssStream<T> {
    const newArray = this.array.map(element => {
      action(element);
      return element;
    });
    return new OssStream(newArray);
  }

  // terminal operations
  collect<R>(collector: Collector<T, R>): R {
    return collector(this.array);
  }

  forEach(action: (value: T) => void): void {
    this.array.forEach(action);
  }
}
