import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../../store/state/app.state';
import { AuthSelectors } from '../../../../auth/store/selectors';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { UnsupportedOperationException } from '../../../../shared/data-model/models/unsupported-operation-exception.model';
import { CollectionUtils } from '../../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { DateUtils } from '../../../../shared/utils/date-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { FormConstants } from '../../../constants/form.constants';
import { FormItemType } from '../../../data-model/enums/form-item-type.enum';
import { NewCrashReportFormValue } from '../../../data-model/models/new-crash-report-form-value.model';
import { OssActivityRecord } from '../../../data-model/models/oss-activity-record.model';
import { PlateToVinResponse } from '../../../data-model/models/plate-to-vin-response.model';
import { SchemaGroupMetaData } from '../../../data-model/models/schema-group-meta-data.model';
import { SubGroupMetaData } from '../../../data-model/models/sub-group-meta-data.model';
import { CrashReportActions } from '../../../store/actions';
import { ServiceLocationSelectors } from '../../../store/selectors';
import { CrashReportDetailFormKeyService } from '../crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailFormValueChangesService } from '../crash-report-detail-form-value-changes/crash-report-detail-form-value-changes.service';
import { CrashReportDetailMapService } from '../crash-report-detail-map.service';

type ValueUpdateFieldPath = [string, number, string, number, string];
type SubmitPair = [
  ValueUpdateFieldPath,
  ValueUpdateFieldPath,
  boolean,
  boolean,
  'yes/no' | 'defined/undefined',
  Record<string, string>,
  { defined: string; undefined: string },
];

@Injectable({ providedIn: 'root' })
export class CrashReportDetailValueUpdateService {
  private readonly SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT = new Map<number, Map<string, ValueUpdateFieldPath>>();
  private readonly SERIVCE_LOCATION_ID_ON_SUBMIT_PAIRS = new Map<number, SubmitPair[]>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly valueChangesService: CrashReportDetailFormValueChangesService
  ) {
    this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT = new Map<number, Map<string, ValueUpdateFieldPath>>();
    this.SERIVCE_LOCATION_ID_ON_SUBMIT_PAIRS = new Map<number, SubmitPair[]>();
    this.initializeServiceLocationIdFieldNamePathMap();
  }

  updateFormOnVinData(
    vinData: PlateToVinResponse,
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData,
    dataIsCorrect: boolean,
    dataIsPartiallyCorrect: boolean,
    dataIsIncorrect: boolean,
    callback: () => void
  ): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocation)
      .pipe(first())
      .subscribe(serviceLocation => {
        const serviceLocationId = serviceLocation.id;
        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocationId);
        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];

        const vin = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.vin)
          .orElse(null);
        const year = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.year)
          .orElse(null);
        const make = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.make)
          .orElse(null);
        const model = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.model)
          .orElse(null);

        OssOptional.ofNullable(fieldNamePathMap.get('VIN')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], vin, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('VEH_YEAR')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], year, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('VEH_MAKE')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], make, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('VEH_MODEL')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], model, false, false])
        );

        argsList.forEach(args => {
          if (dataIsCorrect || dataIsPartiallyCorrect) {
            this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]);
          }
          if (dataIsCorrect) {
            this.hideOrShowDownstreamFormItemsAndUpdateParentPageSectionAndSubgroup(args, true, callback);
          }
          if (dataIsPartiallyCorrect || dataIsIncorrect) {
            this.hideOrShowDownstreamFormItemsAndUpdateParentPageSectionAndSubgroup(args, false, callback);
          }
        });
      });
  }

  updateFormOnArrival(newCrashReportFormValue: NewCrashReportFormValue): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocation)
      .pipe(withLatestFrom(this.store.select(AuthSelectors.selectUser)), first())
      .subscribe(([serviceLocation, user]) => {
        /**
         * notes:
         *    crash date and crash time need to be editable if the crash occured the day before
         *    date and time police notified needs to be linked to dispatch time and date, is this dispatched from the on arrival workflow?
         *    date and time police arrived needs to be linked to the on scene button. i don't know what this is. is this the arrived at button?
         *    date and time investigation complete should be automatic on submit, how do i submit?
         */

        const serviceLocationId = serviceLocation.id;
        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocationId);

        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];
        // set report num 2
        // report num 2 will be defined if the crash report was created while the app was offline
        if (CommonUtils.isDefined(newCrashReportFormValue.reportNum2)) {
          OssOptional.ofNullable(fieldNamePathMap.get('REPORT_NUM_2')).ifPresent(path =>
            this.updateValue(path[0], path[1], path[2], path[3], path[4], newCrashReportFormValue.reportNum2, false, false)
          );
        }

        // set location fields
        OssOptional.ofNullable(fieldNamePathMap.get('LAT')).ifPresent(path =>
          this.updateValue(path[0], path[1], path[2], path[3], path[4], newCrashReportFormValue.latitude, false, false)
        );
        OssOptional.ofNullable(fieldNamePathMap.get('LONG')).ifPresent(path =>
          this.updateValue(path[0], path[1], path[2], path[3], path[4], newCrashReportFormValue.longitude, false, false)
        );
        OssOptional.ofNullable(fieldNamePathMap.get('CRASH_COORDINATES')).ifPresent(path => {
          const location = `${newCrashReportFormValue.latitude},${newCrashReportFormValue.longitude}`;
          this.updateValue(path[0], path[1], path[2], path[3], path[4], location, false, false);
        });

        // set investigating officer fields
        const titleCasePipe = new TitleCasePipe();
        const userFirstName = OssOptional.ofNullable(user.first_name)
          .map(firstName => titleCasePipe.transform(firstName))
          .orElse('UserFirstName');
        const userLastName = OssOptional.ofNullable(user.last_name)
          .map(lastName => titleCasePipe.transform(lastName))
          .orElse('UserLastName');
        const userRank = 'OSS';
        OssOptional.ofNullable(fieldNamePathMap.get('INVEST_OFFICER')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], `${userLastName}, ${userFirstName}`, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('INV_OFF_NAME_FIRST')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], userFirstName, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('INV_OFF_NAME_LAST')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], userLastName, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('INV_OFF_RANK')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], userRank, false, false])
        );

        // set date fields
        const crashDate = DateUtils.convertDateObjectToUtcStringAtSixAm(newCrashReportFormValue.crashDate);
        OssOptional.ofNullable(fieldNamePathMap.get('CRASH_DATE')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('DATE_POLICE_NOTE')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('DATE_POLICE_ARR')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('DATE_LANES_OPEN')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );

        argsList.forEach(args => this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]));
      });
  }

  updateFormOnSubmit(
    form: FormGroup,
    updateDateAndTimeInvestigationComplete: boolean = true,
    isRedirectFromActivity: boolean,
    activityRecord: OssActivityRecord
  ): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocation)
      .pipe(first())
      .subscribe(serviceLocation => {
        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];
        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocation.id);

        let dateInvestigationComplete = DateUtils.convertDateObjectToUtcStringAtSixAm(new Date());
        let timeInvestigationComplete = DateUtils.convertDateObjectTo24HourHhColonMmString(new Date());
        const dateChanged = DateUtils.convertDateObjectToUtcStringAtSixAm(new Date());

        if (isRedirectFromActivity) {
          dateInvestigationComplete = OssOptional.ofNullable(activityRecord)
            .map(record => record.time_closed)
            .map(timeClosed => DateUtils.convertDateObjectToUtcStringAtSixAm(timeClosed))
            .orElse(DateUtils.convertDateObjectToUtcStringAtSixAm(new Date()));

          timeInvestigationComplete = OssOptional.ofNullable(activityRecord)
            .map(record => record.time_closed)
            .map(timeClosed => DateUtils.convertDateObjectTo24HourHhColonMmString(timeClosed))
            .orElse(DateUtils.convertDateObjectTo24HourHhColonMmString(new Date()));
        }

        if (updateDateAndTimeInvestigationComplete) {
          // set the date and time investigation complete to the current date and time
          OssOptional.ofNullable(fieldNamePathMap.get('DATE_INVEST_COMP')).ifPresent(path =>
            argsList.push([path[0], path[1], path[2], path[3], path[4], dateInvestigationComplete, true, false])
          );
          OssOptional.ofNullable(fieldNamePathMap.get('TIME_INVEST_COMP')).ifPresent(path =>
            argsList.push([path[0], path[1], path[2], path[3], path[4], timeInvestigationComplete, false, false])
          );
          OssOptional.ofNullable(fieldNamePathMap.get('DATE_CHANGED')).ifPresent(path =>
            argsList.push([path[0], path[1], path[2], path[3], path[4], dateChanged, true, false])
          );
        }

        const submitPairs = this.SERIVCE_LOCATION_ID_ON_SUBMIT_PAIRS.get(serviceLocation.id);

        if (CommonUtils.isNullOrUndefined(submitPairs)) {
          console.info('no submit pairs found for service location id ' + serviceLocation.id);
          return;
        }

        submitPairs.forEach(submitPair => {
          const upstreamPath = submitPair[0];
          const [upstreamSchemaGroupDomId, , upstreamSubGroupDomId, , upstreamFieldName] = upstreamPath;

          const downstreamPath = submitPair[1];
          const [, , , , downstreamFieldName] = downstreamPath;

          const schemaGroupRepeats = submitPair[2];
          const subGroupRepeats = submitPair[3];

          const type = submitPair[4];
          const yesNoValueMap = submitPair[5];
          const definedUndefinedValueMap = submitPair[6];

          switch (true) {
            case !schemaGroupRepeats && !subGroupRepeats: {
              this.updateDownstreamFormItemValueOnUpstreamFormItemValue(
                argsList,
                fieldNamePathMap,
                upstreamFieldName,
                downstreamFieldName,
                type,
                yesNoValueMap,
                definedUndefinedValueMap,
                null,
                null
              );
              break;
            }

            case schemaGroupRepeats && !subGroupRepeats: {
              const schemaGroupFormArray = form.controls[upstreamSchemaGroupDomId] as FormArray;
              schemaGroupFormArray.controls.forEach(schemaGroupFormGroup => {
                const metaData = schemaGroupFormGroup.value[FormConstants.META_DATA];
                const isPlaceholder = metaData[FormConstants.IS_PLACEHOLDER];
                const isDeleted = metaData[FormConstants.IS_DELETED];
                if (isPlaceholder || isDeleted) {
                  return;
                }
                this.updateDownstreamFormItemValueOnUpstreamFormItemValue(
                  argsList,
                  fieldNamePathMap,
                  upstreamFieldName,
                  downstreamFieldName,
                  type,
                  yesNoValueMap,
                  definedUndefinedValueMap,
                  metaData[FormConstants.INDEX],
                  null
                );
              });
              break;
            }

            case schemaGroupRepeats && subGroupRepeats: {
              const schemaGroupFormArray = form.controls[upstreamSchemaGroupDomId] as FormArray;
              schemaGroupFormArray.controls.forEach((schemaGroupFormGroup: FormGroup) => {
                const schemaGroupMetaData = schemaGroupFormGroup.value[FormConstants.META_DATA];
                const isPlaceholder = schemaGroupMetaData[FormConstants.IS_PLACEHOLDER];
                const isDeleted = schemaGroupMetaData[FormConstants.IS_DELETED];
                if (isPlaceholder || isDeleted) {
                  return;
                }
                const compositeKey = this.keyService.getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(
                  schemaGroupMetaData[FormConstants.DOM_ID],
                  schemaGroupMetaData[FormConstants.INDEX],
                  upstreamSubGroupDomId
                );
                const subGroupsScopedToUpstreamSubGroupDomId = OssOptional.ofNullable(
                  this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap.get(compositeKey)
                ).orElseThrow(() => new TechnicalException('there are no sub groups scoped to ' + compositeKey));
                subGroupsScopedToUpstreamSubGroupDomId.forEach((subGroupFormGroup: FormGroup) => {
                  const subGroupMetaData = subGroupFormGroup.value[FormConstants.META_DATA];
                  const isPlaceholder = subGroupMetaData[FormConstants.IS_PLACEHOLDER];
                  const isDeleted = subGroupMetaData[FormConstants.IS_DELETED];
                  if (isPlaceholder || isDeleted) {
                    return;
                  }
                  this.updateDownstreamFormItemValueOnUpstreamFormItemValue(
                    argsList,
                    fieldNamePathMap,
                    upstreamFieldName,
                    downstreamFieldName,
                    type,
                    yesNoValueMap,
                    definedUndefinedValueMap,
                    schemaGroupMetaData[FormConstants.INDEX],
                    subGroupMetaData[FormConstants.INDEX]
                  );
                });
              });
              break;
            }

            case !schemaGroupRepeats && subGroupRepeats: {
              const schemaGroupFormArray = form.controls[upstreamSchemaGroupDomId] as FormArray;
              const schemaGroupFormGroup = CollectionUtils.getOnlyElement(
                schemaGroupFormArray.controls,
                'there should only be one element in the schema group form array for dom id ' + upstreamSchemaGroupDomId
              );
              const schemaGroupMetaData = schemaGroupFormGroup.value[FormConstants.META_DATA];
              const isPlaceholder = schemaGroupMetaData[FormConstants.IS_PLACEHOLDER];
              const isDeleted = schemaGroupMetaData[FormConstants.IS_DELETED];
              if (isPlaceholder || isDeleted) {
                throw new TechnicalException('there is only one schema group, it should not be a placeholder or deleted');
              }
              const compositeKey = this.keyService.getSchemaGroupDomIdIndexSubGroupDomIdCompositeKey(upstreamSchemaGroupDomId, 0, upstreamSubGroupDomId);
              const subGroupsScopedToUpstreamSubGroupDomId = OssOptional.ofNullable(
                this.mapService.schemaGroupDomIdIndexSubGroupDomIdCompositeKeySubGroupsMap.get(compositeKey)
              ).orElseThrow(() => new TechnicalException('there are no sub groups scoped to ' + compositeKey));
              subGroupsScopedToUpstreamSubGroupDomId.forEach((subGroupFormGroup: FormGroup) => {
                const subGroupMetaData = subGroupFormGroup.value[FormConstants.META_DATA];
                const isPlaceholder = subGroupMetaData[FormConstants.IS_PLACEHOLDER];
                const isDeleted = subGroupMetaData[FormConstants.IS_DELETED];
                if (isPlaceholder || isDeleted) {
                  return;
                }
                this.updateDownstreamFormItemValueOnUpstreamFormItemValue(
                  argsList,
                  fieldNamePathMap,
                  upstreamFieldName,
                  downstreamFieldName,
                  type,
                  yesNoValueMap,
                  definedUndefinedValueMap,
                  null,
                  subGroupMetaData[FormConstants.INDEX]
                );
              });
              break;
            }

            default: {
              throw new UnsupportedOperationException('schema group repeats and sub group repeats are not supported');
            }
          }
        });

        argsList.forEach(args => this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]));
      });
  }

  private updateValue(
    schemaGroupDomId: string,
    schemaGroupIndex: number,
    subGroupDomId: string,
    subGroupIndex: number,
    formItemFieldName: string,
    value: unknown,
    isDate: boolean,
    shouldFindClosestMatch: boolean
  ): void {
    const { ECRASH_REF, ECRASH_REF_MULTI, BUTTON_GROUP, BUTTON_GROUP_MULTI } = FormItemType;
    const { META_DATA, TYPE, VALUE, ITEMS, HIDDEN_ITEMS } = FormConstants;

    const compositeKey = this.keyService.getFormItemCompositeKey(schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, formItemFieldName);
    const formItemFormGroup = OssOptional.ofNullable(this.mapService.formItemCompositeKeyFormGroupMap.get(compositeKey)).orElseThrow(
      () => new TechnicalException(`form item with key ${formItemFieldName} not found`)
    );

    let formattedValue = value;
    if (isDate) {
      // oss input expects string in form mm/dd/yy
      formattedValue = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(value.toString());
    }

    if ([ECRASH_REF, ECRASH_REF_MULTI, BUTTON_GROUP, BUTTON_GROUP_MULTI].includes(formItemFormGroup.value[META_DATA][TYPE]) && shouldFindClosestMatch) {
      if (CommonUtils.isDefinedAndNonEmptyString(formattedValue)) {
        const closestMatch = CollectionUtils.findClosestMatch(formattedValue.toString(), [
          ...formItemFormGroup.value[META_DATA][ITEMS],
          ...OssOptional.ofNullable(formItemFormGroup.value[META_DATA][HIDDEN_ITEMS]).orElse([]),
        ]);
        // oss auto complete expects list of selected values or list of selected value
        formattedValue = [closestMatch.value];
      } else {
        formattedValue = [null];
      }
    }

    formItemFormGroup.controls[VALUE].setValue(formattedValue, { emitEvent: true });

    let dbValue = value;
    if (isDate) {
      // send db date object
      dbValue = value;
    }
    if ([ECRASH_REF, ECRASH_REF_MULTI, BUTTON_GROUP, BUTTON_GROUP_MULTI].includes(formItemFormGroup.value[META_DATA][TYPE])) {
      // send db the formatted value, probably have to send a list of values or list of value type is ecrah ref multi
      dbValue = formattedValue;
    }
    // this is ugly but necessary: the api expects vin to be an empty string instead of null
    // if another metro area has a different form item field name we can add a map of service location id to form item field name
    if (formItemFieldName.toLowerCase() === 'vin' && dbValue === null) {
      dbValue = StringUtils.EMPTY;
    }
    this.store.dispatch(
      CrashReportActions.updateCrashReportDetail({
        metaData: formItemFormGroup.value[META_DATA],
        value: dbValue,
        isValid: formItemFormGroup.valid,
        isSetInBackground: true,
      })
    );
  }

  private initializeServiceLocationIdFieldNamePathMap(): void {
    this.configureBaltimoreFieldNameMap();
    this.configureNewOrleansFieldNameMap();
  }

  private hideOrShowDownstreamFormItemsAndUpdateParentPageSectionAndSubgroup(
    args: [string, number, string, number, string, unknown, boolean, boolean],
    shouldHide: boolean,
    callback: () => void
  ): void {
    const formItemCompositeKey = this.keyService.getFormItemCompositeKey(args[0], args[1], args[2], args[3], args[4]);
    OssOptional.ofNullable(this.mapService.formItemCompositeKeyFormGroupMap.get(formItemCompositeKey)).ifPresentOrElse(
      formItemFormGroup => {
        const metaDataFormGroup = formItemFormGroup.controls[FormConstants.META_DATA];
        metaDataFormGroup.patchValue({ ...metaDataFormGroup.value, [FormConstants.SHOULD_HIDE]: shouldHide });

        this.valueChangesService.patchImpactedPageFormGroup(formItemFormGroup);
        this.valueChangesService.patchImpactedSectionFormGroup(formItemFormGroup);
        this.valueChangesService.patchImpactedSubGroupFormGroup(formItemFormGroup);

        callback();
      },
      () => {
        throw new TechnicalException(`form item with key ${formItemCompositeKey} not found`);
      }
    );
  }

  private configureBaltimoreFieldNameMap(): void {
    const fieldNameMap = new Map<string, ValueUpdateFieldPath>();

    // on arrival fields
    fieldNameMap.set('REPORT_NUM_2', ['reportdetails', 0, 'reportdetails', 0, 'REPORT_NUM_2']);
    fieldNameMap.set('LAT', ['reportdetails', 0, 'reportdetails', 0, 'LAT']);
    fieldNameMap.set('LONG', ['reportdetails', 0, 'reportdetails', 0, 'LONG']);
    fieldNameMap.set('DATE_POLICE_NOTE', ['reportdetails', 0, 'reportdetails', 0, 'DATE_POLICE_NOTE']);
    fieldNameMap.set('TIME_POLICE_NOTE', ['reportdetails', 0, 'reportdetails', 0, 'TIME_POLICE_NOTE']);
    fieldNameMap.set('DATE_POLICE_ARR', ['reportdetails', 0, 'reportdetails', 0, 'DATE_POLICE_ARR']);
    fieldNameMap.set('TIME_POLICE_ARR', ['reportdetails', 0, 'reportdetails', 0, 'TIME_POLICE_ARR']);
    fieldNameMap.set('INVEST_OFFICER', ['reportdetails', 0, 'reportdetails', 0, 'INVEST_OFFICER']);
    fieldNameMap.set('CRASH_DATE', ['crashdetails', 0, 'crashdetails', 0, 'CRASH_DATE']);
    fieldNameMap.set('DATE_POLICE_NOTE', ['crashdetails', 0, 'crashdetails', 0, 'DATE_POLICE_NOTE']);
    fieldNameMap.set('DATE_POLICE_ARR', ['crashdetails', 0, 'crashdetails', 0, 'DATE_POLICE_ARR']);
    fieldNameMap.set('DATE_LANES_OPEN', ['crashdetails', 0, 'crashdetails', 0, 'DATE_LANES_OPEN']);
    fieldNameMap.set('DATE_PARTIES_DISCHARGED', ['reportdetails', 0, 'reportdetails', 0, 'DATE_PARTIES_DISCHARGED']);
    fieldNameMap.set('TIME_PARTIES_DISCHARGED', ['reportdetails', 0, 'reportdetails', 0, 'TIME_PARTIES_DISCHARGED']);

    // on submit fields
    fieldNameMap.set('DATE_INVEST_COMP', ['reportdetails', 0, 'reportdetails', 0, 'DATE_INVEST_COMP']);
    fieldNameMap.set('TIME_INVEST_COMP', ['reportdetails', 0, 'reportdetails', 0, 'TIME_INVEST_COMP']);

    // license plate fields
    fieldNameMap.set('VIN', ['vehic', 0, 'vehic', 0, 'VIN']);
    fieldNameMap.set('VEH_YEAR', ['vehic', 0, 'vehic', 0, 'VEH_YEAR']);
    fieldNameMap.set('VEH_MAKE', ['vehic', 0, 'vehic', 0, 'VEH_MAKE']);
    fieldNameMap.set('VEH_MODEL', ['vehic', 0, 'vehic', 0, 'VEH_MODEL']);

    this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.set(2, fieldNameMap);
  }

  private configureNewOrleansFieldNameMap(): void {
    // initialize the field name map
    const fieldNameMap = new Map<string, ValueUpdateFieldPath>();

    // initialize the on submit pairs list
    this.SERIVCE_LOCATION_ID_ON_SUBMIT_PAIRS.set(1, []);
    const submitPairs = this.SERIVCE_LOCATION_ID_ON_SUBMIT_PAIRS.get(1);

    // on arrival fields
    fieldNameMap.set('REPORT_NUM_2', ['hidden', 0, 'hidden', 0, 'REPORT_NUM_2']);
    fieldNameMap.set('LAT', ['hidden', 0, 'hidden', 0, 'LAT']);
    fieldNameMap.set('LONG', ['hidden', 0, 'hidden', 0, 'LONG']);
    fieldNameMap.set('CRASH_COORDINATES', ['crash', 0, 'location', 0, 'CRASH_COORDINATES']);
    fieldNameMap.set('DATE_POLICE_NOTE', ['crash', 0, 'crash', 0, 'DATE_POLICE_NOTE']);
    fieldNameMap.set('TIME_POLICE_NOTE', ['crash', 0, 'crash', 0, 'TIME_POLICE_NOTE']);
    fieldNameMap.set('DATE_POLICE_ARR', ['crash', 0, 'crash', 0, 'DATE_POLICE_ARR']);
    fieldNameMap.set('TIME_POLICE_ARR', ['crash', 0, 'crash', 0, 'TIME_POLICE_ARR']);
    fieldNameMap.set('DATE_LANES_OPEN', ['crash', 0, 'crash', 0, 'DATE_LANES_OPEN']);
    fieldNameMap.set('TIME_LANES_OPEN', ['crash', 0, 'crash', 0, 'TIME_LANES_OPEN']);
    fieldNameMap.set('INVEST_OFFICER', ['hidden', 0, 'hidden', 0, 'INVEST_OFFICER']);
    fieldNameMap.set('INV_OFF_NAME_FIRST', ['hidden', 0, 'hidden', 0, 'INV_OFF_NAME_FIRST']);
    fieldNameMap.set('INV_OFF_NAME_LAST', ['hidden', 0, 'hidden', 0, 'INV_OFF_NAME_LAST']);
    fieldNameMap.set('INV_OFF_RANK', ['hidden', 0, 'hidden', 0, 'INV_OFF_RANK']);
    fieldNameMap.set('CRASH_DATE', ['crash', 0, 'crash', 0, 'CRASH_DATE']);

    // on submit fields
    const locationTuples: [string, string][] = [
      // ['DIST_FROM_INTER', 'DIST_FROM_INTER_NA'],
      // ['DIST_FROM_INTER', 'DIST_FROM_INTER_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, locationTuples, 'crash', false, 'location', false);

    const vehicleTuples: [string, string][] = [
      // ['PLATE_NUM', 'PLATE_NUM_UNKNOWN'],
      // ['PLATE_NUM', 'PLATE_MISSING'],
      // ['REG_STATE', 'REG_STATE_UNKNOWN'],
      // ['VIN', 'VIN_UNKNOWN'],
      // ['VEH_YEAR', 'VEH_YEAR_UNKNOWN'],
      // ['REG_YEAR', 'REG_YEAR_UNKNOWN'],
      // ['VEH_REMOVED_BY', 'VEH_REMOVED_BY_UNKNOWN'],
      // ['NUM_AXLES', 'NUM_AXLES_UNKNOWN'],
      // ['UTB_THM_HAZ_ID', 'UTB_THM_HAZ_ID_UNKNOWN'],
      // ['UTB_CAR_NAME', 'UTB_CAR_NAME_UNKNOWN'],
      // ['UTB_CAR_ADDR', 'UTB_CAR_ADDR_UNKNOWN'],
      // ['MTR_CARRIER_PHONE', 'MTR_CARRIER_PHONE_UNKNOWN'],
      // ['COMMODITY_HAULED', 'COMMODITY_HAULED_UNKNOWN'],
      // ['INSCO', 'INSCO_UNKNOWN'],
      // ['INS_PHONE', 'INS_PHONE_UNKNOWN'],
      // ['INSCO_NAIC_NUM', 'INSCO_NAIC_NUM_UNKNOWN'],
      // ['INS_POL_NUM', 'INS_POL_NUM_UNKWN'],
      // ['INS_EXP', 'INS_EXP_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, vehicleTuples, 'vehic', true, 'vehic', false);

    const contributingFactorTuples: [string, string][] = [
      // ['POSTED_SPEED', 'SPEED_LIMIT_UNKNOWN'],
      // ['POSTED_SPEED', 'SPEED_LIMIT_NA'],
      // ['SKID_FR', 'SKID_NOT_APP'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, contributingFactorTuples, 'vehic', false, 'contribfactors', false);

    const trailerTuples: [string, string][] = [
      // ['VEH_TRAILER_VIN', 'VEH_TRAILER_VIN_UNKNOWN'],
      // ['VEH_TRAILER_NUM_AXLES', 'VEH_TRAILER_NUM_AXLES_UNKNOWN'],
      // ['VEH_TRAILER_YEAR', 'VEH_TRAILER_YEAR_UNKNOWN'],
      // ['VEH_TRAILER_MAKE', 'VEH_TRAILER_MAKE_UNKNOWN'],
      // ['VEH_TRAILER_MODEL', 'VEH_TRAILER_MODEL_UNKNOWN'],
      // ['VEH_TRAILER_PLATE_YR', 'VEH_TRAILER_PLATE_YR_UNKNOWN'],
      // ['VEH_TRAILER_PLATE_ST', 'VEH_TRAILER_PLATE_ST_UNKNOWN'],
      // ['VEH_TRAILER_PLATE_NUM', 'VEH_TRAILER_PLATE_NUM_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, trailerTuples, 'vehic', true, 'trailer', true);

    const driverTuples: [string, string][] = [
      // ['DR_LAST_NAME', 'DR_NAME_UNKNOWN'],
      // ['DR_ST', 'DR_ADDRESS_UNKNOWN'],
      // ['DR_HOME_PHONE', 'DR_PHONE_UNKNOWN'],
      // ['DR_DOB', 'DR_DOB_UNKNOWN'],
      // ['DR_AGE', 'DR_AGE_UNKNOWN'],
      // ['OWNER_NAME', 'OWNER_NAME_UNKNOWN'],
      // ['OWNER_ST', 'OWNER_ADDR_UNKNOWN'],
      // ['DR_EMS_RUN_NUM', 'DR_EMS_RUN_NUM_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, driverTuples, 'vehic', true, 'driver', false);

    const occupantTuples: [string, string][] = [
      // ['OCC_LAST_NAME', 'OCC_NAME_UNKNOWN'],
      // ['OCC_DOB', 'OCC_DOB_UNKNOWN'],
      // ['OCC_ADDR', 'OCC_ADDR_UNKNOWN'],
      // ['OCC_PHONE_NUMBER', 'OCC_PHONE_NUMBER_UNKNOWN'],
      // ['OCC_EMS_RUN_NUM', 'OCC_EMS_RUN_NUM_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, occupantTuples, 'vehic', true, 'occup', true);

    const witnessTuples: [string, string][] = [
      // ['WITNESS_ADDR', 'WITNESS_ADDR_UNKNOWN'],
      // ['WITNESS_AGE', 'WITNESS_AGE_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, witnessTuples, 'other', false, 'witness', true);

    const propertyDamageTuples: [string, string][] = [
      // ['NON_VEH_1_PROP_DMG_OWNER_NAME', 'NON_VEH_1_PROP_DMG_OWNER_NAME_UNKNOWN'],
      // ['NON_VEH_1_PROP_DMG_OWNER_PHONE', 'NON_VEH_1_PROP_DMG_OWNER_PHONE_UNKNOWN'],
      // ['NON_VEH_1_PROP_DMG_ADDRESS_STREET', 'NON_VEH_1_PROP_DMG_ADDRESS_UNKNOWN'],
      // ['NON_VEH_2_PROP_DMG_OWNER_NAME', 'NON_VEH_2_PROP_DMG_OWNER_NAME_UNKNOWN'],
      // ['NON_VEH_2_PROP_DMG_OWNER_PHONE', 'NON_VEH_2_PROP_DMG_OWNER_PHONE_UNKNOWN'],
      // ['NON_VEH_2_PROP_DMG_ADDRESS_STREET', 'NON_VEH_2_PROP_DMG_ADDRESS_UNKNOWN'],
      // ['NON_VEH_3_PROP_DMG_OWNER_NAME', 'NON_VEH_3_PROP_DMG_OWNER_NAME_UNKNOWN'],
      // ['NON_VEH_3_PROP_DMG_OWNER_PHONE', 'NON_VEH_3_PROP_DMG_OWNER_PHONE_UNKNOWN'],
      // ['NON_VEH_3_PROP_DMG_ADDRESS_STREET', 'NON_VEH_3_PROP_DMG_ADDRESS_UNKNOWN'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, propertyDamageTuples, 'other', false, 'propertydamage', false);

    const pedestrianTuples: [string, string][] = [
      // ['PED_LAST_NAME', 'PED_NAME_UNKNOWN'],
      // ['PED_DOB', 'PED_DOB_UNKNOWN'],
      // ['PED_AGE', 'PED_AGE_UNKNOWN'],
      // ['PED_ST', 'PED_ADDR_UNKNOWN'],
      // ['PED_HOME_PHONE', 'PED_HOME_PHONE_UNKNOWN'],
      // ['PED_EMS_RUN_NUM', 'PED_EMS_RUN_NUM_UNKNOWN'],
      // ['PED_MED_UNIQ_ID', 'PED_MED_UNIQ_ID_UNKNOWN'],
      // ['PED_MED_UNIQ_ID', 'PED_MED_UNIQ_ID_NA'],
    ];
    this.setFieldNameMapAndAddToSubmitPairs(fieldNameMap, submitPairs, pedestrianTuples, 'other', false, 'pedes', true);

    fieldNameMap.set('DATE_INVEST_COMP', ['hidden', 0, 'hidden', 0, 'DATE_INVEST_COMP']);
    fieldNameMap.set('TIME_INVEST_COMP', ['hidden', 0, 'hidden', 0, 'TIME_INVEST_COMP']);
    fieldNameMap.set('DATE_CHANGED', ['hidden', 0, 'hidden', 0, 'DATE_CHANGED']);

    // license plate fields
    fieldNameMap.set('VIN', ['vehic', 0, 'vehic', 0, 'VIN']);
    fieldNameMap.set('VEH_YEAR', ['vehic', 0, 'vehic', 0, 'VEH_YEAR']);
    fieldNameMap.set('VEH_MAKE', ['vehic', 0, 'vehic', 0, 'VEH_MAKE']);
    fieldNameMap.set('VEH_MODEL', ['vehic', 0, 'vehic', 0, 'VEH_MODEL']);

    this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.set(1, fieldNameMap);
  }

  private updateDownstreamFormItemValueOnUpstreamFormItemValue(
    argsList: [string, number, string, number, string, unknown, boolean, boolean][],
    fieldNamePathMap: Map<string, ValueUpdateFieldPath>,
    upstreamFieldName: string,
    downstreamFieldName: string,
    type: 'yes/no' | 'defined/undefined',
    yesNoValueMap: Record<string, string>,
    definedUndefinedValueMap: { defined: string; undefined: string },
    schemaGroupIndex: number,
    subGroupIndex: number
  ): void {
    // get the upstream form group value
    const upstreamPath = fieldNamePathMap.get(upstreamFieldName);
    const upstreamKey = this.keyService.getFormItemCompositeKey(
      upstreamPath[0],
      OssOptional.ofNullable(schemaGroupIndex).orElse(upstreamPath[1]),
      upstreamPath[2],
      OssOptional.ofNullable(subGroupIndex).orElse(upstreamPath[3]),
      upstreamPath[4]
    );
    const upstreamFormGroup = this.mapService.formItemCompositeKeyFormGroupMap.get(upstreamKey);
    const upstreamFormGroupValue = upstreamFormGroup.controls[FormConstants.VALUE].value;

    // get the downstream form group value control and type
    const downstreamPath = fieldNamePathMap.get(downstreamFieldName);
    const downstreamKey = this.keyService.getFormItemCompositeKey(
      downstreamPath[0],
      OssOptional.ofNullable(schemaGroupIndex).orElse(downstreamPath[1]),
      downstreamPath[2],
      OssOptional.ofNullable(subGroupIndex).orElse(downstreamPath[3]),
      downstreamPath[4]
    );
    const { downstreamFormGroupType, downstreamFormGroupValueControl } = OssOptional.ofNullable(
      this.mapService.formItemCompositeKeyFormGroupMap.get(downstreamKey)
    )
      .map(formGroup => ({
        downstreamFormGroupValueControl: formGroup.controls[FormConstants.VALUE],
        downstreamFormGroupType: formGroup.value[FormConstants.META_DATA][FormConstants.TYPE],
      }))
      .orElseThrow(() => new TechnicalException(`downstream form item with key ${downstreamKey} not found`));

    // save a copy of the previous downstream form group value
    const previousDownstreamFormGroupValueControlValue = downstreamFormGroupValueControl.value;

    // set the downstream form group value control based on the upstream form group value
    switch (type) {
      case 'yes/no': {
        if (CommonUtils.isNullOrUndefined(yesNoValueMap)) {
          throw new TechnicalException('upstream value downstream value map is null.');
        }
        if (CommonUtils.isNullOrUndefined(yesNoValueMap[upstreamFormGroupValue])) {
          throw new TechnicalException('upstream value not found in map. value equals ' + upstreamFormGroupValue + '.');
        }
        downstreamFormGroupValueControl.setValue(yesNoValueMap[upstreamFormGroupValue], { emitEvent: false });
        break;
      }

      case 'defined/undefined': {
        if (CommonUtils.isNullOrUndefined(definedUndefinedValueMap)) {
          throw new TechnicalException('defined undefined value map is null.');
        }
        let isDefined = false;
        if (StringUtils.isString(upstreamFormGroupValue) && StringUtils.isNotEmpty(upstreamFormGroupValue)) {
          isDefined = true;
        }
        if (
          CommonUtils.isArray(upstreamFormGroupValue) &&
          CommonUtils.hasContent(upstreamFormGroupValue) &&
          upstreamFormGroupValue.every(value => CommonUtils.isNullOrUndefinedOrEmptyString(value))
        ) {
          isDefined = true;
        }
        downstreamFormGroupValueControl.setValue(isDefined ? definedUndefinedValueMap.defined : definedUndefinedValueMap.undefined, { emitEvent: false });
        break;
      }

      default: {
        throw new UnsupportedOperationException('type not supported. supported types include: boolean, defined/undefined');
      }
    }

    // check if the downstream form group value control value changed
    // if it did not change, do not update the argsList so that we do not send the same value to the api
    const currentDownstreamFormGroupValueControlValue = downstreamFormGroupValueControl.value;
    const downstreamFormGroupValueControlValueChanged = currentDownstreamFormGroupValueControlValue !== previousDownstreamFormGroupValueControlValue;
    if (!downstreamFormGroupValueControlValueChanged) {
      return;
    }

    // add the downstream form group value control to the args list
    argsList.push([
      downstreamPath[0],
      OssOptional.ofNullable(schemaGroupIndex).orElse(downstreamPath[1]),
      downstreamPath[2],
      OssOptional.ofNullable(subGroupIndex).orElse(downstreamPath[3]),
      downstreamPath[4],
      downstreamFormGroupValueControl.value,
      downstreamFormGroupType === FormItemType.DATE,
      false,
    ]);
  }

  private setFieldNameMapAndAddToSubmitPairs(
    fieldNameMap: Map<string, ValueUpdateFieldPath>,
    submitPairs: SubmitPair[],
    upstreamFieldNameDownstreamFieldNameTuples: [string, string][],
    schemaGroupDomId: string,
    doesSchemaGroupRepeat: boolean,
    subGroupDomId: string,
    doesSubGroupRepeat,
    type: 'yes/no' | 'defined/undefined' = 'defined/undefined',
    yesNoValueMap: Record<string, string> = { Y: 'N', N: 'Y' },
    definedUndefinedValueMap: { defined: string; undefined: string } = { defined: 'N', undefined: 'Y' }
  ): void {
    const schemaGroupIndex = doesSchemaGroupRepeat ? null : 0;
    const subGroupIndex = doesSubGroupRepeat ? null : 0;

    upstreamFieldNameDownstreamFieldNameTuples.forEach(([upstreamFieldName, downstreamFieldName]) => {
      fieldNameMap.set(upstreamFieldName, [schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, upstreamFieldName]);
      fieldNameMap.set(downstreamFieldName, [schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, downstreamFieldName]);

      submitPairs.push([
        fieldNameMap.get(upstreamFieldName),
        fieldNameMap.get(downstreamFieldName),
        doesSchemaGroupRepeat,
        doesSubGroupRepeat,
        type,
        yesNoValueMap,
        definedUndefinedValueMap,
      ]);
    });
  }
}
